import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React, { useContext } from "react";
import { View } from "../../../components/shared/Themed";

import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import ContentTitleHeader from "../../../components/core/ContentTitleHeader";
import TabBarIcon from "../../../components/core/TabBarIcon";
import ProfileScreen from "../../../screens/Settings/UserSettingScreens/UserSettingProfileScreen";
import LoginScreen from "../../../screens/Settings/UserSettingScreens/UserSettingLoginScreen";
import { RootTabParamList, RootTabScreenProps } from "../../../types";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";

const Tab = createMaterialTopTabNavigator<RootTabParamList>();

export default function UserSettingsTabNavigator({ navigation }: any) {
  const colorScheme = useColorScheme();
  const orientationContext = useContext(DeviceOrientationContext);
  return (
    <View
      style={{
        height: "100%",
        backgroundColor: colorScheme === "light" ? "#fafafa" : "#fafafa",
      }}
    >
      {!orientationContext.isSmallDevice && (
        <ContentTitleHeader name="User Settings" navigation={navigation} />
      )}
      <Tab.Navigator
        initialRouteName="Profile"
        screenOptions={{
          tabBarActiveTintColor: Colors[colorScheme].tint,
          tabBarStyle: {
            height: 50,
            width: orientationContext.isSmallDevice ? "100%" : "50%",
            alignSelf: orientationContext.isSmallDevice
              ? "flex-start"
              : "center",
            justifySelf: orientationContext.isSmallDevice
              ? "flex-start"
              : "center",
            borderTopWidth: 0,
            backgroundColor: colorScheme === "light" ? "#fafafa" : "#fafafa",
          },
          tabBarLabelStyle: {
            fontSize: 14,
            fontWeight: "bold",
            textTransform: "none",
          },
          tabBarItemStyle: {
            width: 100,
          },
        }}
      >
        <Tab.Screen
          name="Profile"
          component={ProfileScreen}
          options={({ navigation }: RootTabScreenProps<"Profile">) =>
            orientationContext.isSmallDevice
              ? {
                  title: "",
                  tabBarIcon: ({ color }) =>
                    orientationContext.isSmallDevice && (
                      <TabBarIcon name="user-circle" color={color} />
                    ),
                }
              : {
                  title: "Profile",
                }
          }
        />
        <Tab.Screen
          name="ChangePassword"
          component={LoginScreen}
          options={({ navigation }: RootTabScreenProps<"Login">) =>
            orientationContext.isSmallDevice
              ? {
                  title: "",
                  tabBarIcon: ({ color }) =>
                    orientationContext.isSmallDevice && (
                      <TabBarIcon name="key" color={color} />
                    ),
                }
              : {
                  title: "Login",
                }
          }
        />
      </Tab.Navigator>
    </View>
  );
}
