export default {
  teams: {
    manager: "MANAGER",
    operation: "OPERATION",
    sales: "SALES",
    warehouse: "WAREHOUSE",
    external: "EXTERNAL",
    admin: "ADMIN",
    production: "PRODUCTION",
  },
};
