export const chartColors: Array<string> = [
  "255, 99, 132",
  "53, 162, 235",
  "0, 128, 128",
  "24, 36, 126",
  "159, 43, 96",
  "238, 201, 0",
  "135, 206, 255",
  "139, 149, 162",
  "197, 179, 88",
  "76, 17, 48",
  "125, 64, 64",
  "255, 87, 87",
  "255, 204, 204",
];
