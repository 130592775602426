import React from "react";
import { TouchableOpacity, StyleSheet, Image } from "react-native";
import { DatePickerInput } from "react-native-paper-dates";
import CustomTextInput from "./CustomTextInput";
import CustomEditableTextInput from "./CustomEditableTextInput";
import Spinner from "../shared/Spinner";
import { Text, View } from "../shared/Themed";

type PropsFunction = () => void;

export default function CustomArtworks({
  buttonText,
  uniqueId,
  setUniqueId,
  labelSize,
  setlabelSize,
  barcode,
  setBarcode,
  artworkId,
  setArtworkId,
  artworkName,
  setArtworkName,
  approvedArtworkNumber,
  setApprovedArtworkNumber,
  pendingArtworkNumbers,
  setPendingArtworkNumbers,
  artworkVersion,
  setArtworkVersion,
  image,
  setImage,
  isLoading,
  isImageLoading,
  setLoading,
  createdDate,
  setCreatedDate,
  isSmallDevice,
  pickImage,
  handleSubmit,
  addPendingArtworkNumber,
  removePendingArtworkNumber,
  modalVisible,
  setModalVisible,
  confirmVisible,
  setConfirmVisible,
  childernComponent,
}: {
  buttonText: String;
  uniqueId: String;
  setUniqueId: PropsFunction;
  labelSize: String;
  setlabelSize: PropsFunction;
  barcode: String;
  setBarcode: PropsFunction;
  artworkId: String;
  setArtworkId: PropsFunction;
  artworkName: String;
  setArtworkName: PropsFunction;
  approvedArtworkNumber: String;
  setApprovedArtworkNumber: PropsFunction;
  pendingArtworkNumbers: Object[];
  setPendingArtworkNumbers: PropsFunction;
  artworkVersion: String;
  setArtworkVersion: PropsFunction;
  image: String;
  setImage: PropsFunction;
  isLoading: Boolean;
  isImageLoading: Boolean;
  setLoading: PropsFunction;
  createdDate: Date;
  setCreatedDate: PropsFunction;
  isSmallDevice: Boolean;
  pickImage: PropsFunction;
  handleSubmit: PropsFunction;
  addPendingArtworkNumber: PropsFunction;
  removePendingArtworkNumber: PropsFunction;
  modalVisible: Boolean;
  setModalVisible: PropsFunction;
  confirmVisible: Boolean;
  setConfirmVisible: PropsFunction;
  childernComponent: String;
}) {
  const CustomComponent = childernComponent || {};
  return (
    <View style={styles.box}>
      <Text style={styles.title}>Artwork Image</Text>
      <View style={styles.logoBox}>
        {!isImageLoading ? (
          <TouchableOpacity
            style={{ width: "100%", height: "100%" }}
            onPress={() => setModalVisible(!modalVisible)}
          >
            <Image
              source={{ uri: image }}
              style={styles.logo}
              resizeMode="cover"
            />
          </TouchableOpacity>
        ) : (
          <View
            style={{
              width: 250,
              height: 250,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              size="small"
              color="red"
              styles={{ flexDirection: "row" }}
            />
          </View>
        )}
      </View>
      <View style={styles.subBox}>
        <TouchableOpacity style={styles.logoButton} onPress={pickImage}>
          <Text style={styles.logoButtontext}>Upload Artwork</Text>
        </TouchableOpacity>
        <Text style={styles.greyLabel}>JPG, GIF or PNG. Max size of 5MB</Text>
      </View>
      <Text
        style={{
          borderTopWidth: 1,
          borderTopColor: "#808080",
          borderTopStyle: "solid",
          width: "100%",
          marginTop: 20,
          marginBottom: 20,
        }}
      ></Text>
      <CustomTextInput
        title="Artwork ID"
        required={true}
        width={isSmallDevice ? "90%" : "90%"}
        borderColor="#ccc"
        marginLeft={0}
        placeHolder="Artwork ID"
        value={artworkId}
        disabled={true}
        setChangeText={(text) => setArtworkId(text)}
      />
      <CustomTextInput
        title="Artwork Name"
        required={true}
        width={isSmallDevice ? "90%" : "90%"}
        borderColor="#ccc"
        marginLeft={0}
        placeHolder="Artwork Name"
        value={artworkName}
        disabled={true}
        setChangeText={(text) => setArtworkName(text)}
      />
      <CustomTextInput
        title="Version Number"
        required={true}
        width={isSmallDevice ? "90%" : "90%"}
        borderColor="#ccc"
        marginLeft={0}
        placeHolder="Version Number"
        value={artworkVersion}
        disabled={true}
        setChangeText={(text) => setArtworkVersion(text)}
      />
      <View style={{ marginBottom: 10 }}>
        <CustomEditableTextInput
          addButtonText={"Add Pending Artwork Number"}
          addItem={addPendingArtworkNumber}
          removeItem={removePendingArtworkNumber}
          title={"Pending Artwork Number"}
          inputs={pendingArtworkNumbers}
          childern={CustomComponent}
        />
      </View>
      <View style={{ flexDirection: "row" }}>
        <CustomTextInput
          title="Approved Artwork Number"
          width={isSmallDevice ? "90%" : "90%"}
          borderColor="#ccc"
          marginLeft={0}
          placeHolder="Approved Artwork Number"
          value={approvedArtworkNumber}
          disabled={true}
          setChangeText={(text) => setApprovedArtworkNumber(text)}
        />
      </View>
      <View style={{ flexDirection: "row" }}>
        <CustomTextInput
          title="Label Size"
          width={isSmallDevice ? "40%" : "40%"}
          borderColor="#ccc"
          marginLeft={0}
          placeHolder="Label Size"
          value={labelSize}
          disabled={true}
          setChangeText={(text) => setlabelSize(text)}
        />
        <View style={{ flexDirection: "column", marginLeft: 20 }}>
          <Text
            style={{
              width: "100%",
              fontSize: 15,
              fontWeight: "bold",
              textAlign: "left",
              marginLeft: 10,
            }}
          >
            Date Updated
          </Text>
          <DatePickerInput
            style={[
              {
                height: 59,
                width: "35%",
                marginLeft: 10,
                marginTop: 8,
                backgroundColor: "white",
                borderRadius: 5,
                borderColor: "#ccc",
                borderWidth: 0.5,
              },
            ]}
            value={createdDate}
            onChange={(d) => setCreatedDate(d)}
            inputMode="start"
            // mode='outlined'
          />
        </View>
      </View>
      <CustomTextInput
        title="Product Barcode"
        width={isSmallDevice ? "90%" : "90%"}
        borderColor="#ccc"
        marginLeft={0}
        placeHolder="Product Barcode"
        value={barcode}
        disabled={true}
        setChangeText={(text) => setBarcode(text)}
      />

      <View style={{ flexDirection: "row" }}>
        {
          <TouchableOpacity
            style={[
              styles.button,
              {
                backgroundColor:
                  buttonText === "Update" ? "#088F8F" : "#6495ED",
              },
            ]}
            onPress={handleSubmit /*uploadImage*/}
          >
            <Text style={styles.buttonText}>
              {buttonText === "Update" ? "Update Artwork" : "Create Artwork"}
            </Text>
          </TouchableOpacity>
        }
        {buttonText === "Update" && (
          <TouchableOpacity
            style={[styles.button, { backgroundColor: "red" }]}
            onPress={() => setConfirmVisible(!confirmVisible)}
          >
            <Text style={styles.buttonText}>{"Delete Artwork"}</Text>
          </TouchableOpacity>
        )}
        {isLoading && (
          <Spinner styles={styles.spinner} size="small" color="#00ff00" />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  box: {
    flex: 1,
    flexDirection: "column",
    margin: 20,
    width: 400,
  },
  subBox: {
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 40,
    borderRadius: 10,
    marginLeft: 15,
    elevation: 3,
    backgroundColor: "#6495ED",
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  logoBox: {
    flexDirection: "row",
    width: "100%",
    // borderWidth: 1,
    // elevation: 2,
    backgroundColor: "white",
    borderColor: "#D3D3D3",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  logo: {
    width: "100%",
    height: 350,
  },
  title: {
    fontSize: 18,
    marginTop: 12,
    color: "#808080",
    fontWeight: "bold",
    marginLeft: 14,
  },
  logoButton: {
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 40,
    marginLeft: 10,
    borderRadius: 20,
    borderColor: "#0000FF",
    borderWidth: 1,
    elevation: 2,
    backgroundColor: "white",
  },
  logoButtontext: {
    fontSize: 14,
    lineHeight: 10,
    marginLeft: 10,
    letterSpacing: 0.25,
    color: "#0000FF",
  },
  greyLabel: {
    fontSize: 12,
    textAlign: "left",
    width: "70%",
    lineHeight: 10,
    letterSpacing: 0.25,
    color: "#808080",
    fontWeight: "bold",
    marginLeft: 15,
    marginTop: 12,
  },
  spinner: {
    marginLeft: 12,
  },
});
