import { actionTypesItems as actionTypes } from "./../constants/actionTypesItems";
import { itemsModal } from "../../models/itemsModel";

const setItemsList = (data: Array<itemsModal>) => {
  return {
    type: actionTypes.ListOfItems,
    payload: data,
  };
};

const setNestedItemsList = (data: Array<itemsModal>) => {
  return {
    type: actionTypes.ListOfNestedItems,
    payload: data,
  };
};

const setIsNestedItemsListLoaded = (data: boolean) => {
  return {
    type: actionTypes.IsNestedItemsListLoaded,
    payload: data,
  };
};

export default {
  setItemsList,
  setNestedItemsList,
  setIsNestedItemsListLoaded,
};
