import React, { useState, useEffect } from "react";
import { StyleSheet } from "react-native";
import { Button, Dialog } from "react-native-paper";
import * as Services from "../../../utils/common";
import roleConfig from "../../../utils/constants/roles";
import { Text, View } from "../../../components/shared/Themed";
import {
  getRole,
  getUsersByCompanyId,
  updateUser,
} from "../../../services/usersService";
import { updateCompany } from "../../../services/companyService";
import Spinner from "../../../components/shared/Spinner";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

export default function TeamActiveFrom() {
  const companyContext = JSON.parse(
    getItemFromSessionStorage("company-context") as string
  );
  const [visible, setVisible] = useState<Boolean>(false);
  const [status, setStatus] = useState<String>("");
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState<Object>({});
  const [currentRole, setCurrentRole] = useState<String>("");
  const [isLoading, setLoading] = useState<Boolean>(false);

  const hideDialog = () => {
    setVisible(false);
  };

  const showDialog = (user: Object) => {
    setSelectedUser(user);
    setVisible(true);
  };

  const removeUserRole = () => {
    setLoading(true);
    const roles = selectedUser.roles.filter(
      (r) => r.company_id !== companyContext.company_id
    );
    const company_ids = selectedUser.company_ids.filter(
      (r) => r !== companyContext.company_id
    );
    const body = {
      roles: roles,
      company_ids: company_ids,
    };
    updateUser(body, selectedUser.user_id).then((data) => {
      if (data.status === "ACTIVE") {
        getUsersByCompanyId(companyContext.company_id).then((data) => {
          setUsers(data.users);
        });
        const companyBody = {
          $pullAll: {
            admin_ids: [selectedUser.user_id],
            owner_ids: [selectedUser.user_id],
            member_ids: [selectedUser.user_id],
          },
        };
        updateCompany(companyBody, companyContext.company_id).then(() => {
          setLoading(false);
        });
      }
    });
    setVisible(false);
  };

  const memberItemView = (role: String) => {
    const views = [];
    if (users) {
      users.forEach((user, i) => {
        if (user.roles) {
          const roles = user.roles;
          if (
            roles.some(
              (r) =>
                r.role === role && r.company_id === companyContext.company_id
            )
          ) {
            views.push(
              <View
                key={Services.makeid(4)}
                style={{
                  borderBottomWidth: 0.2,
                  borderColor: "lightgrey",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <View style={{ flexDirection: "column", width: "70%" }}>
                    <Text
                      style={[
                        styles.label2,
                        { width: "70%", color: "black", marginLeft: 10 },
                      ]}
                    >
                      {user.first_name} {user.last_name}
                    </Text>
                    <Text
                      style={[
                        styles.label2,
                        { width: "70%", color: "grey", marginLeft: 10 },
                      ]}
                    >
                      {user.email}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", width: "30%" }}>
                    <Text
                      style={[
                        styles.label2,
                        {
                          width: "80%",
                          color: "grey",
                          textAlign: "flex-start",
                        },
                      ]}
                    >
                      {role}
                    </Text>
                    {role !== roleConfig.roles.owner &&
                      (currentRole === roleConfig.roles.owner ||
                        currentRole === roleConfig.roles.admin) && (
                        <Button
                          style={styles.button}
                          color="grey"
                          icon="delete-outline"
                          onPress={() => showDialog(user)}
                        />
                      )}
                  </View>
                </View>
              </View>
            );
          }
        }
      });
      return views;
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (companyContext && isMounted) {
      getUsersByCompanyId(companyContext.company_id).then((data) => {
        setUsers(data.users);
      });
    }
    setLoading(true);
    getRole(companyContext.company_id).then((mapiData) => {
      setCurrentRole(mapiData?.role);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: 50,
            justifyContent: "center",
            alignItems: "center",
            borderBottomWidth: 1,
          }}
        >
          <Text style={[styles.label, { width: "60%" }]}>USER</Text>
          <Text
            style={[{ width: "10%", height: "100%", borderRightWidth: 1 }]}
          ></Text>
          <Text style={[styles.label, { width: "30%" }]}>ROLE</Text>
        </View>
        {isLoading ? (
          <Spinner styles={styles.spinner} size="small" color="#00ff00" />
        ) : (
          <View>
            {currentRole === roleConfig.roles.owner &&
              memberItemView(roleConfig.roles.owner)}
            {memberItemView(roleConfig.roles.admin)}
            {memberItemView(roleConfig.roles.member)}
          </View>
        )}
      </View>
      <Dialog
        styles={{ alignSelf: "center", justifySelf: "center" }}
        visible={visible}
        onDismiss={hideDialog}
      >
        <Dialog.Actions>
          <Dialog.Content>
            <View style={{ flexDirection: "column", marginTop: "5%" }}>
              <Text style={styles.title}>Are you sure to remove</Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.title, { fontWeight: "bold" }]}>
                  {selectedUser.display_name}{" "}
                </Text>
                <Text
                  style={[
                    styles.title,
                    { fontWeight: "bold", color: "#6495ED" },
                  ]}
                >
                  ({selectedUser.email})
                </Text>
                <Text style={[styles.title]}>?</Text>
              </View>
            </View>
          </Dialog.Content>
          <Button onPress={() => hideDialog()}>Cancel</Button>
          <Button onPress={() => removeUserRole()}>Ok</Button>
        </Dialog.Actions>
      </Dialog>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    width: "100%",
    height: "100%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
  },
  button: {
    width: "20%",
  },
  label: {
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 10,
    color: "grey",
  },
  label2: {
    fontSize: 14,
    fontWeight: "bold",
  },
  title: {
    fontSize: 13,
    textAlign: "left",
    color: "#808080",
  },
  spinner: {
    marginTop: 12,
    marginLeft: 12,
  },
});
