import {
  OKTA_DOMAIN,
  OKTA_ISSUER,
  OKTA_CLIENT_ID,
  OKTA_REDIRECT_URL,
} from "@env";

const credentials = {
  development: {
    domain: "https://auth2.msteps.net",
    issuer: "https://auth2.msteps.net/oauth2/default",
    clientId: "0oa150o57x3jiQpFN0h8",
    redirectUri: "http://localhost:19006",
    // API Token
    // token: '00u-UQO3O1UcFNhaS9ZVD1_UT2q5TA9q7E5Y-a0l-v',
  },
  production: {
    domain: OKTA_DOMAIN,
    issuer: OKTA_ISSUER,
    clientId: OKTA_CLIENT_ID,
    redirectUri: OKTA_REDIRECT_URL,
  },
};

export default {
  api:
    process.env.NODE_ENV === "development"
      ? credentials.development
      : credentials.production,
};
