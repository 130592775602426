import { View, Text, TouchableOpacity } from "react-native";
import { ReadOnlyProps } from "../../../utils/templates";
import React, { useState } from "react";

import { Badge } from "@react-native-material/core";
import * as Services from "../../../utils/common";
import { StyleSheet } from "react-native";
import { DataTable } from "react-native-paper";

import statusColorCodes from "../../../utils/constants/statusColorCodes";
import Spinner from "../../shared/Spinner";
import { formatValueAsDataType } from "../../../utils/common";
import Icon from "react-native-vector-icons/FontAwesome5";

interface Props {
  nestedHeader?: any;
  nestedRows?: Array<any>;
  isNestedDataLoaded?: boolean;
  rowKeyValue?: string;
  itemsPerPage: number;
  isDisplayTimeWithDate?: boolean;

  //exclude column to not have thousand separator format
  nestedColumnsToNotHaveThousandSeparator?: Array<string>;

  handleNestedSortColumn?: (
    columnKey: string,
    columnParentIdentifier: string
  ) => void;
}

export const NestedDataTable: React.FC<ReadOnlyProps<Props>> = ({
  nestedHeader,
  nestedRows,
  isNestedDataLoaded = true,
  rowKeyValue,
  itemsPerPage,
  isDisplayTimeWithDate = false,
  nestedColumnsToNotHaveThousandSeparator,
  handleNestedSortColumn,
}: ReadOnlyProps<Props>) => {
  const [nestedPage, setNestedPage] = useState<number>(0);
  const [_nestedItemsPerPage] = useState<number>(itemsPerPage);

  const fromPage = (
    page: number,
    itemPerPage: number,
    rows: any[] | undefined
  ) => {
    return page * itemPerPage + itemPerPage > (rows || []).length
      ? (rows || []).length
      : page * itemPerPage + itemPerPage;
  };

  return (
    <View style={styles.collapsableView}>
      <DataTable style={{ padding: "10px" }}>
        <DataTable.Header>
          {Object.keys(nestedHeader).map((key) => {
            return (
              <DataTable.Title
                style={{
                  flex: nestedHeader[key].width,
                }}
                key={`${Services.makeid(10)}_${key}`}
              >
                <View style={{ width: 120 }}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: nestedHeader[key].textAlign,
                    }}
                  >
                    {nestedHeader[key].name} &nbsp;&nbsp;
                    {nestedHeader[key].isSort &&
                      nestedRows &&
                      nestedRows.length > 1 && (
                        <TouchableOpacity
                          onPress={() =>
                            handleNestedSortColumn?.(
                              key,
                              nestedRows[0][rowKeyValue as string]
                            )
                          }
                        >
                          <Icon
                            name={
                              nestedHeader[key].sortDirection === "asc"
                                ? "sort-alpha-down"
                                : "sort-alpha-up"
                            }
                            size={13}
                            color="black"
                          />
                        </TouchableOpacity>
                      )}
                  </Text>
                </View>
              </DataTable.Title>
            );
          })}
        </DataTable.Header>

        {!isNestedDataLoaded ? (
          (nestedRows as any[]).length > 0 ? (
            nestedRows?.map((_row) => {
              return (
                <DataTable.Row
                  key={`${Services.makeid(10)}_${_row[rowKeyValue as string]}`}
                >
                  {Object.keys(nestedHeader).map((_header) => {
                    return (
                      <View
                        style={{
                          flex: nestedHeader[_header].width,
                          borderColor: "white",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        key={Services.makeid(10)}
                      >
                        {!["status", "ship_status"].includes(_header) ? (
                          <Text
                            style={{
                              fontSize: 12,
                              width: "100%",
                              textAlign: nestedHeader[_header].textAlign,
                            }}
                          >
                            {nestedColumnsToNotHaveThousandSeparator?.includes(
                              nestedHeader[_header].name
                            )
                              ? _row[_header]
                              : nestedHeader[_header].name.includes("Uom1")
                              ? `${formatValueAsDataType(
                                  _row[_header],
                                  isDisplayTimeWithDate
                                )} (${_row["InvntryUom"]})`
                              : nestedHeader[_header].name.includes("Uom2")
                              ? `${formatValueAsDataType(
                                  _row[_header],
                                  isDisplayTimeWithDate
                                )} (${_row["Uom2"]})`
                              : formatValueAsDataType(
                                  _row[_header],
                                  isDisplayTimeWithDate
                                )}
                          </Text>
                        ) : (
                          <Badge
                            label={_row[_header]}
                            tintColor="white"
                            color={statusColorCodes[_row[_header]]}
                          />
                        )}
                      </View>
                    );
                  })}
                </DataTable.Row>
              );
            })
          ) : (
            <View style={styles.noDataBanner}>
              <Text
                style={{
                  fontWeight: "bold",
                  textDecorationLine: "underline",
                  textTransform: "uppercase",
                  color: "rgba(47, 149, 220, 0.7)",
                }}
              >
                No Data Available
              </Text>
            </View>
          )
        ) : (
          <View>
            <Spinner
              size="small"
              color="red"
              styles={{ flexDirection: "row", margin: "5%" }}
            />
          </View>
        )}

        <DataTable.Pagination
          page={nestedPage}
          numberOfPages={Math.ceil(
            ((nestedRows && nestedRows.length) || 0) / _nestedItemsPerPage
          )}
          onPageChange={(nestedPage) => setNestedPage(nestedPage)}
          label={`${fromPage(nestedPage, _nestedItemsPerPage, nestedRows)} of ${
            (nestedRows && nestedRows.length) || 0
          }`}
          showFastPaginationControls
          numberOfItemsPerPage={_nestedItemsPerPage}
          selectPageDropdownLabel={"Rows per page"}
        />
      </DataTable>
    </View>
  );
};

const styles = StyleSheet.create({
  collapsableView: {
    flex: 1,
    borderStyle: "solid",
    borderColor: "rgba(47, 149, 220, 0.5)",
    borderWidth: 1,
  },
  noDataBanner: {
    margin: "2%",
    textAlign: "center",
  },
});
