import { actionTypesArtwork as actionTypes } from "../constants/actionTypesArtwork";
import { artworkState } from "../state/artwork";

const artworkReducer = (state = artworkState, action: any) => {
  switch (action.type) {
    case actionTypes.ListOfArtwork:
      return {
        ...state,
        artworkList: action.payload,
        isArtWorkListLoaded: true,
      };
    default:
      return state;
  }
};

export default artworkReducer;
