import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { View } from "../../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../../components/custom/Modal/index";
import Spinner from "../../../components/shared/Spinner";
import { getCustomerStatus } from "../../../services/customerStatusService";
import { validSignedURL } from "../../../utils/validateSignedUrl";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import {
  headersCustomerStatus,
  mdHeadersCustomerStatus,
  smHeadersCustomerStatus,
} from "../utils/customerStatusHeaders";
import { customerStatusModel } from "../../../models/reportsModel";
import { handleTableLoader } from "../../../utils/common";
import { useSelector } from "react-redux";
import { userClaimInformation } from "../../../models/usersModel";
import { filterBasedOnAnyKey } from "../../../utils/searchInArrays";
import { sortColumns } from "../../../utils/sortColumns";

export default function CustomerStatusReportScreen({ route, navigation }: any) {
  const { isLargeDevice, isMediumDevice } = useContext(
    DeviceOrientationContext
  );

  const { customerStatusReport, isCustomerStatusReportListLoaded } = useSelector(
    (state: any) => state.reports
  );
  const { userInfo } = useSelector((state: any) => state.configurations);

  const [selected, setSelected] = useState<customerStatusModel>(
    {} as unknown as customerStatusModel
  );

  const [customerStatusPageLevelList, setCustomerStatusPageLevelList] = useState<
    Array<customerStatusModel>
  >([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headersCustomerStatus
    );
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const convertCustomerStatusData = (_customerStatusReport: Array<customerStatusModel>) => {
    return _customerStatusReport.map((cs: customerStatusModel) => {
      return {
        ...cs,
        AccountStatus: cs.AccountStatus === null ? "Contact Office" : cs.AccountStatus,
      };
    });
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      const searchedList: Array<customerStatusModel> = filterBasedOnAnyKey(
        customerStatusReport,
        searchText
      );
      setCustomerStatusPageLevelList(convertCustomerStatusData(searchedList));
    }

    setTimeout(() => {
      fadeTableLoader();
    }, 1000);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedCustomerStatusList: Array<customerStatusModel> = sortColumns(
      customerStatusPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setCustomerStatusPageLevelList(_sortedCustomerStatusList);
  };

  useEffect(() => {
    let isMounted: boolean = true;
    const _userInfo = userInfo as userClaimInformation;

    // Avoid keep refreshing table when item is selected
    if (isMounted) {
      if (customerStatusPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();
        getCustomerStatus(
          "",
          _userInfo.team,
          `${_userInfo.first_name} ${_userInfo.last_name}`,
          tableHeaders
        );
      }
    }

    if (Object.keys(selected).length !== 0) {
      validSignedURL(
        selected.CardCode,
        "avatar",
        setImageLoading,
        setSelectedImage
      );
    }

    setTableHeaders(
      isLargeDevice
        ? headersCustomerStatus
        : isMediumDevice
        ? mdHeadersCustomerStatus
        : smHeadersCustomerStatus
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [selected, isRefresh]);

  useEffect(() => {
    setCustomerStatusPageLevelList(convertCustomerStatusData(customerStatusReport));

    setTimeout(() => {
      fadeTableLoader();
    }, 2000);
  }, [customerStatusReport]);

  const ModalContent = () => {
    const _excludedKeys = [""];

    const _orderedKeys = [
      "AccountStatus",
      "CardCode",
      "CardName",
      "PymntGroup",
      "CreditLine",
      "CreditLimitAvailable",
      "Variance",
      "Balance",
      "OpenDeliveries",
      "CommittedOrders",
      "SlpName"
    ];

    return (
      <Modal.ModalContent
        title={selected.CardCode || ""}
        icon="folder"
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headersCustomerStatus}
            orderedKeys={_orderedKeys}
          />
        }
      />
    );
  };

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={[styles.container, { backgroundColor: "#f2f2f2" }]}>
        <View
          style={[
            styles.containerContent,
            {
              width: "100%",
            },
          ]}
        >
          {isCustomerStatusReportListLoaded ? (
            <View>
              <LabelBannerWithSearchBar
                titleName={`Total Items: ${customerStatusPageLevelList.length}`}
                handleSearch={handleSearch}
                setRefresh={setRefresh}
              />
              <DataTableWithPagination
                headers={tableHeaders}
                rows={customerStatusPageLevelList}
                itemsPerPage={100}
                useModal={true}
                isDataLoading={isSearched}
                isRowsExpandable={false}
                setModalVisible={setModalVisible}
                setSelected={setSelected}
                isDetail={true}
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={ModalContent}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    // marginTop: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
