import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import HomeTabNavigator from "./HomeTabNavigator";
import ModalScreen from "../../../screens/ModalScreen";
import UserSettingsTabNavigator from "../../SharedNavigator/TabNavigator/UserSettingsTabNavigator";
import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import { StyleSheet } from "react-native";
import { RootTabParamList, RootTabScreenProps } from "../../../types";

export default function TopTabNavigator({}) {
  const colorScheme = useColorScheme();
  const Tab = createMaterialTopTabNavigator<RootTabParamList>();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        swipeEnabled: false,
        tabBarActiveTintColor: Colors[colorScheme].tint,
        tabBarIndicatorStyle: styles._tabBarIndicatorStyle,
        tabBarStyle: styles._tabBarStyle,
        tabBarLabelStyle: styles._tabBarLabelStyle,
        tabBarItemStyle: styles._tabBarItemStyle,
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeTabNavigator}
        options={({}: RootTabScreenProps<"Home">) => ({
          title: "Home",
          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        })}
      />
      <Tab.Screen
        name="Help"
        component={ModalScreen}
        options={{
          title: "Help",
          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
        listeners={{
          tabPress: (e) => {
            e.preventDefault();
          },
        }}
      />
      <Tab.Screen
        name="UserSettings"
        component={UserSettingsTabNavigator}
        options={({}: RootTabScreenProps<"UserSettings">) => ({
          title: "Accounts",
          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        })}
        listeners={{
          tabPress: (e) => {
            // Prevent default action
          },
        }}
      />
    </Tab.Navigator>
  );
}

const styles = StyleSheet.create({
  _tabBarIndicatorStyle: {
    backgroundColor: undefined,
  },
  _tabBarStyle: {
    height: 60,
    width: "40%",
    alignSelf: "center",
    justifySelf: "center",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  _tabBarLabelStyle: {
    fontSize: 16,
    textTransform: "none",
  },
  _tabBarItemStyle: {
    width: 80,
  },
});
