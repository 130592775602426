import { useContext, useEffect, useRef, useState } from "react";
import { Text, View, StyleSheet } from "react-native";
import { Divider } from "react-native-paper";

import Icon from "react-native-vector-icons/Feather";
import { ChartTooltipItem } from "chart.js";
import {
  formatDate,
  formatDateToMongoISOFormat,
  isToday,
} from "../../../utils/dateUtility";
import { totalScansModal } from "../../../models/barcodeModel";
import { ReadOnlyProps } from "../../../utils/templates";
import _ from "lodash";
import { NoDataBanner } from "../../../components/shared/NoDataBanner";
import { LineChartControl } from "../../../components/custom/Charts/LineChartControl";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { chartColors } from "./constants/ChartColors";
import { Line } from "react-chartjs-2";
import { getTotalScansStats } from "../../../services/barcodeService";
import Spinner from "../../../components/shared/Spinner";

interface Props {
  selectedScanDate: Date;
  startDate: string;
  endDate: string;
  operatorName: string;
  isRefresh: boolean;
}

export const TotalScanContent = ({
  selectedScanDate,
  startDate,
  endDate,
  operatorName,
  isRefresh,
}: ReadOnlyProps<Props>) => {
  const chartRef = useRef<Line>(null);
  const isMountedRef = useRef<boolean>();
  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );

  const [processedScanStats, setProcessedScanStats] =
    useState<totalScansModal>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isSectionLoadedBefore, setIsSectionLoadedBefore] =
    useState<boolean>(false);

  const getScanWeeklyColorStatus = () => {
    if (processedScanStats) {
      return processedScanStats?.todaysScanStats?.length >=
        processedScanStats?.lastWeekSameDayScanStats?.length
        ? true
        : false;
    }

    return false;
  };

  const data = {
    labels: processedScanStats ? processedScanStats.labels : [],
    datasets: processedScanStats
      ? [
          {
            label: `this month (${new Date(
              processedScanStats.currentMonth
            ).toLocaleDateString("en-AU", {
              month: "short",
            })})`,
            fill: false,
            data: processedScanStats.currentMonthScanStats,
            borderColor: `rgb(${(chartColors as unknown as string[])[0]})`,
            backgroundColor: `rgba(${
              (chartColors as unknown as string[])[0]
            }, 0.5)`,
            pointHoverBackgroundColor: "white",
            showLine: isMobileDevice || isSmallDevice ? false : true,
          },
          {
            label: `last month (${new Date(
              processedScanStats.previousMonth
            ).toLocaleDateString("en-AU", {
              month: "short",
            })})`,
            fill: false,
            data: processedScanStats.lastMonthScanStats,
            borderColor: `rgb(${(chartColors as unknown as string[])[1]})`,
            backgroundColor: `rgba(${
              (chartColors as unknown as string[])[1]
            }, 0.5)`,
            pointHoverBackgroundColor: "white",
            showLine: isMobileDevice || isSmallDevice ? false : true,
          },
        ]
      : [],
  };

  const titleToolTip = (tooltipItem: Array<ChartTooltipItem>) => {
    return `Day ${tooltipItem[0].label as string}`;
  };

  const fetchTotalScansStats = async () => {
    try {
      const scanResults = await getTotalScansStats(
        startDate,
        endDate,
        formatDateToMongoISOFormat(selectedScanDate),
        operatorName
      );

      setProcessedScanStats(scanResults.data);
      setIsDataLoading(scanResults.isLoading);
    } catch (error) {
      setProcessedScanStats({} as unknown as totalScansModal);
      setIsDataLoading(false);

      console.log(
        "onDateChange in TotalScanStats section the stats loading method failed of the following reason: ",
        error
      );
    }
  };

  useEffect(() => {
    if (isSectionLoadedBefore) {
      //Destory the chart
      if (chartRef.current && chartRef.current !== null) {
        chartRef.current.chartInstance.clear();
      }

      setIsDataLoading(true);
      fetchTotalScansStats();
    }
  }, [selectedScanDate, operatorName, isRefresh]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchTotalScansStats();
    setIsSectionLoadedBefore(true);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <View style={{ margin: "3%" }}>
      {!isDataLoading ? (
        <>
          <View>
            {processedScanStats &&
            (processedScanStats?.todaysScanStats?.length > 0 ||
              Number(processedScanStats?.scanWeeklyPercentage) > 0) ? (
              <>
                <Text style={{ fontSize: 28 }}>
                  {processedScanStats?.todaysScanStats[0]?.length.toLocaleString()}
                  <Text style={{ fontSize: 16, marginLeft: 2 }}>
                    &nbsp; (
                    {isToday(selectedScanDate)
                      ? "Today"
                      : `${formatDate(selectedScanDate, "short")}`}
                    )
                  </Text>
                </Text>
                <Text style={{ fontSize: 16 }}>
                  <Text
                    style={[
                      getScanWeeklyColorStatus()
                        ? styles.percentGreen
                        : styles.percentRed,
                    ]}
                  >
                    <Icon
                      name={
                        getScanWeeklyColorStatus() ? "arrow-up" : "arrow-down"
                      }
                      size={16}
                    />
                    &nbsp;{`${processedScanStats?.scanWeeklyPercentage}%`}&nbsp;
                  </Text>
                  vs same day last week
                </Text>
              </>
            ) : (
              <NoDataBanner />
            )}
          </View>
          <Divider style={{ marginTop: "3%" }} />
          <View style={{ marginTop: "3%" }}>
            {processedScanStats &&
            (processedScanStats?.currentMonthTotalScans > 1 ||
              processedScanStats?.previousMonthTotalScans > 1) ? (
              <LineChartControl
                chartId="totalScanLineChart"
                chartReference={chartRef}
                chartHeight={220}
                xAxisLabel="Per month"
                hasXAxisGridlines={false}
                yAxisLabel="Total Scans"
                yAxisStepSize={50}
                dataRecords={data}
                titleToolTip={titleToolTip}
                isAnimate={isMobileDevice || isSmallDevice ? false : true}
                isLineSmoothCornered={
                  isMobileDevice || isSmallDevice ? false : true
                }
                isLineShown={isMobileDevice || isSmallDevice ? false : true}
              />
            ) : (
              <NoDataBanner />
            )}
          </View>
        </>
      ) : (
        <Spinner
          size="small"
          color={"#00ff00"}
          styles={{ flexDirection: "row", margin: "20%" }}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  percentGreen: {
    color: "#289E43",
  },
  percentRed: {
    color: "#D02828",
  },
});
