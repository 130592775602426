import sapiConfig from "../configs/sapi";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { getUserInfo } from "./usersService";
import { filterBasedOnAnyKey } from "../utils/searchInArrays";
import { itemsModal } from "../models/itemsModel";
import { ItemsDispatcher } from "../redux/dispatcher/itemsDispatcher";
import { actionTypesItems } from "../redux/constants/actionTypesItems";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

const BASE_URL = sapiConfig.api.domain;

const methodPath = async (searchText: string = "") => {
  const { given_name, family_name, profile, zoneinfo } = await getUserInfo();

  return `/shipping/items/search/all?${searchText}&-90&${given_name}%20${family_name}&${profile}&${
    zoneinfo ? zoneinfo.replace("Australia/", "") : "Sydney"
  }`;
};

export const getAllItems = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>
): Promise<void> => {
  let itemsList: Array<itemsModal> = [];
  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: await methodPath(""),
  })
    .then((result) => {
      itemsList = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordsets[0]
      )
        .filter((item: any) => {
          return item.ship_status !== "Arrived";
        })
        .map((item) => {
          const eta_store_end_date =
            item.eta_store_date.split("-")[1] !== undefined
              ? (item.eta_store_date.split("-")[1] as string).split("/")
              : "";

          const eta_store_end_date_format =
            eta_store_end_date !== ""
              ? `${eta_store_end_date[1]}/${eta_store_end_date[0]}/${eta_store_end_date[2]}`
              : "";

          return { ...item, eta_date: eta_store_end_date_format };
        });
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ItemsDispatcher(actionTypesItems.ListOfItems, itemsList);
};

export const getAllNestedItems = async (
  containerId: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
): Promise<void> => {
  let nestedItemsList: Array<itemsModal> = [];
  ItemsDispatcher(actionTypesItems.ListOfNestedItems, []);

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: await methodPath(containerId),
  })
    .then((result) => {
      const sortedNestedItemsList = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordsets[0]
      );

      const filteredItems = filterBasedOnAnyKey(
        sortedNestedItemsList,
        containerId
      );

      nestedItemsList = filteredItems;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ItemsDispatcher(actionTypesItems.ListOfNestedItems, nestedItemsList);
};
