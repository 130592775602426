export const drawerItemsMain = [
  {
    key: "ScanByOperator",
    title: "Scan",
    routes: [
      {
        nav: "ScanByOperator",
        routeName: "ScanByOperator",
        title: "Scan",
      },
    ],
  },
];
