import { useContext, useEffect, useRef, useState } from "react";
import { Text, View, StyleSheet } from "react-native";

import GaugeChart from "react-gauge-chart";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { ReadOnlyProps } from "../../../utils/templates";
import { avgScanModal } from "../../../models/barcodeModel";
import _ from "lodash";
import { NoDataBanner } from "../../../components/shared/NoDataBanner";
import { getAvgScanStats } from "../../../services/barcodeService";
import { formatDateToMongoISOFormat } from "../../../utils/dateUtility";
import Spinner from "../../../components/shared/Spinner";

interface Props {
  selectedScanDate: Date;
  startDate: string;
  endDate: string;
  isRefresh: boolean;
  operatorName: string;
}

export const AvgScanContent = ({
  selectedScanDate,
  startDate,
  endDate,
  operatorName,
  isRefresh,
}: ReadOnlyProps<Props>) => {
  const isMountedRef = useRef<boolean>();
  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );

  const [processedScanStats, setProcessedScanStats] = useState<avgScanModal>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isSectionLoadedBefore, setIsSectionLoadedBefore] =
    useState<boolean>(false);

  const fetchAvgScan = async () => {
    try {
      const scanResults = await getAvgScanStats(
        startDate,
        endDate,
        formatDateToMongoISOFormat(selectedScanDate),
        operatorName
      );

      setProcessedScanStats(scanResults.data);
      setIsDataLoading(scanResults.isLoading);
    } catch (error) {
      setProcessedScanStats({} as unknown as avgScanModal);
      setIsDataLoading(false);

      console.log(
        "onDateChange in AvgScan section the stats loading method failed of the following reason: ",
        error
      );
    }
  };

  useEffect(() => {
    if (isSectionLoadedBefore) {
      setIsDataLoading(true);
      fetchAvgScan();
    }
  }, [selectedScanDate, operatorName, isRefresh]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchAvgScan();
    setIsSectionLoadedBefore(true);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <View style={{ margin: "3%" }}>
      <View
        style={{
          flexDirection: !isSmallDevice && !isMobileDevice ? "row" : "column",
        }}
      >
        {!isDataLoading ? (
          processedScanStats &&
          (processedScanStats?.lineScan > 0 ||
            processedScanStats?.operatorScan > 0) ? (
            processedScanStats?.avgScans?.map((item) => {
              return (
                <View style={[styles.chartBoxLabel]} key={item.id.toString()}>
                  <GaugeChart
                    id={item.id.toString()}
                    nrOfLevels={10}
                    colors={["#FF5F6D", "#FFC371"]}
                    textColor="black"
                    arcWidth={0.3}
                    percent={Number(item.percent)}
                    style={{ width: 200 }}
                    animate={false}
                    animateDuration={0}
                  />
                  <Text>{item.label.toString()}</Text>
                </View>
              );
            })
          ) : (
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                flexDirection: "row",
              }}
            >
              <NoDataBanner />
            </View>
          )
        ) : (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
              flexDirection: "row",
            }}
          >
            <Spinner
              size="small"
              color={"#00ff00"}
              styles={{ flexDirection: "row", margin: "20%" }}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  chartBoxLabel: {
    marginTop: "3%",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
