import { AccessToken, IDToken } from "@okta/okta-auth-js";
import { tokenManager } from "../oktaAuthService";

export const accessToken = async () => {
  return (await tokenManager.get("accessToken")) as AccessToken;
};

export const idToken = async () => {
  return (await tokenManager.get("idToken")) as IDToken;
};
