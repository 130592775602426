import React from "react";
import { StyleSheet, Text, TextInput } from "react-native";
import Icons from "@expo/vector-icons/MaterialCommunityIcons";
import { View } from "../shared/Themed";
import { ReadOnlyProps } from "../../utils/templates";

interface Props {
  required?: boolean;
  title: string;
  placeholder?: string;
  value: string;
  width: string;
  editable?: boolean;
  blurOnSubmit?: boolean;
  isMultiline?: boolean;
  refInput?: any;
  height?: number;
  isTextEntrySecure?: boolean;
  showSoftInputOnFocus?: boolean;
  setChangeText: (event: any) => void;
  handleOnSubmitEditing?: (event: any) => void;
}

export const TextInputControl: React.FC<ReadOnlyProps<Props>> = ({
  required = false,
  title,
  placeholder = "",
  value,
  width,
  editable = true,
  blurOnSubmit = true,
  isMultiline = false,
  isTextEntrySecure = false,
  showSoftInputOnFocus = true,
  refInput,
  height = 35,
  setChangeText,
  handleOnSubmitEditing,
}: ReadOnlyProps<Props>) => {
  return (
    <View style={{ flexDirection: "column", width: width, marginTop: 15 }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={[styles.label, { color: "black", marginBottom: "5px" }]}>
          {title}
        </Text>
        {required && <Icons name="star" size={10} color="red" />}
      </View>
      <View>
        <TextInput
          style={[styles.input, { height: height }]}
          ref={refInput}
          onChangeText={setChangeText}
          placeholder={placeholder}
          value={value}
          multiline={isMultiline}
          editable={editable}
          blurOnSubmit={blurOnSubmit}
          autoFocus={true}
          onSubmitEditing={handleOnSubmitEditing}
          secureTextEntry={isTextEntrySecure}
          showSoftInputOnFocus={showSoftInputOnFocus}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  label: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
  },
  input: {
    borderWidth: 1,
    borderRadius: 4,
    padding: "2%",
    fontSize: 14,
    borderColor: "rgba(0, 0, 0, 0.29)",
  },
});
