/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootStackParamList } from "../types";

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              Reports: {
                screens: {
                  CustomerStatus: {
                    path: "reports/customerStatus",
                    screens: {
                      CustomerStatusReportsScreen: "CustomerStatus",
                    },
                  },
                  Products: {
                    path: "reports/search/products",
                    screens: {
                      ProductsReportsScreen: "Products",
                    },
                  },
                  Stocks: {
                    path: "reports/stocks",
                    screens: {
                      StocksReportsScreen: "Stocks",
                    },
                  },
                  ProductionStocks: {
                    path: "reports/production/stocks",
                    screens: {
                      ProductionStocksReportsScreen: "ProductionStocks",
                    },
                  },
                  RawMaterialStocksOverView: {
                    path: "reports/production/raw",
                    screens: {
                      RawMaterialStocksOverviewReportsScreen:
                        "RawMaterialStocksOverView",
                    },
                  },
                  RawMaterialStocksDetails: {
                    path: "reports/production/rawDetail",
                    screens: {
                      RawMaterialStocksReportsScreen:
                        "RawMaterialStocksDetails",
                    },
                  },
                  WarehouseInventory: {
                    path: "reports/warehouse/stocks",
                    screens: {
                      PmxInventoryReportsScreen: "WarehouseInventory",
                    },
                  },
                  WarehouseDetailsInventory: {
                    path: "reports/warehouse/batch",
                    screens: {
                      PmxDetailsInventoryReportsScreen:
                        "WarehouseDetailsInventory",
                    },
                  },
                },
              },
              Artworks: {
                screens: {
                  ArtworkManagement: {
                    path: "artworks/list",
                    screens: {
                      ListArtworksScreen: "ArtworkManagement",
                    },
                  },
                  NewArtwork: {
                    path: "artworks/add",
                    screens: {
                      NewArtworkScreen: "NewArtwork",
                    },
                  },
                },
              },
              Containers: {
                screens: {
                  ContainersManagement: {
                    path: "containers/list",
                    screens: {
                      ContainersManagementScreen: "ContainersManagement",
                    },
                  },
                  ManageContainers: {
                    path: "containers/manage",
                    screens: {
                      ManageContainersScreen: "ManageContainers",
                    },
                  },
                  ItemsManagement: {
                    path: "containers/items/list",
                    screens: {
                      ItemsManagementScreen: "ItemsManagement",
                    },
                  },
                },
              },
              Scans: {
                screens: {
                  ScanByOperator: {
                    path: "scan/operator",
                    screens: {
                      ScanByOperatorScreen: "ScanByOperator",
                    },
                  },
                },
              },
              OperationalScans: {
                screens: {
                  ScanDashboard: {
                    path: "scan/dashboard",
                    screens: {
                      ScanDashboardScreen: "ScanDashboard",
                    },
                  },
                  ScanByManager: {
                    path: "scan/manager",
                    screens: {
                      ScanByManagerScreen: "ScanByManager",
                    },
                  },
                },
              },
            },
          },
        },
      },
      Modal: "modal",
      NotFound: "*",
      Login: "login",
      UpdateArtwork: "artworks/update/:id",
      UserSettings: {
        screens: {
          Profile: {
            screens: {
              ProfileScreen: "Profile",
            },
          },
        },
      },
      Invite: "invites/:id/",
      OrgSettings: {
        screens: {
          Company: {
            path: "org/settings/company",
            screens: {
              CompanyScreen: "Company",
            },
          },
          Branding: {
            path: "org/settings/branding",
            screens: {
              BrandingScreen: "Branding",
            },
          },
          Teams: {
            path: "org/settings/teams",
            screens: {
              TeamsScreen: "Teams",
            },
          },
          Active: {
            path: "/org/settings/teams/Active",
            screens: {
              TeamActiveMemberScreen: "Active",
            },
          },
        },
      },
      Help: "help",
    },
  },
};

export default linking;
