import { containersModal } from "../../models/containersModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  containersList: Array<containersModal>;
  isContainersListLoaded: boolean;
  selectedContainer: containersModal;
  isContainersTableRefreshed: boolean;
  allowedRolesForContainers: Array<string>;
  importerList: Array<Record<string, string>>;
  modalityTypeList: Array<Record<string, string>>;
  containerSizeList: Array<Record<string, string>>;
  countryStateList: Array<Record<string, string>>;
  shipmentStatusList: Array<Record<string, string>>;
  priorityList: Array<Record<string, string>>;
}

export const containersState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  containersList: [],
  isContainersListLoaded: false,
  selectedContainer: {} as unknown as containersModal,
  isContainersTableRefreshed: false,

  //Constants
  allowedRolesForContainers: ["OWNER", "ADMIN", "MANAGER", "OPERATION"],
  importerList: [
    { key: "", value: "-- Select --" },
    { key: "Multisteps", value: "Multisteps" },
    { key: "S & A Packaging", value: "S & A Packaging" },
  ],
  modalityTypeList: [
    { key: "", value: "-- Select --" },
    { key: "By Sea", value: "By Sea" },
    { key: "By Air", value: "By Air" },
  ],
  containerSizeList: [
    { key: "", value: "-- Select --" },
    { key: "20 ft", value: "20 ft" },
    { key: "40 ft", value: "40 ft" },
  ],
  countryStateList: [
    { key: "", value: "-- Select --" },
    { key: "ACT", value: "ACT" },
    { key: "NT", value: "NT" },
    { key: "NSW", value: "NSW" },
    { key: "QLD", value: "QLD" },
    { key: "SA", value: "SA" },
    { key: "TAS", value: "TAS" },
    { key: "VIC", value: "VIC" },
    { key: "WA", value: "WA" },
  ],
  shipmentStatusList: [
    { key: "", value: "-- Select --" },
    { key: "Confirmed", value: "Confirmed" },
    { key: "Completed", value: "Completed" },
    { key: "Delayed", value: "Delayed" },
    { key: "Delivered", value: "Delivered" },
    { key: "In Transit", value: "In Transit" },
    { key: "On Hold", value: "On Hold" },
    { key: "Planned", value: "Planned" },
    { key: "Shipped", value: "Shipped" },
  ],
  priorityList: [
    { key: "", value: "-- Select --" },
    { key: "High", value: "High" },
    { key: "Low", value: "Low" },
    { key: "Normal", value: "Normal" },
    { key: "Urgent", value: "Urgent" },
  ],
};
