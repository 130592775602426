import { getUserInfo } from "./usersService";
import sapiConfig from "../configs/sapi";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { ReportsDispatcher } from "../redux/dispatcher/reportsDispatcher";
import { actionTypesReports } from "../redux/constants/actionTypesReports";
import { productModel } from "../models/reportsModel";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = sapiConfig.api.domain;
const Controller = "/report";

export const getAllProducts = async (
  team: string,
  location: string = "No Region",
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  const userInfo: UserClaims<CustomUserClaims> = await getUserInfo();
  const encodeName = encodeURIComponent(
    `${userInfo.given_name} ${userInfo.family_name}`.trim()
  );

  const userTeam = team.toLowerCase();

  // Temporary search field to use in the endpoint. It will be removed once the old version GO is fully terminated
  // Search option is now in Redux
  const customSearchField = "";
  const apiPath =
    userTeam === "" || userTeam === "sales"
      ? `${Controller}/products/salesitem?${customSearchField}&${encodeName}`
      : `${Controller}/products/item?${customSearchField}`;

  let productsReport: Array<productModel> = [];
  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: apiPath,
  })
    .then((result) => {
      productsReport = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ReportsDispatcher(actionTypesReports.ListOfProductReport, productsReport);
};
