export enum actionTypesReports {
  ListOfStockReport = "stockReportList",
  LastRefreshStockReport = "lastRefreshStockReport",
  ListOfPmxInventory = "pmxInventoryList",
  ListOfPmxInventoryDetail = "pmxInventoryDetailList",
  ListOfProductionStockReport = "productionStockReportList",
  ListOfRawMaterialOverviewStockReport = "rawMaterialOverviewStockReportList",
  ListOfRawMaterialStockReport = "rawMaterialStockReportList",
  ListOfProductReport = "productReportList",
  LastRefreshProductReport = "lastRefreshProductReport",
  ListOfCustomerStatus = "customerStatusList",
}
