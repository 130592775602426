import { Text, View } from "../shared/Themed";
import useColorScheme from "../../hooks/useColorScheme";

let colorScheme;
type PropsFunction = () => void;

export default function TabTitle(props: {
  name: String;
  route?: Object;
  navigation?: PropsFunction;
  routeTo?: String;
  routeToScreen?: Object;
}) {
  colorScheme = useColorScheme();
  return (
    <View
      style={{
        flexDirection: "row",
        height: "8%",
        borderTopWidth: 0.1,
        borderBottomWidth: 3,
        borderColor: colorScheme === "light" ? "darkgrey" : "white",
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        marginBottom: 10,
      }}
    >
      <View
        style={{
          width: "25%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          {/*<TouchableOpacity
              onPress={() => props.name === 'Organization Settings' ? window.location.href = `${oktaConfig.api.redirectUri}` : props.navigation.goBack() }
            >
              <Ionicons name="step-backward" size={25} color="black" />
            </TouchableOpacity>*/}
          <Text
            style={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "left",
              color: colorScheme === "light" ? "grey" : "white",
            }}
          >
            {props.name}
          </Text>
        </View>
      </View>
    </View>
  );
}
