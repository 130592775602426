import * as React from "react";
import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { drawerItemsMain } from "./ContainersDrawerItemsMain ";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";
import ContainersManagementScreen from "../../../screens/Containers/ContainersManagementScreen";
import ItemsManagementScreen from "../../../screens/Containers/ItemsManagementScreen";
import ManageContainers from "../../../screens/Containers/ManageContainers";

const Drawer = createDrawerNavigator();

export default function ContainersDrawerNavigator() {
  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerStyle: {
          width: 200,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent drawerItems={drawerItemsMain} {...props} />
      )}
    >
      <Drawer.Screen
        options={{
          drawerLabel: "Containers Management",
          title: "Containers Status",
        }}
        name="ContainersManagement"
        component={ContainersManagementScreen}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Add Container",
          title: "Manage Container",
        }}
        name="ManageContainers"
        component={ManageContainers}
        initialParams={{ mode: "create", container: {} }}
      />
      <Drawer.Screen
        options={{ drawerLabel: "Items Management", title: "Items Management" }}
        name="ItemsManagement"
        component={ItemsManagementScreen}
      />
    </Drawer.Navigator>
  );
}
