const tintColorLight = "#2f95dc";
//const tintColorDark = '#fff';

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  multiSteps: {
    background: "#244263",
  },
  // dark: {
  //   text: '#fff',
  //   background: '#000',
  //   tint: tintColorDark,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorDark,
  // },
};
