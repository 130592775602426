import React from "react";
import { ActivityIndicator } from "react-native";
import { ReadOnlyProps } from "../../utils/templates";

interface Props {
  styles?: object;
  size: number | "small" | "large" | undefined;
  color: string;
  isAnimating?: boolean;
}

export default function Spinner(props: ReadOnlyProps<Props>) {
  return (
    <ActivityIndicator
      animating={props.isAnimating}
      style={props.styles}
      size={props.size}
      color={props.color}
    />
  );
}
