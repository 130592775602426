import { actionTypesItems } from "../constants/actionTypesItems";
import store from "../configureStore";
import allActions from "../actions";
import { itemsModal } from "../../models/itemsModel";

const { dispatch } = store;

export const ItemsDispatcher = (
  actionType: actionTypesItems,
  data: unknown
) => {
  const itemsAction = allActions.itemsActions;

  switch (actionType) {
    case actionTypesItems.ListOfItems:
      dispatch(itemsAction.setItemsList(data as Array<itemsModal>));
      break;
    case actionTypesItems.ListOfNestedItems:
      dispatch(itemsAction.setNestedItemsList(data as Array<itemsModal>));
      break;
    case actionTypesItems.IsNestedItemsListLoaded:
      dispatch(itemsAction.setIsNestedItemsListLoaded(data as boolean));
      break;
  }
};
