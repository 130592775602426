import React, { useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { FAB } from "react-native-paper";
import { useIsFocused } from "@react-navigation/native";
import { View } from "../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../components/custom/Modal";
import Spinner from "../../components/shared/Spinner";
import useColorScheme from "../../hooks/useColorScheme";

import { getAllItems } from "../../services/itemsService";
import { itemsModal } from "../../models/itemsModel";
import { headers, mdHeaders, smHeaders } from "./utils/itemsHeader";
import { filterBasedOnAnyKey } from "../../utils/searchInArrays";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { useSelector } from "react-redux";
import { handleTableLoader } from "../../utils/common";
import { sortColumns } from "../../utils/sortColumns";

export default function ItemsManagementScreen({}) {
  const scrollRef = useRef<ScrollView>(null);
  const isFocused = useIsFocused();
  const colorScheme = useColorScheme();
  const columnsToNotHaveThousandsSeparator: Array<string> = ["Packing #"];

  const { isLargeDevice, isMediumDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );

  const { itemsList, isItemsListLoaded } = useSelector(
    (state: any) => state.items
  );

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [, setContentOffSet] = useState<number>(0);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [selected, setSelected] = useState<itemsModal>(
    {} as unknown as itemsModal
  );

  const [isFabOpen, setFabOpen] = useState<Record<string, boolean>>({
    open: false,
  });

  const [itemsPageLevelList, setItemsPageLevelList] = useState<
    Array<itemsModal>
  >([]);

  //Table & Nested Table Headers
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );

  const handleScroll = (event: any) => {
    setContentOffSet(Math.floor(event.nativeEvent?.contentOffset?.y) ?? 0);
  };

  const onFabOpenChange = ({ open }: { open: boolean }) => setFabOpen({ open });

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedItemsList: Array<itemsModal> = sortColumns(
      itemsPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setItemsPageLevelList(_sortedItemsList);
  };

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  useEffect(() => {
    let isMounted: boolean = true;

    if (isMounted) {
      //To restrict fetching data from endpoint, if data is available in store
      if (itemsPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();
        getAllItems(tableHeaders);
      }
    }

    setTableHeaders(
      isLargeDevice ? headers : isMediumDevice ? mdHeaders : smHeaders
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [isFocused, isRefresh]);

  useEffect(() => {
    setItemsPageLevelList(itemsList);

    setTimeout(() => {
      fadeTableLoader();
    }, 2000);
  }, [itemsList]);

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      const searchedItems: Array<itemsModal> = filterBasedOnAnyKey(
        itemsList,
        searchText
      );
      setItemsPageLevelList(searchedItems);
    }

    fadeTableLoader();
  };

  const ModalContent = () => {
    const _excludedKeys = [
      "_id",
      "__v",
      "created_Date",
      "created_by",
      "item_code",
    ];

    return (
      <Modal.ModalContent
        title={selected.item_code}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headers}
          />
        }
      />
    );
  };

  return (
    <ScrollView
      style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      onScroll={handleScroll}
      ref={scrollRef}
    >
      <View
        style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      >
        <View
          style={[
            styles.container,
            {
              backgroundColor: colorScheme === "light" ? "#f2f2f2" : "#f2f2f2",
            },
          ]}
        >
          <View
            style={[
              styles.containerContent,
              {
                width: isLargeDevice
                  ? "100%"
                  : isMediumDevice
                  ? "100%"
                  : "100%",
              },
            ]}
          >
            {isItemsListLoaded ? (
              <View>
                <FAB.Group
                  open={isFabOpen.open}
                  style={[
                    styles.fab,
                    {
                      paddingRight: isSmallDevice
                        ? "80%"
                        : isLargeDevice
                        ? "93%"
                        : "90%",
                    },
                  ]}
                  icon={isFabOpen.open ? "plus" : "arrow-up"}
                  actions={[
                    {
                      icon: "plus",
                      onPress: () => {},
                    },
                    {
                      icon: "arrow-up",
                      onPress: () =>
                        scrollRef.current?.scrollTo({
                          x: 0,
                          y: 0,
                          animated: true,
                        }),
                    },
                  ]}
                  visible={false}
                  onStateChange={onFabOpenChange}
                  onPress={() => {
                    if (isFabOpen.open) {
                      // do something if the speed dial is open
                    }
                  }}
                />
                <LabelBannerWithSearchBar
                  titleName={`Total Items: ${itemsPageLevelList.length}`}
                  handleSearch={handleSearch}
                  setRefresh={setRefresh}
                />
                <DataTableWithPagination
                  headers={tableHeaders}
                  rows={itemsPageLevelList}
                  isDataLoading={isSearched}
                  itemsPerPage={20}
                  useModal={true}
                  setModalVisible={setModalVisible}
                  setSelected={setSelected}
                  isDetail={true}
                  columnsToNotHaveThousandSeparator={
                    columnsToNotHaveThousandsSeparator
                  }
                  handleRowClick={() => {}}
                  handleDeleteRow={() => {}}
                  handleSortColumn={onHandleSortColumn}
                />
              </View>
            ) : (
              <View>
                <Spinner
                  size="small"
                  color="red"
                  styles={{ flexDirection: "row", margin: "20%" }}
                />
              </View>
            )}
          </View>
        </View>
        <Modal.ModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          childern={ModalContent}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  fab: {
    bottom: -10,
    position: "absolute",
    zIndex: 1,
  },
});
