export const drawerItemsMain = [
  {
    key: "Containers Management",
    title: "Containers Status",
    routes: [
      {
        nav: "ContainersManagement",
        routeName: "ContainersManagement",
        title: "Containers Status",
      },
    ],
  },
  {
    key: "Add Container",
    title: "Add Container",
    routes: [
      {
        nav: "ManageContainers",
        routeName: "ManageContainers",
        title: "Add Container",
      },
    ],
  },
  // {
  //   key: "Items Management",
  //   title: "Items Management",
  //   routes: [
  //     {
  //       nav: "ItemsManagement",
  //       routeName: "ItemsManagement",
  //       title: "Items Management",
  //     },
  //   ],
  // },
];
