import { useState, useRef, useEffect } from "react";
import { View, StyleSheet, Text, Picker } from "react-native";
import { Menu } from "react-native-paper";
import Icons from "@expo/vector-icons/MaterialCommunityIcons";
import { useHover } from "react-native-web-hooks";
import * as Okta from "../../services/oktaAuthService";
import roleConfig from "../../utils/constants/roles";
import oktaConfig from "../../configs/okta";
import * as Services from "../../utils/common";
import useColorScheme from "../../hooks/useColorScheme";
import { getRole } from "../../services/usersService";
import { getS3SignUrl } from "../../services/s3BucketService";
import { useSelector } from "react-redux";
import { companyModel } from "../../models/companyModel";

import { ConfigurationDispatcher } from "../../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../../redux/constants/actionTypesConfigurations";
import {
  clearSessionStorage,
  setItemInSessionStorage,
} from "../../services/utils/storageService";

export default function AvatarMenu(props: any) {
  const { companies, currentCompany, currentRole, navigation, userInfo } =
    useSelector((state: any) => state.configurations);

  const colorScheme = useColorScheme();
  const outOfBoxRef = useRef(null);
  const settingsRef = useRef(null);
  const logoutRef = useRef(null);
  const switchRef = useRef(null);
  const userManagementRef = useRef(null);
  const orgManagementRef = useRef(null);
  const isSwitchHovered = useHover(switchRef);
  const isSettingsHovered = useHover(settingsRef);
  const isLogoutHovered = useHover(logoutRef);
  const isUserManagementHovered = useHover(userManagementRef);
  const isOrgManagementHovered = useHover(orgManagementRef);
  const [showTeam, setShowTeam] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>(
    companies.length ? currentCompany.company_id : ""
  );

  const setHasAvatarMenu = (data: boolean) => {
    ConfigurationDispatcher(actionTypesConfigurations.HasAvatarMenu, data);
  };

  const logoutHandler = () => {
    Okta.deleteSession().then(() => {
      clearSessionStorage();
      window.location.href = `${oktaConfig.api.redirectUri}`;
    });
  };

  const selectedView = (selectedTitle: string) => {
    return (
      <Text style={{ fontSize: 14, color: "black" }}>{selectedTitle}</Text>
    );
  };

  const checkOutsideClick = (ref: any) => {
    useEffect(() => {
      let isMounted = true;
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target) && isMounted) {
          setHasAvatarMenu(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        isMounted = false;
      };
    }, [ref]);
  };

  const pickerItemView = () => {
    const pickerItems = companies.map((company: companyModel) => (
      <Picker.Item
        key={Services.makeid(4)}
        label={company.company_name.toUpperCase()}
        value={company.company_id}
      />
    ));
    return pickerItems;
  };

  const pickerHandler = (itemValue: string) => {
    setSelectedValue(itemValue);

    const selectedCompanyId = itemValue;
    var company = companies.filter((comp: companyModel) => {
      return [selectedCompanyId].indexOf(comp.company_id) > -1;
    });
    ConfigurationDispatcher(
      actionTypesConfigurations.CurrentCompany,
      company[0]
    );

    setItemInSessionStorage(
      "company-context",
      JSON.stringify({
        company_id:
          typeof company[0] !== "undefined"
            ? company[0].company_id
            : "0000000001",
        company_name:
          typeof company[0] !== "undefined"
            ? company[0].company_name
            : "Multisteps",
      })
    );

    const companyId = company[0].company_id; //Multisteps Company ID
    getS3SignUrl(
      "multisteps-go-public-image",
      `logo/${companyId}.png`,
      "getObject",
      "image/*"
    ).then((data) => {
      setItemInSessionStorage("logo-context", data?.signedUrl);
    });

    var company = companies.filter((comp: companyModel) => {
      return [selectedValue].indexOf(comp.company_id) > -1;
    });
    getRole(company[0].company_id).then((data) => {
      ConfigurationDispatcher(
        actionTypesConfigurations.CurrentRole,
        data?.role
      );
    });
  };

  checkOutsideClick(outOfBoxRef);

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <View ref={outOfBoxRef} style={styles.container}>
      <View>
        <View style={{ flexDirection: "row", marginLeft: 10 }}>
          <Icons
            style={{ marginTop: 10 }}
            name="account-box"
            size={18}
            color="black"
          />
          <Text style={[styles.label2]}>{userInfo.display_name}</Text>
        </View>
        <View
          style={[
            {
              marginTop: 10,
              borderBottomWidth: 0.1,
              borderColor: "lightgrey",
            },
          ]}
        />
        {currentCompany && showTeam ? (
          <View style={{ flexDirection: "row" }}>
            <Text style={[styles.label, { color: "#808080" }]}>TEAM:</Text>
            <Text
              style={[styles.label, { color: "black", fontWeight: "bold" }]}
            >
              {userInfo.team.toUpperCase()}
            </Text>
          </View>
        ) : !props.currentCompany && showTeam ? (
          <View style={{ flexDirection: "row" }}>
            <Text style={[styles.label, { color: "#808080" }]}>Role:</Text>
            <Text
              style={[styles.label, { color: "black", fontWeight: "bold" }]}
            >
              {roleConfig.roles.standard}
            </Text>
          </View>
        ) : (
          <View />
        )}
        {showTeam ? (
          <View
            style={[
              {
                marginTop: 10,
                borderBottomWidth: 0.1,
                borderColor: "lightgrey",
              },
            ]}
          />
        ) : (
          <View />
        )}
        <View
          ref={settingsRef}
          style={[
            {
              flexDirection: "row",
              width: "95%",
              alignSelf: "center" /*marginLeft: 10*/,
            },
            isSettingsHovered && styles.hover,
            props.title === "User Settings" && styles.active,
          ]}
        >
          <Icons
            style={{ marginTop: 15 }}
            name="account"
            size={18}
            color="black"
          />
          <Menu.Item
            onPress={() => (
              setHasAvatarMenu(false),
              navigation.navigate("UserSettings", { screen: "Profile" })
            )}
            title={selectedView("Account Settings")}
          />
        </View>
        {currentCompany &&
        (currentRole === roleConfig.roles.admin ||
          currentRole === roleConfig.roles.owner) ? (
          <View
            ref={userManagementRef}
            style={[
              {
                flexDirection: "row",
                width: "95%",
                alignSelf: "center" /*marginLeft: 10*/,
              },
              isUserManagementHovered && styles.hover,
              props.title === "Users Management" && styles.active,
            ]}
          >
            <Icons
              style={{ marginTop: 15 }}
              name="account-multiple"
              size={18}
              color="black"
            />
            <Menu.Item
              onPress={() => (
                setHasAvatarMenu(false),
                navigation.navigate("OrgSettings", { screen: "Teams" })
              )}
              title={selectedView("Users Management")}
            />
          </View>
        ) : (
          <View ref={userManagementRef} />
        )}
        {currentCompany &&
        (currentRole === roleConfig.roles.admin ||
          currentRole === roleConfig.roles.owner) ? (
          <View
            ref={orgManagementRef}
            style={[
              {
                flexDirection: "row",
                width: "95%",
                alignSelf: "center" /*marginLeft: 10*/,
              },
              isOrgManagementHovered && styles.hover,
              props.title === "Organization Management" && styles.active,
            ]}
          >
            <Icons
              style={{ marginTop: 15 }}
              name="store"
              size={18}
              color="black"
            />
            <Menu.Item
              onPress={() => (
                setHasAvatarMenu(false),
                navigation.navigate("OrgSettings", { screen: "Company" })
              )}
              title={selectedView("Organization Settings")}
            />
          </View>
        ) : (
          <View ref={orgManagementRef} />
        )}
        {currentCompany ? (
          <View
            ref={switchRef}
            style={[
              {
                flexDirection: "row",
                width: "95%",
                alignSelf: "center" /*marginLeft: 10*/,
              },
              props.title === "Organization Switch" && styles.active,
            ]}
          >
            <Icons
              style={{ marginTop: 15 }}
              name="electric-switch-closed"
              size={18}
              color="black"
            />
            <Picker
              style={{
                fontSize: 13,
                fontWeight: "bold",
                maxWidth: currentCompany.company_name.length * 20,
                borderColor: colorScheme === "light" ? "white" : "black",
                marginTop: "3.5%",
                marginLeft: "3.5%",
                // backgroundColor: colorScheme === 'light' ? 'white' : 'black',
                backgroundColor: colorScheme === "light" ? "white" : "white",
                color: colorScheme === "light" ? "grey" : "white",
              }}
              selectedValue={selectedValue}
              onValueChange={(itemValue, itemIndex) => pickerHandler(itemValue)}
            >
              {pickerItemView()}
            </Picker>
          </View>
        ) : (
          <View ref={switchRef} />
        )}
        <View
          style={[
            {
              marginTop: 10,
              borderBottomWidth: 0.1,
              borderColor: "lightgrey",
            },
          ]}
        />
        <View
          ref={logoutRef}
          style={[
            {
              flexDirection: "row",
              width: "95%",
              alignSelf: "center" /*marginLeft: 10*/,
            },
            isLogoutHovered && styles.hover,
            props.title === "Logout" && styles.active,
          ]}
        >
          <Icons
            style={{ marginTop: 15 }}
            name="logout"
            size={18}
            color="black"
          />
          <Menu.Item
            onPress={() => logoutHandler()}
            title={selectedView("Logout")}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: 140,
  },
  label: {
    fontWeight: 500,
    fontSize: 15,
    marginTop: 10,
    marginLeft: 10,
    justifyContent: "center",
  },
  label2: {
    fontWeight: "bold",
    flexWrap: "wrap",
    fontSize: 15,
    marginTop: 10,
    marginLeft: 10,
  },
  active: {
    color: "blue",
    opacity: 1.0,
  },
  hover: {
    backgroundColor: "#bcd4e6",
    opacity: 1.0,
  },
});
