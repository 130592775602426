import { sortDirection } from "./constants/genericTypes";
import { sortCollection } from "./sortCollection";

/**
 * This method allows to sort a column and to add/update the order
 * within the headers of the collection
 * @param {Array<unknown>} listOfRecords - list containing the data to process
 * @param {string} columnKey - column on which sorting needs to be applied
 * @param {Record<string, Record<string, string | number | boolean>>} tableHeaders - an object containing the header value for the table
 * @param {(e: Record<string, Record<string, string | number | boolean>>) => void} setTableHeaders - callback to update the headers
 */

export const sortColumns = (
  listOfRecords: Array<unknown>,
  columnKey: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  setTableHeaders: (
    e: Record<string, Record<string, string | number | boolean>>
  ) => void
) => {
  //Setting Headers to have updated sortDirection Icon
  const columnHeaders = { ...tableHeaders };
  const currentColumn = columnHeaders[columnKey];

  //if there is no sortDirection, set ASC else follow the toggle between ASC/DESC
  const isColumnSorted = currentColumn.sortDirection
    ? currentColumn.sortDirection === sortDirection.Ascending
      ? sortDirection.Descending
      : sortDirection.Ascending
    : sortDirection.Ascending;

  const alteredColumn = {
    ...currentColumn,
    sortDirection: isColumnSorted,
    isSort: true,
  };

  const updatedTableHeaders = {
    ...columnHeaders,
    [columnKey]: alteredColumn,
  };

  setTableHeaders(updatedTableHeaders);

  //Based on the current column, sort the container's list
  const _itemsList = [...listOfRecords];
  return sortCollection(updatedTableHeaders, "sortDirection", _itemsList);
};
