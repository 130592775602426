import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View } from "../../shared/Themed";
import { ReadOnlyProps } from "../../../utils/templates";

export interface Props {
  childern: React.ElementType;
  titleName: string;
  school?: Array<Record<string, string | number | boolean | unknown>>;
  clickedSave?: number;
  isEditable?: boolean;
  isLinkedin?: boolean;
  isSubmit?: boolean;
  setEditable?: (e: boolean) => void;
  setSubmit?: (e: boolean) => void;
  setClickedSave?: (e: number) => void;
  setSchool?: (e: any) => void;
  setLinkedin?: (e: boolean) => void;
}

export default class LabelBanner extends React.Component<ReadOnlyProps<Props>> {
  constructor(props: ReadOnlyProps<Props>) {
    super(props);
  }

  render() {
    const Children = this.props.childern;
    const handleSumbit = () => {
      this.props.setEditable?.(false);
      this.props.setSubmit?.(true);
    };

    return (
      <View>
        <View style={[styles.container]}>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              height: 50,
              alignItems: "flex-start",
              justifyContent: "flex-start",
              backgroundColor: this.props.isEditable ? "#fece85" : "white",
              borderBottomWidth: 1,
              borderColor: "darkgrey",
            }}
          >
            <Text
              style={[
                styles.title,
                {
                  width: this.props.isEditable ? "50%" : "50%",
                },
              ]}
              lightColor="rgba(0,0,0,0.8)"
              darkColor="rgba(255,255,255,0.8)"
            >
              {this.props.titleName}
            </Text>
            {this.props.isEditable && (
              <TouchableOpacity
                style={[
                  styles.button,
                  { width: "20%", backgroundColor: "grey" },
                ]}
                onPress={() => this.props.setEditable?.(!this.props.isEditable)}
              >
                <Text style={styles.buttonText}>{"Cancel"}</Text>
              </TouchableOpacity>
            )}
            {this.props.isEditable && (
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    width: "20%",
                    backgroundColor: this.props.isEditable
                      ? "#7F00FF"
                      : "#b22442",
                  },
                ]}
                onPress={() =>
                  this.props.isEditable
                    ? handleSumbit()
                    : this.props.setEditable?.(true)
                }
              >
                <Text style={styles.buttonText}>
                  {this.props.isEditable ? "Save" : "Edit"}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {this.props.childern && <Children {...this.props} />}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    borderWidth: 0.1,
    width: "100%",
    borderRadius: 2,
    borderColor: "#F5F5F5",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: "2%",
    justifySelf: "center",
    alignSelf: "center",
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -10, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    borderWidth: 0.5,
    borderRadius: 10,
    elevation: 3,
    marginRight: "1%",
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: 0.25,
    color: "white",
  },
});
