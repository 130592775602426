import {
  sortDirection as _sortDirection,
  sortDirection,
} from "../utils/constants/genericTypes";
import { orderBy } from "lodash";

export const sortByDirection = (
  sortDirections: Array<sortDirection>,
  columnKeys: Array<string>,
  list: any
) => {
  return orderBy(list, columnKeys, sortDirections);
};
