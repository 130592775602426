import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import mapiConfig from "../configs/mapi";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = mapiConfig.api.domain;

export const googlePlaceAutoComplete = async (location: string) => {
  const methodPath = `/google/search/city/${location}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      result.data.forEach(
        (place: any) =>
          delete Object.assign(place, { name: place.description })[
            "description"
          ]
      );

      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};
