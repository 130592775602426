import { rolesModel, userClaimInformation } from "../../models/usersModel";
import { addUser, getUsers, updateUser } from "../../services/usersService";
import { extractCompanyLogo, extractImageFromS3 } from "./extractCompanyLogo";
import oktaConfig from "../../configs/okta";
import { extractCompanyId } from "./extractCompanyId";
import { ConfigurationDispatcher } from "../../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../../redux/constants/actionTypesConfigurations";

export const InitUser = (
  isMounted: boolean,
  setLoading: (e: boolean) => void,
  setLogo: (e: string) => void
) => {
  const setUserInfo = (data: userClaimInformation) => {
    ConfigurationDispatcher(actionTypesConfigurations.UserInfo, data);
  };

  const setUsersRoles = (data: Array<rolesModel>) => {
    const roles: Array<string> = [];
    data.map((r: rolesModel) => {
      roles.push(r.role);
    });

    ConfigurationDispatcher(actionTypesConfigurations.UserRoles, roles);
  };

  const setHasAvatar = (data: boolean) => {
    ConfigurationDispatcher(actionTypesConfigurations.HasAvatar, data);
  };

  const setProfilePhoto = (data: string) => {
    ConfigurationDispatcher(actionTypesConfigurations.ProfilePhoto, data);
  };

  const setCurrentRole = (data: string) => {
    ConfigurationDispatcher(actionTypesConfigurations.CurrentRole, data);
  };

  const setInitials = (data: string) => {
    ConfigurationDispatcher(actionTypesConfigurations.Initial, data);
  };

  return new Promise((resolve, reject) => {
    try {
      getUsers(false).then(async (mapiData) => {
        const { users }: { users: Array<userClaimInformation> } = mapiData;

        if (users.length) {
          const user = users[0];
          setUserInfo(user);
          setUsersRoles(user.roles || []);

          // Passing empty user object will sync user data from Okta
          user.auto_sync && updateUser({}, "");
          if (isMounted) {
            extractCompanyId().then((_companyItem) => {
              extractCompanyLogo(
                _companyItem.companyId,
                setLogo,
                setCurrentRole,
                user.roles || []
              );
            });
          }

          if (user.has_avatar) {
            extractImageFromS3("avatar", user.user_id).then((data) => {
              setProfilePhoto(data?.signedUrl);
              setHasAvatar(true);
              setLoading(false);
            });
          } else {
            setHasAvatar(false);
            setLoading(false);
          }
        }
        // temporary solution until signup is ready
        else if (!users.length) {
          const user = users[0];

          await addUser().then((result) => {
            const userProfile = (result &&
              result.users) as userClaimInformation;

            const userInfo = mapiData && users.length ? user : userProfile;
            setUserInfo(userInfo);
            setUsersRoles(userInfo.roles || []);

            if (isMounted) {
              const fullName =
                mapiData && users.length
                  ? user.display_name || ""
                  : userProfile.display_name || "";

              setInitials(fullName);
              setLoading(false);
            }

            window.location.href = `${oktaConfig.api.redirectUri}`;
          });
        }
      });

      resolve(200);
    } catch (error) {
      reject(error);
    }
  });
};
