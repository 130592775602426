import store from "../configureStore";
import allActions from "../actions";
import { containersModal } from "../../models/containersModel";
import { actionTypesContainers } from "../constants/actionTypesContainers";

const { dispatch } = store;

export const ContainersDispatcher = (
  actionType: actionTypesContainers,
  data: unknown
) => {
  const containersAction = allActions.containerActions;

  switch (actionType) {
    case actionTypesContainers.ListOfContainers:
      dispatch(
        containersAction.setContainersList(data as Array<containersModal>)
      );
      break;
    case actionTypesContainers.IsContainersTableRefreshed:
      dispatch(containersAction.setIsContinersTableRefreshed(data as boolean));
      break;
    case actionTypesContainers.SelectedContainer:
      dispatch(containersAction.setSelectedContainer(data as containersModal));
      break;
  }
};
