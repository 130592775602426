import CryptoJS from "crypto-js";

const encryptedBase64Key = process.env.ENCRYPTION_KEY as string;
const parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);

export const encrypt = (value: any) => {
  let encryptedData = null;

  encryptedData = CryptoJS.AES.encrypt(value.trim(), parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encryptedData.toString();
};

export const decrypt = (value: any) => {
  var decryptedData = CryptoJS.AES.decrypt(value.trim(), parsedBase64Key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
};
