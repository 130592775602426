import { Card } from "react-native-paper";
import { StyleSheet, Text, View } from "react-native";
import React from "react";

interface Props {
  title: string;
  subTitle?: string;
  cardCSS?: any;
  cardTitleCSS?: any;
  cardContentCSS?: any;
  cardInnerContentCSS?: any;
  cardContent: React.ReactChild;
}

export const ScanCard = (props: Props) => {
  return (
    <Card mode="elevated" style={[props.cardCSS]}>
      <Card.Title
        title={<Text style={styles.cardTitleStyles}>{props.title}</Text>}
        subtitle={props.subTitle}
        style={[styles.cardTitle, props.cardTitleCSS]}
      />
      <Card.Content
        style={[props.cardContentCSS, { padding: 0, border: "1px solid #eee" }]}
      >
        <View style={[props.cardInnerContentCSS]}>{props.cardContent}</View>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    padding: "2%",
    width: "100%",
  },
  cardTitleStyles: {
    //color: "rgb(128, 128, 128)",
    fontSize: 18,
    fontWeight: "bold",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  cardTitle: {
    borderWidth: 1,
    borderColor: "rgba(216, 216, 216, 0.4)",
  },
  bold: {
    fontWeight: "bold",
  },
  fab: {
    bottom: -10,
    position: "absolute",
    zIndex: 1,
  },
});
