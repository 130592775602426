import { pad } from "./common";

export const formatDate = (
  date: any,
  monthType?: any,
  isTimeInclude: boolean = false
) => {
  let d = new Date(date);

  //Formatting Date
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", {
    month: monthType || "numeric",
  }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

  //Formatting Time
  let time = new Intl.DateTimeFormat("en", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }).format(d);

  return `${da}-${mo}-${ye} ${isTimeInclude ? `${time}` : ""}`;
};

export const checkIsDate = (date: any) => {
  const parsedDate = Date.parse(date);

  if (isNaN(date) && !isNaN(parsedDate)) {
    return true;
  }
  return false;
};

export const checkIsNumber = (value: any) => {
  if (isNaN(value)) {
    return false;
  }
  return true;
};

export const numberToThousandSeparator = (value: any) => {
  return value ? new Intl.NumberFormat("en-AU").format(value) : "0";
};

export const addDaysToDate = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const subtractDaysFromDate = (date: Date, days: number) => {
  date.setDate(date.getDate() - days);
  return date;
};

export const isToday = (selectedDate: Date) => {
  const today = new Date();
  return (
    selectedDate.getDate() == today.getDate() &&
    selectedDate.getMonth() == today.getMonth() &&
    selectedDate.getFullYear() == today.getFullYear()
  );
};

export const formatDateToMongoISOFormat = (date: Date) => {
  return Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};

/**
 * This method returns complete days within a month
 * based on the month value, if you add 1 month in the
 * passed value then you will get days in current month
 * else if you subtract a month you will get days
 * in previous month to the date
 * @param {number} month - value based on which total days in a month is being passed
 * @return {string[]} - returns an array of string containing all the days in a month
 */
export const getCompleteMonthDays = (month: number) => {
  const endDate = new Date(new Date().getFullYear(), month, 0);
  const days: Array<string> = [];

  for (let index = 1; index <= endDate.getDate(); index++) {
    days.push(pad(index));
  }
  return days;
};

export const getStartEndDatesForAMonth = (
  date: Date,
  isCurrentDateBeEndDate: boolean
) => {
  const _date = new Date(date);
  const isCurrentMonth = new Date().getMonth() === _date.getMonth();
  // year - 1 will be applied when month is January
  const year = _date.getFullYear();

  //getMonth + 1 returns the current month as getMonth() is zero indexed
  const month = _date.getMonth();

  return {
    startDate: new Date(year, month, 1),
    endDate: isCurrentDateBeEndDate
      ? new Date(_date)
      : new Date(year, isCurrentMonth ? month : month + 1, 0),
  };
};

export const getYesterdayFromDate = (date: Date) => {
  let calculatedYesterday = new Date(date);
  calculatedYesterday.setDate(calculatedYesterday.getDate() - 1);

  return calculatedYesterday;
};

export const getFirstDayOfTheMonth = (date: Date) => {
  const calculatedFirstDayOfTheMonth = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  );

  return calculatedFirstDayOfTheMonth;
};
