export const headers = {
  artwork_id: {
    name: "Artwork ID",
    width: 0.5,
    textAlign: "left",
  },
  artwork_name: {
    name: "Artwork Name",
    width: 1.7,
    textAlign: "left",
  },
  version_number: {
    name: "Version",
    width: 0.6,
    textAlign: "left",
  },
  pending_artwork_numbers: {
    name: "Pending Numbers",
    width: 1,
    textAlign: "left",
  },
  approved_artwork_number: {
    name: "Approved Number",
    width: 1,
    textAlign: "left",
  },
  label_size: {
    name: "Label Size",
    width: 0.6,
    textAlign: "left",
  },
  barcode: {
    name: "Barcode",
    width: 0.6,
    textAlign: "left",
  },
  date: {
    name: "Updated Date & time",
    width: 1.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  // created_by: {
  //   name: "Created By",
  //   width: 1,
  //   textAlign: "left",
  // },
  "": { name: "", width: 0.3, textAlign: "" },
};

export const MediumDeviceHeaders = {
  artwork_id: {
    name: "Artwork ID",
    width: 0.5,
    textAlign: "left",
  },
  artwork_name: {
    name: "Artwork Name",
    width: 2,
    textAlign: "left",
  },
  version_number: {
    name: "Version",
    width: 0.6,
    textAlign: "left",
  },
  pending_artwork_numbers: {
    name: "Pending Numbers",
    width: 1,
    textAlign: "left",
  },
  approved_artwork_number: {
    name: "Approved Number",
    width: 1,
    textAlign: "left",
  },
  date: {
    name: "Updated Date",
    width: 1,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  "": { name: "", width: 0.3, textAlign: "" },
};

export const SmallDeviceHeaders = {
  artwork_id: {
    name: "Artwork ID",
    width: 1,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  artwork_name: {
    name: "Artwork Name",
    width: 3,
    textAlign: "left",
  },
  version_number: {
    name: "Version",
    width: 0.6,
    textAlign: "left",
  },
  "": { name: "", width: 0.3, textAlign: "" },
};
