import { actionTypesArtwork as actionTypes } from "./../constants/actionTypesArtwork";
import { artworkModel } from "../../models/artworkModel";

const setArtworkList = (data: Array<artworkModel>) => {
  return {
    type: actionTypes.ListOfArtwork,
    payload: data,
  };
};

export default {
  setArtworkList,
};
