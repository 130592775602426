import { combineReducers } from "redux";

import containersReducer from "../reducers/containersReducer";
import itemsReducer from "./itemsReducer";
import configurationReducer from "./configurationsReducer";
import artworkReducer from "./artworkReducer";
import reportsReducer from "./reportsReducer";

const rootReducer = combineReducers({
  configurations: configurationReducer,
  containers: containersReducer,
  items: itemsReducer,
  artwork: artworkReducer,
  reports: reportsReducer,
});

export default rootReducer;
