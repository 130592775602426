import React, { useState } from "react";
import { TextInput, TouchableOpacity, StyleSheet } from "react-native";
import * as Okta from "../../../services/oktaAuthService";
import { Text, View } from "../../../components/shared/Themed";
import { useSelector } from "react-redux";
import Spinner from "../../../components/shared/Spinner";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

function LoginForm() {
  const { currentCompany, navigation, userInfo } = useSelector(
    (state: any) => state.configurations
  );

  const companyContext = JSON.parse(
    getItemFromSessionStorage("company-context") as string
  );
  const [username, setUsername] = useState(userInfo.login);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<String>("");
  const disabled =
    (currentCompany && currentCompany.company_name === "Multisteps") ||
    companyContext === null;

  const submitHandler = () => {
    setLoading(true);
    Okta.forgotPassword(userInfo.email).then((data) => {
      setStatus(data.status);
      setLoading(false);
      navigation.navigate("Login");
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <Text style={styles.label2}>
          Your log details and update your password.
        </Text>
        <Text style={styles.label}>Username</Text>
        <TextInput
          style={styles.input}
          onChangeText={setUsername}
          placeholder="Enter your email address or username"
          value={username}
          disabled={disabled}
        />
        {isLoading && (
          <Spinner styles={styles.spinner} size="small" color="#00ff00" />
        )}
        {companyContext !== null &&
          companyContext.company_name !== "Multisteps" && (
            <TouchableOpacity
              style={styles.button}
              onPress={submitHandler}
              disabled={disabled}
            >
              <Text style={styles.buttonText}>Reset Password</Text>
            </TouchableOpacity>
          )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
  },
  button: {
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#6495ED",
    margin: 20,
  },
  buttonText: {
    fontSize: 17,
    lineHeight: 21,
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: 0.25,
    color: "white",
  },
  label: {
    minWidth: "10%",
    maxWidth: 500,
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    // marginDown: 14,
    marginLeft: 14,
  },
  label2: {
    minWidth: "10%",
    maxWidth: 1000,
    fontSize: 15,
    textAlign: "left",
    // marginDown: 14,
    marginLeft: 14,
    margin: 50,
  },
  input: {
    minWidth: "10%",
    maxWidth: 500,
    height: 60,
    backgroundColor: "#fff",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: "#ccc",
    borderWidth: 2,
    borderRadius: 15,
    fontSize: 15,
    margin: 20,
  },
  spinner: {
    flexDirection: "column",
  },
});

export default LoginForm;
