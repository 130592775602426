import { useState, useEffect, useContext } from "react";
import { View } from "../../../components/shared/Themed";
import OperationTopTabNavigator from "../../OperationUserNavigator/TabNavigator/TopTabNavigator";
import OperationBottomTabNavigator from "../../OperationUserNavigator/TabNavigator/BottomTabNavigator";
import StandardTopTabNavigator from "../../StandardUserNavigator/TabNavigation/TopTabNavigator";
import StandardBottomTabNavigator from "../../StandardUserNavigator/TabNavigation/BottomTabNavigator";
import CompanyLogo from "../../../components/core/CompanyLogo";
import Avatar from "../../../components/core/Avatar";

import * as Okta from "../../../services/oktaAuthService";
import teamConfig from "../../../utils/constants/teams";
import { userClaimInformation } from "../../../models/usersModel";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { useSelector } from "react-redux";
import { InitUser } from "../../utils/initUser";
import { ConfigurationDispatcher } from "../../../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../../../redux/constants/actionTypesConfigurations";
import Spinner from "../../../components/shared/Spinner";
import {
  clearSessionStorage,
  getItemFromSessionStorage,
} from "../../../services/utils/storageService";

export default function RootTabNavigator({ navigation }: any) {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [userProfile, setUserProfile] = useState<userClaimInformation>();

  const { isSmallDevice } = useContext(DeviceOrientationContext);

  const { isUserAuthorized, currentCompany, userInfo } = useSelector(
    (state: any) => state.configurations
  );

  const setLogo = (data: string) => {
    ConfigurationDispatcher(actionTypesConfigurations.Logo, data);
  };

  const setIsUserAuthorized = (data: boolean) => {
    ConfigurationDispatcher(actionTypesConfigurations.IsUserAuthorized, data);
  };

  useEffect(() => {
    setUserProfile(userInfo);
  }, [userInfo]);

  useEffect(() => {
    ConfigurationDispatcher(actionTypesConfigurations.Navigation, navigation);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (!isUserAuthorized) {
      Okta.getSession().then((oktaData) => {
        if (oktaData === null) {
          clearSessionStorage();
          navigation.navigate("Login", { screen: "login" });
        } else {
          setIsUserAuthorized(true);

          // Get user details from mongodb, Okta is only using for authenication
          InitUser(isMounted, setLoading, setLogo);

          const inviteContext = getItemFromSessionStorage("invite-context");
          if (inviteContext) {
            window.location.href = inviteContext;
          }
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [currentCompany]);

  const { sales, operation, manager, admin, warehouse, production } =
    teamConfig.teams;
  const teams = [operation, manager, admin, warehouse];
  const limitedTeam = [sales, production];

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: "white",
        flexDirection: "row",
      }}
    >
      {isUserAuthorized && !isLoading ? (
        <View style={{ height: "100%", width: "100%", flexDirection: "row" }}>
          {!isSmallDevice && <CompanyLogo />}
          {isSmallDevice &&
            limitedTeam.includes(userProfile?.team.toUpperCase()) && (
              <StandardBottomTabNavigator />
            )}
          {isSmallDevice && teams.includes(userProfile?.team.toUpperCase()) && (
            <OperationBottomTabNavigator />
          )}
          {!isSmallDevice &&
            limitedTeam.includes(userProfile?.team.toUpperCase()) && (
              <StandardTopTabNavigator />
            )}
          {!isSmallDevice &&
            teams.includes(userProfile?.team.toUpperCase()) && (
              <OperationTopTabNavigator />
            )}
          {isSmallDevice && <CompanyLogo />}
          <Avatar />
        </View>
      ) : (
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            size="small"
            color="#00ff00"
            styles={{
              flexDirection: "row",
              justifySelf: "center",
              alignSelf: "center",
            }}
          />
        </View>
      )}
    </View>
  );
}
