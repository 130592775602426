import mapiConfig from "../configs/mapi";
import { getUserInfo } from "./usersService";

import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = mapiConfig.api.domain;
const Controller = "/company";

export const getCompanyDetailsByUserId = async () => {
  const userInfo = await getUserInfo();
  const userId: string = userInfo.sub;
  const methodPath = `${Controller}/search/userid/${userId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        company: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const getCompanyDetails = async (companyId: string) => {
  const methodPath = `${Controller}/search/${companyId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        company: result.data[0],
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const updateCompany = async (httpBody: any, companyId: string) => {
  const methodPath = `${Controller}/update/${companyId}`;
  const _httpBody = Object.keys(httpBody).length ? httpBody : {};

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: _httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};
