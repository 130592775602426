import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Text, View } from "../shared/Themed";
import { Button } from "react-native-paper";

export interface Props {
  isSmallDevice: string;
  childern?: String;
  addButtonText: String;
}

interface State {
  inputs: Object[];
}

export default class CustomEditableTextInput extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      work: [{ pendingNumber: "" }],
    };
  }

  render() {
    const onChangeText = (text: String, index: Number) => {
      var newWork = this.props.inputs;
      newWork[index].pendingNumber = text;
      this.setState({ work: newWork });
    };

    const CustomComponent = this.props.childern || {};
    const inputItemView = () => {
      const views = [];
      if (this.props.inputs.length) {
        this.props.inputs.forEach((input, i) => {
          views.push(
            <View key={i} style={{ width: "90%" }}>
              <View
                style={{ flexDirection: "row", width: "100%", marginTop: 10 }}
              >
                <Text
                  style={{
                    width: "60%",
                    fontSize: 15,
                    fontWeight: "bold",
                    textAlign: "left",
                    marginLeft: 20,
                  }}
                >
                  {i + 1}
                  {". "}
                  {this.props.title}
                </Text>
                <Button
                  style={{
                    width: "30%",
                    alignSelf: "flex-end",
                    justifySelf: "flex-end",
                  }}
                  icon="delete"
                  onPress={() => this.props.removeItem(i)}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  alignSelf: "flex-start",
                  justifySelf: "flex-start",
                }}
              >
                {this.props.childern && (
                  <CustomComponent
                    {...this.props}
                    index={i}
                    onChangeText={onChangeText}
                    value={this.props.inputs[i].pendingNumber}
                  />
                )}
              </View>
            </View>
          );
        });
        return views;
      }
    };

    return (
      <View style={{ width: "100%", marginLeft: 1 }}>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <View style={{ width: "100%" }}>
            <TouchableOpacity
              style={[
                styles.button,
                { width: "60%", backgroundColor: "#988fff", margin: 15 },
              ]}
              onPress={() => this.props.addItem()}
            >
              <Text style={styles.buttonText}>{this.props.addButtonText}</Text>
            </TouchableOpacity>
          </View>
        </View>
        {inputItemView()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: "2%",
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -10, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    height: 35,
    borderWidth: 0.5,
    borderRadius: 10,
    elevation: 3,
    marginRight: "1%",
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: 0.25,
    color: "white",
  },
});
