import { actionTypesReports as actionTypes } from "../constants/actionTypesReports";
import { reportsState } from "../state/reports";

const reportsReducer = (state = reportsState, action: any) => {
  switch (action.type) {
    case actionTypes.ListOfStockReport:
      return {
        ...state,
        stockReportList: action.payload,
        isStockReportListLoaded: true,
      };
    case actionTypes.LastRefreshStockReport:
      return {
        ...state,
        lastRefreshStockReport: action.payload,
      };
    case actionTypes.ListOfProductReport:
      return {
        ...state,
        productReportList: action.payload,
        isProductReportListLoaded: true,
      };
    case actionTypes.LastRefreshProductReport:
      return {
        ...state,
        lastRefreshProductReport: action.payload,
      };
      case actionTypes.ListOfCustomerStatus:
        return {
          ...state,
          customerStatusReport: action.payload,
          isCustomerStatusReportListLoaded: true,
        };
    case actionTypes.ListOfPmxInventory:
      return {
        ...state,
        pmxInventoryReport: action.payload,
        isPmxInventoryReportListLoaded: true,
      };
    case actionTypes.ListOfPmxInventoryDetail:
      return {
        ...state,
        pmxInventoryDetailReport: action.payload,
        isPmxInventoryDetailReportListLoaded: true,
      };
    case actionTypes.ListOfProductionStockReport:
      return {
        ...state,
        productionStockReport: action.payload,
        isProductionStockReportListLoaded: true,
      };
    case actionTypes.ListOfRawMaterialOverviewStockReport:
      return {
        ...state,
        rawMaterialOverviewReport: action.payload,
        isRawMaterialOverviewReportListLoaded: true,
      };
    case actionTypes.ListOfRawMaterialStockReport:
      return {
        ...state,
        rawMaterialStockReport: action.payload,
        isRawMaterialStockReportListLoaded: true,
      };
    default:
      return state;
  }
};

export default reportsReducer;
