import * as XLSX from "xlsx";
import { renameLabel } from "./common";

export const exportDataToExcel = (
  dataToExport: Array<any>,
  fileName?: string,
  sheetName?: string
): void => {
  const fileExtension = ".xlsx";
  const headers = Object.keys(dataToExport[0] || []).map((header: string) => {
    return renameLabel(header);
  });

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport || []);
  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${fileName}${fileExtension}`);
};
