import { StyleSheet, TouchableOpacity } from "react-native";

import { Text, View } from "../components/shared/Themed";
import { RootStackScreenProps } from "../types";
import oktaConfig from "../configs/okta";
import React from "react";

export default function NotFoundScreen({
  navigation,
}: RootStackScreenProps<"NotFound">) {
  const submitHandler = () => {
    window.location.href = `${oktaConfig.api.redirectUri}`;
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        Seems like you don't have permission to view this page or the page is
        not found, please contact helpdesk@multisteps.com.au
      </Text>
      <TouchableOpacity onPress={() => submitHandler()} style={styles.link}>
        <Text style={styles.linkText}>Go to home screen!</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
