import { defaultLog, logError } from "./utils/logService";
import oktaConfig from "../configs/okta";
import {
  OktaAuth,
  OktaAuthOptions,
  TokenManager,
  AccessToken,
  IDToken,
  UserClaims,
  TokenResponse,
} from "@okta/okta-auth-js";

import * as Sentry from "@sentry/browser";

// https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86pit5m9svnm4e&scope=r_liteprofile&state=123456&redirect_uri=https://auth.msteps.net/oauth2/v1/authorize/callback

const config: OktaAuthOptions = {
  issuer: oktaConfig.api.issuer,
  // Required for login flow using getWithRedirect()
  clientId: oktaConfig.api.clientId,
  redirectUri: oktaConfig.api.redirectUri,
  responseType: "code",
  pkce: false,
  // Configure TokenManager to use sessionStorage instead of localStorage
  tokenManager: {
    storage: "sessionStorage",
    autoRenew: true,
  },
};

const authClient: OktaAuth = new OktaAuth(config);
const tokenManager: TokenManager = authClient.tokenManager;

async function deleteSession() {
  // await authClient.revokeAccessToken(); // strongly recommended
  return authClient
    .closeSession()
    .then(() => {
      return {
        isLoading: false,
      };
    })
    .catch((e) => {
      Sentry.captureException(e);
      if (e.xhr && e.xhr.status === 429) {
        defaultLog(`Okta error: ${e}`);
        return {
          isLoading: false,
        };
      }
    });
}

async function getAuthWithLinkedin() {
  return authClient
    .signInWithRedirect({
      // Use a custom IdP for social authentication
      idp: "0oa169pupbo23HCAD0h8",
    })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch(function (err) {
      logError(err);
      Sentry.captureException(err);

      return {
        status: "FAILED",
        isLoading: false,
      };
    });
}

async function getAuth(username: string, password: string) {
  return authClient
    .signInWithCredentials({ username, password })
    .then((transaction) => {
      if (transaction.status === "SUCCESS") {
        authClient.token.getWithRedirect({
          sessionToken: transaction.sessionToken,
          responseType: "id_token",
        });
        // getSession();
        return {
          status: "ACTIVE",
          isLoading: false,
        };
      }
    })
    .catch((e) => {
      Sentry.captureException(e);
      return {
        status: "FAILED",
        isLoading: false,
      };
    });
}

async function getAccessToken() {
  return await authClient.token
    .getWithoutPrompt({
      responseType: ["token", "id_token"],
      scopes: ["openid", "profile"],
    })
    .then((data: TokenResponse) => {
      return {
        status: "ACTIVE",
        data: data,
      };
    })
    .catch(function (err) {
      Sentry.captureException(err);
      return {
        status: "FAILED",
      };
    });
}

async function getSession() {
  const response: Record<string, string | TokenResponse> =
    await getAccessToken();
  if (response.status === "FAILED") {
    return null;
  } else {
    tokenManager.setTokens((response.data as TokenResponse).tokens);
    const accessToken: AccessToken = (await tokenManager.get(
      "accessToken"
    )) as AccessToken;
    const idToken: IDToken = (await tokenManager.get("idToken")) as IDToken;
    const userInfo: UserClaims = await authClient.token.getUserInfo(
      accessToken,
      idToken
    );
    return authClient.session
      .get()
      .then(() => {
        return {
          userInfo: userInfo,
          idToken: idToken,
          accessToken: accessToken,
        };
      })
      .catch((err) => {
        Sentry.captureException(err);
        logError(`endpoint: getSession - Error: ${err}`);
      });
  }
}

async function forgotPassword(username: string) {
  return authClient
    .forgotPassword({
      username: username,
      factorType: "EMAIL",
    })
    .then(function (transaction) {
      if (transaction.status == "RECOVERY_CHALLENGE") {
        return {
          status: "ACTIVE",
        };
      } else {
        return {
          status: "FAILED",
        };
      }
    })
    .catch(function (err) {
      Sentry.captureException(err);
      return {
        status: err,
      };
    });
}

export {
  forgotPassword,
  getSession,
  getAuth,
  getAuthWithLinkedin,
  deleteSession,
  authClient,
  tokenManager,
};
