import { rolesModel } from "../../models/usersModel";
import { getS3SignUrl } from "../../services/s3BucketService";
import { setItemInSessionStorage } from "../../services/utils/storageService";

export const extractImageFromS3 = async (
  imageType: string,
  imageId: string
) => {
  return getS3SignUrl(
    "multisteps-go-public-image",
    `${imageType}/${imageId}.png`,
    "getObject",
    "image/*"
  );
};

export const extractCompanyLogo = (
  companyId: string,
  setLogo: (e: string) => void,
  setCurrentRole: (e: string) => void,
  userRoles: Array<rolesModel>
) => {
  return extractImageFromS3("logo", companyId).then((data) => {
    setItemInSessionStorage("logo-context", data?.signedUrl);
    setLogo(data?.signedUrl);

    const _currentRole =
      userRoles &&
      (userRoles.filter((role: rolesModel) => {
        return role.company_id === companyId;
      }) as Array<rolesModel>);

    setCurrentRole(_currentRole && _currentRole[0].role);
  });
};
