import { useContext, useEffect, useRef, useState } from "react";
import { StyleSheet, ScrollView, Image, TouchableOpacity } from "react-native";
import { Card, Divider, Text, Button } from "react-native-paper";
import { View } from "../../../components/shared/Themed";
import useColorScheme from "../../../hooks/useColorScheme";

import { TextInputControl } from "../../../components/custom/TextInputControl";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";

import { useForm, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import { ReadOnlyProps } from "../../../utils/templates";
import { getScanStatsByBoxIds } from "../../../services/barcodeService";
import { barcodeModal } from "../../../models/barcodeModel";
import Spinner from "../../../components/shared/Spinner";
import Icon from "react-native-vector-icons/AntDesign";
import { NoDataBanner } from "../../../components/shared/NoDataBanner";
import { convertScannerFeedToModel } from "../utils/scanCalculations";
import { userClaimInformation } from "../../../models/usersModel";
import { useSelector } from "react-redux";
import { ModalBody } from "../../../components/custom/Modal/ModalBody";
import { sortByDirection } from "../../../utils/sortDataTable";
import { sortDirection } from "../../../utils/constants/genericTypes";
import FAIcon from "react-native-vector-icons/FontAwesome5";

interface Props {
  navigation: any;
}

export default function ScanByManagerScreen({
  navigation,
}: ReadOnlyProps<Props>) {
  const toast = useToast();

  const scanDefaultValues = {
    itemsBarCode: "",
  };

  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );
  const { userInfo } = useSelector((state: any) => state.configurations);

  const [displaySpinner, setDisplaySpinner] = useState<boolean>(true);
  const [bulkScanInformation, setBulkScanInformation] = useState<
    barcodeModal[]
  >([]);

  const refTextInput = useRef<any>(null);

  useEffect(() => {
    if (refTextInput.current) {
      refTextInput.current.focus();
    }
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: scanDefaultValues,
  });

  const backToList = () => {
    resetScreen();
    navigation.navigate("ScanDashboard");
  };

  const CardTitleWithIcon = () => {
    return (
      <>
        <Button onPress={() => backToList()} mode="text">
          <Icon name={"leftcircleo"} size={16} color="black" />
        </Button>
        <Text style={styles.cardTitleStyles}>&nbsp;Upload Barcodes</Text> &nbsp;
        <Spinner
          isAnimating={!displaySpinner}
          size={18}
          color="rgb(47, 149, 220)"
        />
      </>
    );
  };

  const resetScreen = () => {
    reset(scanDefaultValues);
    refTextInput.current.focus();
  };

  const terminateOrResetSession = (message: string, displayType: string) => {
    toast.show(message, {
      type: displayType,
    });
    resetScreen();
  };

  const ScanCardsList = ({ scanData }: { scanData: barcodeModal }) => {
    const _orderedKeys = [
      "date",
      "line_number",
      "employee_id",
      "employee_name",
    ];
    const _specialLabels = {
      line_number: {
        oldLabel: "line_number",
        newLabel: "Machine #",
      },
    };

    return (
      <Card
        key={scanData.box_id}
        mode="elevated"
        style={[styles.card, { marginRight: "1%", marginLeft: "1%" }]}
      >
        <Card.Title
          title={scanData.box_id}
          style={[styles.cardTitle, styles.bgLightSilver]}
        />
        <Card.Content style={{ padding: 0 }}>
          <ModalBody
            listData={scanData}
            excludedKeys={[]}
            orderedKeys={_orderedKeys}
            specialLabels={_specialLabels}
            isDisplayTime={true}
          />
        </Card.Content>
      </Card>
    );
  };

  const onSubmit = async (formData: any) => {
    setDisplaySpinner(false);

    const { itemsBarCode } = formData;
    const _userInfo: userClaimInformation = userInfo;

    const scanData = convertScannerFeedToModel(
      itemsBarCode,
      _userInfo.user_id,
      `${userInfo.first_name} ${userInfo.last_name}`
    );

    getScanStatsByBoxIds(scanData)
      .then((result) => {
        if ((result.data as Array<barcodeModal>).length > 0) {
          const sortedScanResult = sortByDirection(
            [sortDirection.Descending],
            ["date"],
            result.data
          );
          setBulkScanInformation(sortedScanResult);
          resetScreen();
        } else {
          setBulkScanInformation([]);
          terminateOrResetSession(
            "Barcode does not exists in our system.",
            "danger"
          );
        }
      })
      .catch((err) => {
        terminateOrResetSession(err.message, "danger");
      })
      .finally(() => {
        setDisplaySpinner(true);
      });
  };

  return (
    <ScrollView>
      <View
        style={[
          styles.container,
          {
            backgroundColor: "white",
            flexDirection: !isSmallDevice || !isMobileDevice ? "row" : "column",
          },
        ]}
      >
        <View
          style={{
            flexGrow: !isSmallDevice || !isMobileDevice ? 0.1 : 1,
            paddingLeft: "2%",
            paddingBottom: "2%",
            paddingTop: "2%",
            paddingRight: "0%",
          }}
        >
          <Card
            testID="scanForm"
            mode="elevated"
            style={[
              styles.card,
              { width: isSmallDevice || isMobileDevice ? 360 : 400 },
            ]}
          >
            <Card.Title
              title={<CardTitleWithIcon />}
              style={[styles.cardTitle, styles.bgLightSilver]}
            />
            <Card.Content
              style={{
                padding: 0,
                borderColor: "#eee",
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <View style={[styles.box]}>
                <View>
                  <Text style={{ fontWeight: "bold" }}>
                    Upload data from Scanner
                  </Text>
                  <Image
                    source={{
                      uri: require("../../../assets/UPLOAD_DATA.png"),
                    }}
                    style={{ width: "100%", height: 50, marginTop: "1%" }}
                  />
                </View>
                <Divider style={{ marginTop: "2%" }} />
                <View>
                  <Controller
                    control={control}
                    rules={{
                      validate: {
                        required: async (value) => {
                          if (!value) return "* This is required.";
                        },
                      },
                    }}
                    name="itemsBarCode"
                    render={({ field: { onChange, value } }) => (
                      <TextInputControl
                        title="Barcodes"
                        required={true}
                        isMultiline={true}
                        width="100%"
                        height={300}
                        placeholder="Scan the upload barcode to let the scanner write the data in here."
                        value={value}
                        setChangeText={onChange}
                        blurOnSubmit={false}
                        editable={true}
                        refInput={refTextInput}
                        handleOnSubmitEditing={handleSubmit(onSubmit)}
                      />
                    )}
                  />
                  {errors.itemsBarCode && (
                    <Text style={styles.errorColor}>* This is required.</Text>
                  )}
                </View>
                <View>
                  <Button
                    mode="contained"
                    onPress={handleSubmit(onSubmit)}
                    loading={!displaySpinner}
                    color="rgb(47, 149, 220)"
                    style={{ marginTop: 15 }}
                  >
                    Fetch Records
                  </Button>
                </View>
                <Divider style={{ marginTop: "2%" }} />
                <View style={{ marginTop: "2%" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Clean data from Scanner
                  </Text>
                  <Image
                    source={{
                      uri: require("../../../assets/CLEAR_DATA.png"),
                    }}
                    style={{ width: "100%", height: 50, marginTop: "1%" }}
                  />
                </View>
              </View>
            </Card.Content>
          </Card>
        </View>
        <View
          style={{
            flexGrow: !isSmallDevice || !isMobileDevice ? 2 : 1,
            width: !isSmallDevice || !isMobileDevice ? "45%" : "100%",
          }}
        >
          <View
            style={{
              paddingLeft: "0%",
              paddingBottom: "2%",
              paddingTop: "2%",
              paddingRight: "2%",
            }}
          >
            <Card
              testID="barcodeDisplayReport"
              mode="elevated"
              style={[styles.card]}
            >
              <Card.Content
                style={{
                  padding: 0,
                  borderColor: "#eee",
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                {bulkScanInformation.length > 0 ? (
                  <View>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={styles.header}>
                        Scan Results&nbsp;&nbsp;
                        <TouchableOpacity
                          onPress={() => {
                            setBulkScanInformation([]);
                          }}
                        >
                          <FAIcon name={"sync"} size={15} color="#000080" />
                        </TouchableOpacity>
                      </Text>
                    </View>
                    <Divider style={{ marginTop: "1.5%" }} />
                    <View
                      style={{
                        flexDirection:
                          !isSmallDevice || !isMobileDevice ? "row" : "column",
                        flexWrap: "wrap",
                      }}
                    >
                      {bulkScanInformation.map((scanItem) => {
                        return (
                          <ScanCardsList
                            key={`${scanItem.box_id}${scanItem.employee_id}`}
                            scanData={scanItem}
                          />
                        );
                      })}
                    </View>
                  </View>
                ) : (
                  <View
                    style={{
                      marginTop: !isSmallDevice || !isMobileDevice ? "10%" : 0,
                      marginBottom:
                        !isSmallDevice || !isMobileDevice ? "10%" : 0,
                    }}
                  >
                    <NoDataBanner />
                  </View>
                )}
              </Card.Content>
            </Card>
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  errorColor: {
    color: "red",
  },
  successColor: {
    color: "#037d50",
  },
  bgLightSilver: {
    backgroundColor: "#f2f2f2",
  },
  card: {
    marginTop: "1%",
  },
  cardTitle: {
    borderWidth: 1,
    borderColor: "rgba(216, 216, 216, 0.4)",
  },
  cardTitleStyles: {
    color: "black",
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: "-15px",
  },
  header: {
    marginLeft: "1%",
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    width: "100%",
    paddingBottom: "1%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
    margin: 20,
    padding: 0,
  },
});
