export const drawerItemsMain = [
  {
    key: "Production Dashboard",
    title: "Production Dashboard",
    routes: [
      {
        nav: "ScanDashboard",
        routeName: "ScanDashboard",
        title: "Production Dashboard",
      },
    ],
  },
  {
    key: "ScanByManager",
    title: "Scan Results",
    routes: [
      {
        nav: "ScanByManager",
        routeName: "ScanByManager",
        title: "Scan Results",
      },
    ],
  },
];
