import { ProgressBar } from "react-native-paper";
import { Text } from "../../shared/Themed";
import { ReadOnlyProps } from "../../../utils/templates";

interface Props {
  title?: string;
  subTitle?: string | React.ReactChild;
  progressValue: number;
  barColor?: string;
}

export const ProgressChart = ({
  title,
  subTitle,
  progressValue,
  barColor = "rgb(47, 149, 220)",
}: ReadOnlyProps<Props>) => {
  return (
    <>
      <Text>
        {title} {subTitle}
      </Text>
      <ProgressBar
        progress={progressValue}
        color={barColor}
        style={{
          marginTop: "1%",
          height: "10px",
        }}
      />
    </>
  );
};
