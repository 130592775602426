export const headers = {
  ItemCode: {
    name: "Item Code",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ProdCode: {
    name: "Product Code",
    width: 1.2,
    textAlign: "left",
  },
  ItemName: {
    name: "Item Name",
    width: 5.0,
    textAlign: "left",
  },
  FrgnName: {
    name: "Item Description",
    width: 3.5,
    textAlign: "left",
  },
  InStock: {
    name: "Available",
    width: 1,
    textAlign: "left",
  },
  Committed: {
    name: "Committed",
    width: 1,
    textAlign: "left",
  },
  Required: {
    name: "Required",
    width: 1,
    textAlign: "left",
  },
  OnOrder: {
    name: "Ordered",
    width: 1,
    textAlign: "left",
  },
  Planned: {
    name: "Planned",
    width: 1,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const MediumDeviceHeaders = {
  ItemCode: {
    name: "Item Code",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ProdCode: {
    name: "Product Code",
    width: 1.2,
    textAlign: "left",
  },
  ItemName: {
    name: "Item Name",
    width: 5.0,
    textAlign: "left",
  },
  FrgnName: {
    name: "Item Description",
    width: 3.5,
    textAlign: "left",
  },
  InStock: {
    name: "Available",
    width: 1,
    textAlign: "left",
  },
  Committed: {
    name: "Committed",
    width: 1,
    textAlign: "left",
  },
  Required: {
    name: "Required",
    width: 1,
    textAlign: "left",
  },
  OnOrder: {
    name: "Ordered",
    width: 1,
    textAlign: "left",
  },
  Planned: {
    name: "Planned",
    width: 1,
    textAlign: "left",
  },
  "": { name: "", width: 0.25, textAlign: "" },
};

export const SmallDeviceHeaders = {
  ItemCode: {
    name: "Item Code",
    width: 1.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 2.3,
    textAlign: "left",
  },
  InStock: {
    name: "Available",
    width: 1,
    textAlign: "left",
  },
  "": { name: "", width: 0.1, textAlign: "" },
};
