import { ReadOnlyProps } from "../../utils/templates";

interface Props {
  operatingLines: Array<Record<string, string>>;
  scanMonthlylabels: Array<string>;
}

export const scanState: ReadOnlyProps<Props> = {
  operatingLines: [
    { key: "", value: "-- Select --" },
    { key: "02PRD10001", value: "Line 1" },
    { key: "02PRD10002", value: "Line 2" },
    { key: "02PRD10003", value: "Line 3" },
  ],
  scanMonthlylabels: [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
};
