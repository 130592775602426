import { actionTypesContainers as actionTypes } from "../constants/actionTypesContainers";
import { containersState } from "../state/containers";

const containersReducer = (state = containersState, action: any) => {
  switch (action.type) {
    case actionTypes.ListOfContainers:
      return {
        ...state,
        containersList: action.payload,
        isContainersListLoaded: true,
      };
    case actionTypes.IsContainersTableRefreshed:
      return {
        ...state,
        isContainersTableRefreshed: action.payload,
      };
    case actionTypes.SelectedContainer:
      return {
        ...state,
        selectedContainer: action.payload,
      };
    default:
      return state;
  }
};

export default containersReducer;
