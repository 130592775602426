export const headers = {
  item_code: {
    name: "Item Code",
    width: 0.18,
    textAlign: "left",
  },
  bp_name: {
    name: "Customer",
    width: 0.55,
    textAlign: "left",
  },
  container_number: {
    name: "Container #",
    width: 0.25,
    textAlign: "left",
  },
  state: {
    name: "State",
    width: 0.18,
    textAlign: "left",
  },
  description: {
    name: "Description",
    width: 0.7,
    textAlign: "left",
  },
  packing_list_no: {
    name: "Packing #",
    width: 0.18,
    textAlign: "left",
  },
  quantity: {
    name: "Quantity",
    width: 0.18,
    textAlign: "left",
  },
  etd_date: {
    name: "ETD",
    width: 0.25,
    textAlign: "left",
  },
  eta_date: {
    name: "ETA",
    width: 0.25,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  // u_coo: {
  //   name: "U COO#",
  //   width: 0.2,
  //   textAlign: "left",
  // },
  // sales_name: {
  //   name: "Sales",
  //   width: 0.25,
  //   textAlign: "left",
  // },
  ship_status: {
    name: "Status",
    width: 0.2,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdHeaders = {
  item_code: {
    name: "Item Code",
    width: 0.2,
    textAlign: "left",
  },
  bp_name: {
    name: "Customer",
    width: 0.5,
    textAlign: "left",
  },
  container_number: {
    name: "Container #",
    width: 0.3,
    textAlign: "left",
  },
  quantity: {
    name: "Quantity",
    width: 0.15,
    textAlign: "left",
  },
  packing_list_no: {
    name: "Packing #",
    width: 0.15,
    textAlign: "left",
  },
  etd_date: {
    name: "ETD",
    width: 0.25,
    textAlign: "left",
  },
  eta_date: {
    name: "ETA",
    width: 0.25,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ship_status: {
    name: "Status",
    width: 0.25,
    textAlign: "left",
  },
  "": { name: "", width: 0.3, textAlign: "" },
};

export const smHeaders = {
  item_name: {
    name: "Item Name",
    width: 0.26,
    textAlign: "left",
  },
  quantity: {
    name: "Quantity",
    width: 0.25,
    textAlign: "left",
  },
  eta_date: {
    name: "ETA",
    width: 0.35,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ship_status: {
    name: "Status",
    width: 0.28,
    textAlign: "left",
  },
  "": { name: "", width: 0.085, textAlign: "" },
};

/**
 * --------- NESTED HEADERS ---------
 * */

export const nestedHeaders = {
  item_code: {
    name: "Item Code",
    width: 0.18,
    textAlign: "left",
    isSort: false,
    sortDirection: "asc",
  },
  bp_name: {
    name: "Customer",
    width: 0.55,
    textAlign: "left",
  },
  container_number: {
    name: "Container #",
    width: 0.25,
    textAlign: "left",
  },
  state: {
    name: "State",
    width: 0.18,
    textAlign: "left",
  },
  description: {
    name: "Description",
    width: 0.7,
    textAlign: "left",
  },
  packing_list_no: {
    name: "Packing #",
    width: 0.18,
    textAlign: "left",
  },
  quantity: {
    name: "Quantity",
    width: 0.18,
    textAlign: "left",
  },
  // etd_date: {
  //   name: "ETD",
  //   width: 0.25,
  //   textAlign: "left",
  // },
  // eta_date: {
  //   name: "ETA",
  //   width: 0.25,
  //   textAlign: "left",
  // },
  // u_coo: {
  //   name: "U COO#",
  //   width: 0.2,
  //   textAlign: "left",
  // },
  // sales_name: {
  //   name: "Sales",
  //   width: 0.25,
  //   textAlign: "left",
  // },
  ship_status: {
    name: "Status",
    width: 0.2,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdNestedHeaders = {
  item_code: {
    name: "Item Code",
    width: 0.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  bp_name: {
    name: "Customer",
    width: 0.5,
    textAlign: "left",
  },
  container_number: {
    name: "Container #",
    width: 0.3,
    textAlign: "left",
  },
  quantity: {
    name: "Quantity",
    width: 0.15,
    textAlign: "left",
  },
  packing_list_no: {
    name: "Packing #",
    width: 0.15,
    textAlign: "left",
  },
  // etd_date: {
  //   name: "ETD",
  //   width: 0.25,
  //   textAlign: "left",
  // },
  // eta_date: {
  //   name: "ETA",
  //   width: 0.25,
  //   textAlign: "left",
  // },
  ship_status: {
    name: "Status",
    width: 0.25,
    textAlign: "left",
  },
  "": { name: "", width: 0.3, textAlign: "" },
};

export const smNestedHeaders = {
  item_name: {
    name: "Item Name",
    width: 1,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  quantity: {
    name: "Quantity",
    width: 0.5,
    textAlign: "left",
  },
  ship_status: {
    name: "Status",
    width: 0.3,
    textAlign: "right",
  },
  "": { name: "", width: 0.1, textAlign: "" },
};
