import React, { useState, useEffect } from "react";
import { TextInput, TouchableOpacity, StyleSheet } from "react-native";
import roleConfig from "../../../utils/constants/roles";
import { Text, View } from "../../../components/shared/Themed";
import useColorScheme from "../../../hooks/useColorScheme";
import { getRole } from "../../../services/usersService";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

export default function AccountForm() {
  const colorScheme = useColorScheme();
  const companyContext = JSON.parse(
    getItemFromSessionStorage("company-context") as string
  );
  const [companyName, setCompanyName] = useState<String>(
    companyContext ? companyContext.company_name : ""
  );
  const [currentRole, setCurrentRole] = useState<String>("");
  const [language, setLanguage] = useState<String>("English");
  const [timezone, setTimezone] = useState<String>("Australia");
  let disabled = companyContext && companyContext.company_name === "Multisteps";
  if (companyContext) {
    disabled = currentRole === roleConfig.roles.owner ? false : true;
  }

  const submitHandler = () => {
    // Okta.deleteCurrentUser();
  };

  useEffect(() => {
    let isMounted = true;
    if (companyContext && isMounted) {
      setCompanyName(companyContext.company_name);
    }
    getRole(companyContext.company_id).then((mapiData) => {
      setCurrentRole(mapiData?.role);
    });
    return () => {
      isMounted = false;
    };
  }, [companyContext]);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: colorScheme === "light" ? "white" : "white" },
      ]}
    >
      <View style={styles.box}>
        <Text style={styles.label2}>
          {" "}
          The organization details associate to your account{" "}
        </Text>
        <Text style={styles.label}>Company Name</Text>
        <TextInput
          style={styles.input}
          onChangeText={setCompanyName}
          value={companyName}
          disabled={disabled}
        />
        <Text style={styles.label}>Langauge</Text>
        <TextInput
          style={styles.input}
          onChangeText={setLanguage}
          value={language}
          disabled={disabled}
        />
        <Text style={styles.label}>Country/City</Text>
        <TextInput
          style={styles.input}
          onChangeText={setTimezone}
          value={timezone}
          disabled={disabled}
        />
        {!disabled && (
          <TouchableOpacity style={styles.button} onPress={submitHandler}>
            <Text style={styles.buttonText}>Submit</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 40,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#6495ED",
    marginLeft: 14,
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  label: {
    minWidth: "10%",
    maxWidth: 500,
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    // marginDown: 14,
    marginLeft: 14,
  },
  label2: {
    minWidth: "10%",
    maxWidth: 500,
    fontSize: 15,
    textAlign: "left",
    // marginDown: 14,
    marginLeft: 14,
    margin: 50,
  },
  input: {
    minWidth: "10%",
    maxWidth: 500,
    height: 60,
    backgroundColor: "#fff",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: "#ccc",
    borderWidth: 2,
    borderRadius: 15,
    fontSize: 15,
    margin: 20,
  },
});
