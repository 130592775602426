import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useContext, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "../../../components/shared/Themed";
import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import HomeTitleHeader from "../../../components/core/HomeTitleHeader";
import ContainersDrawerNavigator from "./../Containers/ContainersDrawerNavigator";
import ReportsDrawerNavigator from "../Drawer/ReportsDrawerNavigator";
import { HomeTabParamList, RootTabScreenProps } from "../../../types";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import ScansDrawerNavigator from "../OperationScans/ScansDrawerNavigator";
import Spinner from "../../../components/shared/Spinner";
import { useSelector } from "react-redux";

import teamsConfig from "../../../utils/constants/teams";
import rolesConfig from "../../../utils/constants/roles";
import { userClaimInformation } from "../../../models/usersModel";

const Tab = createMaterialTopTabNavigator<HomeTabParamList>();

export default function HomeTabNavigator() {
  const colorScheme = useColorScheme();
  const orientationContext = useContext(DeviceOrientationContext);
  const [userProfile, setUserProfile] = useState<userClaimInformation>();
  const [assignedRoles, setAssignedRoles] = useState<string[]>();

  const { userInfo, userRoles } = useSelector(
    (state: any) => state.configurations
  );

  const { operation } = teamsConfig.teams;
  const { owner } = rolesConfig.roles;

  useEffect(() => {
    setUserProfile(userInfo);
    setAssignedRoles(userRoles);
  }, [userInfo]);

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: colorScheme === "light" ? "#fafafa" : "#fafafa",
      }}
    >
      {!orientationContext.isSmallDevice && <HomeTitleHeader />}
      <Tab.Navigator
        initialRouteName="Reports"
        screenOptions={{
          tabBarActiveTintColor: Colors[colorScheme].tint,
          tabBarStyle: styles._tabBarStyle,
          tabBarLabelStyle: styles._tabBarLabelStyle,
          tabBarItemStyle: styles._tabBarItemStyle,
          swipeEnabled: false,
          lazy: true,
          lazyPlaceholder: () => {
            return (
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  size="small"
                  color="red"
                  styles={{
                    flexDirection: "row",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              </View>
            );
          },
        }}
      >
        <Tab.Screen
          name="Reports"
          component={ReportsDrawerNavigator}
          options={({}: RootTabScreenProps<"Reports">) => ({
            title: "Report",
          })}
        />
        {/* <Tab.Screen
          name="Artworks"
          component={ArtworksDrawerNavigator}
          options={({}: RootTabScreenProps<"Artworks">) => ({
            title: "Artwork",
          })}
        /> */}
        <Tab.Screen
          name="Containers"
          component={ContainersDrawerNavigator}
          options={({}: RootTabScreenProps<"Containers">) => ({
            title: "Container",
          })}
        />
        {userProfile?.team.toUpperCase() === operation &&
          assignedRoles?.includes(owner) && (
            <Tab.Screen
              name="OperationalScans"
              component={ScansDrawerNavigator}
              options={({}: RootTabScreenProps<"OperationalScans">) => ({
                title: "Production",
              })}
            />
          )}
      </Tab.Navigator>
    </View>
  );
}

const styles = StyleSheet.create({
  _tabBarStyle: {
    height: 50,
    width: "98%",
    alignSelf: "center",
    justifySelf: "center",
    borderTopWidth: 0,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fafafa",
  },
  _tabBarLabelStyle: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "none",
  },
  _tabBarItemStyle: {
    width: 100,
  },
});
