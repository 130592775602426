/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import HomeTabNavigator from "./HomeTabNavigator";
import ModalScreen from "../../../screens/ModalScreen";
import UserSettingsTabNavigator from "../../SharedNavigator/TabNavigator/UserSettingsTabNavigator";
import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import { RootTabParamList, RootTabScreenProps } from "../../../types";

const Tab = createMaterialTopTabNavigator<RootTabParamList>();

export default function TopTabNavigator({}) {
  const colorScheme = useColorScheme();

  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={{
        swipeEnabled: false,
        tabBarActiveTintColor: Colors[colorScheme].tint,
        tabBarIndicatorStyle: {
          backgroundColor: null,
        },
        tabBarStyle: {
          height: 60,
          width: "40%",
          alignSelf: "center",
          justifySelf: "center",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        },
        tabBarLabelStyle: {
          fontSize: 16,
          textTransform: "none",
        },
        tabBarItemStyle: {
          width: 80,
        },
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeTabNavigator}
        options={({ navigation }: RootTabScreenProps<"Home">) => ({
          title: "Home",
          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        })}
      />
      <Tab.Screen
        name="Help"
        component={ModalScreen}
        options={{
          title: "Help",
          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
        listeners={{
          tabPress: (e) => {
            e.preventDefault();
          },
        }}
      />
      <Tab.Screen
        name="UserSettings"
        component={UserSettingsTabNavigator}
        options={({ navigation }: RootTabScreenProps<"UserSettings">) => ({
          title: "Accounts",
          // tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        })}
        listeners={{
          tabPress: (e) => {
            // Prevent default action
          },
        }}
      />
    </Tab.Navigator>
  );
}
