import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import oktaConfig from "../configs/okta";
import mapiConfig from "../configs/mapi";
import { inviteModel } from "../models/inviteModel";
import * as Services from "../utils/common";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = mapiConfig.api.domain;
const Controller = "/invites";

export const getInviteByInviteId = async (inviteId: string) => {
  const methodPath = `${Controller}/search/${inviteId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        invite: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const updateInvite = async (httpBody: inviteModel, inviteId: string) => {
  const methodPath = `${Controller}/update/${inviteId}`;
  const _httpBody = Object.keys(httpBody).length ? httpBody : {};

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "PUT",
    path: methodPath,
    body: _httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const addInvite = async (
  companyId: string,
  team: string,
  role: string,
  email: string
) => {
  const methodPath = `${Controller}/add`;
  const httpBody: inviteModel = {
    role: role,
    team: team,
    status: "pending",
    invite_id: Services.makeid(8),
    company_id: companyId,
    email: email,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        inviteId: httpBody.invite_id,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        status: "ERROR",
        isLoading: false,
      };
    });
};

export const sendInvite = async (
  companyName: string,
  inviteId: string,
  email: string
) => {
  const methodPath = "/smtp/sendInvite";

  var httpBody = {
    inviteLink: `${oktaConfig.api.redirectUri}/invites/${inviteId}`,
    company_name: companyName,
    email: email,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        status: "ERROR",
        isLoading: false,
      };
    });
};
