import { formatDateToMongoISOFormat } from "../../../../utils/dateUtility";

export const getStartDateForScanStats = (currentDate: Date) => {
  const firstDayOfLastMonth = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getMonth() - 1,
    1
  );

  return formatDateToMongoISOFormat(firstDayOfLastMonth);
};
