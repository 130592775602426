export const drawerItemsMain = [
  {
    key: "Containers Management",
    title: "Containers Status",
    routes: [
      {
        nav: "ContainersManagement",
        routeName: "ContainersManagement",
        title: "Containers Status",
      },
    ],
  }
];
