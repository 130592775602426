import { Button } from "react-native-paper";
import { View, Text } from "../../components/shared/Themed";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextInputControl } from "../../components/custom/TextInputControl";
import { CustomHorizontalLine } from "../../components/custom/CustomHorizontalLine";
import * as Okta from "../../services/oktaAuthService";
import { ReadOnlyProps } from "../../utils/templates";
import appColors from "../../utils/constants/colors";

interface Props {
  setDisplayForgetPasswordScreen: (e: boolean) => void;
  setDisplayLoginWithTendaScreen: (e: boolean) => void;
}

export const LoginScreen = ({
  setDisplayForgetPasswordScreen,
  setDisplayLoginWithTendaScreen,
}: ReadOnlyProps<Props>) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  const loginDefaultValues = {
    userEmail: "",
    userPassword: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: loginDefaultValues,
  });

  const onSubmit = (formData: any) => {
    setLoading(true);
    Okta.getAuth(formData.userEmail.trim(), formData.userPassword.trim()).then(
      (data: any) => {
        setStatus(data.status);
        setLoading(false);

        //Reset the form
        if (data.status !== "FAILED") {
          reset(loginDefaultValues);
        }
      }
    );
  };

  return (
    <>
      <View style={[styles.contentLabel]}>
        <Text style={[styles.boldFont, { fontSize: 20 }]}>Login</Text>
      </View>
      <View style={[styles.px_5]}>
        <Controller
          control={control}
          rules={{
            validate: {
              required: async (value) => {
                if (!value) return "* This is required.";
              },
            },
          }}
          name="userEmail"
          render={({ field: { onChange, value } }) => (
            <TextInputControl
              title="Email"
              required={true}
              width="100%"
              placeholder="Enter UserName/Email"
              value={value}
              setChangeText={onChange}
              blurOnSubmit={false}
              handleOnSubmitEditing={handleSubmit(onSubmit)}
            />
          )}
        />
        {errors.userEmail && (
          <Text style={styles.errorColor}>* This is required.</Text>
        )}
        <Controller
          control={control}
          rules={{
            validate: {
              required: async (value) => {
                if (!value) return "* This is required.";
              },
            },
          }}
          name="userPassword"
          render={({ field: { onChange, value } }) => (
            <TextInputControl
              title="Password"
              required={true}
              width="100%"
              placeholder="Enter Password"
              value={value}
              setChangeText={onChange}
              isTextEntrySecure={true}
              blurOnSubmit={false}
              handleOnSubmitEditing={handleSubmit(onSubmit)}
            />
          )}
        />
        {errors.userPassword && (
          <Text style={styles.errorColor}>* This is required.</Text>
        )}
        {status === "FAILED" && (
          <Text style={[styles.errorColor, { marginTop: "2%" }]}>
            * Invalid username or password.
          </Text>
        )}
      </View>
      <View style={[styles.px_5]}>
        <TouchableOpacity onPress={() => setDisplayForgetPasswordScreen(true)}>
          <Text style={[styles.forgetPasswordLink]}>Forget Password?</Text>
        </TouchableOpacity>
        <Button
          mode="contained"
          onPress={handleSubmit(onSubmit)}
          loading={isLoading}
          color={appColors.multiSteps.background}
          style={{ marginTop: 15 }}
          uppercase={false}
        >
          Login
        </Button>
        <CustomHorizontalLine />
        <Button
          mode="contained"
          onPress={() => setDisplayLoginWithTendaScreen(true)}
          loading={false}
          color={appColors.multiSteps.background}
          style={{ marginBottom: 15 }}
          uppercase={false}
        >
          Login with TANDA
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  contentLabel: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  errorColor: {
    color: "red",
  },
  px_5: { paddingLeft: "5%", paddingRight: "5%" },
  forgetPasswordLink: {
    marginTop: "2%",
    fontStyle: "italic",
    color: "#adacac",
    textDecorationLine: "underline",
  },
  boldFont: {
    fontWeight: "bold",
  },
});
