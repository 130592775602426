import React, { useState, useEffect, useContext } from "react";
import {
  Platform,
  TouchableOpacity,
  StyleSheet,
  ImageBackground,
  ScrollView,
} from "react-native";
import { Snackbar, Dialog, Button } from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import CustomTextInput from "../../components/custom/CustomTextInput";
import CustomArtworks from "../../components/custom/CustomArtworks";
import CompanyLogo from "../../components/core/CompanyLogo";
import ContentTitleHeader from "../../components/core/ContentTitleHeader";
import { default as Modal } from "../../components/custom/Modal";
import TabBarIcon from "../../components/core/TabBarIcon";
import { View } from "../../components/shared/Themed";
import useColorScheme from "../../hooks/useColorScheme";
import * as Services from "../../utils/common";
import {
  deleteArtwork,
  getAllOrByOneArtwork,
  updateArtwork,
} from "../../services/artworkService";
import {
  headers,
  SmallDeviceHeaders,
  MediumDeviceHeaders,
} from "./utils/artworkHeaders";
import { artworkModel, pendingArtWork } from "../../models/artworkModel";
import { getS3SignUrl, pushPhotoToS3 } from "../../services/s3BucketService";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";

export default function UpdateFormScreen({ route, navigation }: any) {
  const now = new Date();
  const colorScheme = useColorScheme();
  const [uniqueId, setUniqueId] = useState<string>(Services.makeid(10));
  const [labelSize, setlabelSize] = useState<string>("");
  const [barcode, setBarcode] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [artworkId, setArtworkId] = useState<string>("");
  const [artworkName, setArtworkName] = useState<string>("");
  const [createdDate, setCreatedDate] = useState<Date>(now);
  const [imageBase64, setImageBase64] = useState<string>("");
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [snackBoxVisble, setSnackBoxVisble] = useState<boolean>(false);
  const [snackBoxMessage, setSnackBoxMessage] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("New");
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [artworkVersion, setArtworkVersion] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [approvedArtworkNumber, setApprovedArtworkNumber] =
    useState<string>("");
  const [pendingArtworkNumbers, setPendingArtworkNumbers] = useState<Object[]>([
    { id: Services.makeid(3), pendingNumber: "" },
  ]);
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );

  const { isSmallDevice, isLargeDevice, isMediumDevice } = useContext(
    DeviceOrientationContext
  );

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.3,
      base64: true,
      exif: false,
    });

    if (!result.cancelled) {
      setImageBase64(result.base64 as string);
      setImage(result.uri);
    }
  };

  const uploadImage = async () => {
    getS3SignUrl(
      "multisteps-artworks",
      `${uniqueId}.png`,
      "putObject",
      "image/*"
    ).then((data) => {
      pushPhotoToS3(data?.signedUrl, "PUT", imageBase64, "image/*").then(
        (data) => {}
      );
    });
  };

  const onDismissSnackBar = () => {
    setSnackBoxVisble(false);
    if (isSubmitted) {
      setUniqueId("");
      setlabelSize("");
      setBarcode("");
      setArtworkId("");
      setArtworkName("");
      setArtworkVersion("");
      setPendingArtworkNumbers([]);
      setApprovedArtworkNumber("");
      setImage("");
      setImageBase64("");
      setCreatedDate(now);
      setSubmitted(false);
      navigation.navigate("Root", { screen: "ArtworkManagement" });
    }
  };

  const addPendingArtworkNumber = () => {
    var newPendingNumber = [
      {
        id: Services.makeid(3),
        pendingNumber: "",
      },
    ];
    setPendingArtworkNumbers(pendingArtworkNumbers.concat(newPendingNumber));
  };

  const removePendingArtworkNumber = (index: number) => {
    pendingArtworkNumbers.splice(index, 1);
    setPendingArtworkNumbers(pendingArtworkNumbers);
    setRefresh(!refresh);
  };

  const onPendingArtworkNumberChangeText = (text: string, index: number) => {
    var newPendingArtworkNumbers = pendingArtworkNumbers;
    newPendingArtworkNumbers[index].pendingNumber = text;
    setPendingArtworkNumbers(newPendingArtworkNumbers);
  };

  const handleCreateArtworkSubmit = async () => {
    setLoading(true);
    const body: artworkModel = {
      unique_id: uniqueId as string,
      artwork_id: artworkId as string,
      artwork_name: artworkName as string,
      version_number: artworkVersion as string,
      pending_artwork_numbers: pendingArtworkNumbers as Array<pendingArtWork>,
      approved_artwork_number: approvedArtworkNumber as string,
      label_size: labelSize as string,
      date: createdDate,
      barcode: barcode as string,
    };

    if (artworkId === "") {
      setSnackBoxMessage("Artwork ID field is required");
      setLoading(false);
      setSnackBoxVisble(true);
    } else if (artworkName === "") {
      setSnackBoxMessage("Artwork Name field is required");
      setLoading(false);
      setSnackBoxVisble(true);
    } else if (artworkVersion === "") {
      setSnackBoxMessage("Artwork Version field is required");
      setLoading(false);
      setSnackBoxVisble(true);
    } else if (image === "") {
      setSnackBoxMessage("Artwork Image is required");
      setLoading(false);
      setSnackBoxVisble(true);
    } else {
      updateArtwork(body).then(() => {
        imageBase64 && uploadImage();
        setSubmitted(true);
        setLoading(false);
        setSnackBoxMessage(`${artworkId} has been updated`);
        setSnackBoxVisble(true);
      });
    }
  };

  const handleDelete = () => {
    deleteArtwork(uniqueId as string);
    navigation.navigate("Root", { screen: "ArtworkManagement" });
  };

  const CustomTextInputComponent = (props: Object) => {
    return (
      <CustomTextInput
        title=""
        width={false ? "100%" : "100%"}
        borderColor="#ccc"
        marginLeft={0}
        placeHolder="Pending Artwork number"
        value={props.value}
        disabled={true}
        setChangeText={(text) => props.onChangeText(text, props.index)}
      />
    );
  };

  const CustomEnlargeImageComponent = () => {
    return (
      <View style={{ width: "100%", height: "95%" }}>
        <ImageBackground
          source={{ uri: image }}
          style={{ width: "100%", height: "100%" }}
          resizeMode="cover"
        />
      </View>
    );
  };

  const ConfirmDeleteForm = () => {
    return (
      <Dialog
        style={{
          width: 300,
          height: 150,
          justifySelf: "center",
          alignSelf: "center",
        }}
        visible={confirmVisible}
        onDismiss={setConfirmVisible}
      >
        <Dialog.Title>Alert</Dialog.Title>
        <Dialog.Content>Confirm to delete this artwork</Dialog.Content>
        <Dialog.Actions>
          <Button onPress={() => setConfirmVisible(false)}>No</Button>
          <Button onPress={() => handleDelete()}>Yes</Button>
        </Dialog.Actions>
      </Dialog>
    );
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert(
            "Sorry, Camera roll permissions are required to make this work!"
          );
        }
      }

      setTableHeaders(
        isLargeDevice
          ? headers
          : isMediumDevice
          ? MediumDeviceHeaders
          : SmallDeviceHeaders
      );

      if (route.params.id !== "") {
        getAllOrByOneArtwork(route.params.id).then((data) => {
          if (isMounted) {
            setImageLoading(true);
            setUniqueId(data.artworks[0].unique_id);
            setArtworkId(data.artworks[0].artwork_id);
            setArtworkName(data.artworks[0].artwork_name);
            setBarcode(data.artworks[0].barcode);
            setlabelSize(data.artworks[0].label_size);
            setArtworkVersion(data.artworks[0].version_number);
            setApprovedArtworkNumber(data.artworks[0].approved_artwork_number);
            setPendingArtworkNumbers(data.artworks[0].pending_artwork_numbers);
            setCreatedDate(new Date(data.artworks[0].date));
            getS3SignUrl(
              "multisteps-artworks",
              `${data.artworks[0].image_id}.png`,
              "getObject",
              "image/*"
            ).then((data) => {
              if (data?.signedUrl) {
                setImage(data.signedUrl);
                setTimeout(setImageLoading, 2000, false);
              }
            });
          }
        });
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <View style={{ height: "100%" }}>
      {!isSmallDevice && (
        <View style={{ flexDirection: "row", width: "100%", height: 60 }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              height: 60,
            }}
          >
            <CompanyLogo />
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "60%",
              height: 60,
            }}
          >
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <TabBarIcon name="home" color={"black"} />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {!isSmallDevice && (
        <ContentTitleHeader name="Update Artwork" navigation={navigation} />
      )}
      <ScrollView>
        <View
          style={[
            styles.container,
            { backgroundColor: colorScheme === "light" ? "white" : "white" },
          ]}
        >
          <CustomArtworks
            uniqueId={uniqueId}
            isImageLoading={isImageLoading}
            setUniqueId={setUniqueId}
            labelSize={labelSize}
            setlabelSize={setlabelSize}
            barcode={barcode}
            setBarcode={setBarcode}
            artworkId={artworkId}
            setArtworkId={setArtworkId}
            artworkName={artworkName}
            setArtworkName={setArtworkName}
            approvedArtworkNumber={approvedArtworkNumber}
            setApprovedArtworkNumber={setApprovedArtworkNumber}
            pendingArtworkNumbers={pendingArtworkNumbers}
            setPendingArtworkNumbers={setPendingArtworkNumbers}
            artworkVersion={artworkVersion}
            setArtworkVersion={setArtworkVersion}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            confirmVisible={confirmVisible}
            setConfirmVisible={setConfirmVisible}
            image={image}
            setImage={setImage}
            isLoading={isLoading}
            setLoading={setLoading}
            createdDate={createdDate}
            setCreatedDate={setCreatedDate}
            pickImage={pickImage}
            handleSubmit={handleCreateArtworkSubmit}
            addPendingArtworkNumber={addPendingArtworkNumber}
            removePendingArtworkNumber={removePendingArtworkNumber}
            buttonText={"Update"}
            isSmallDevice={false}
            childernComponent={CustomTextInputComponent}
          />
          <Snackbar
            visible={snackBoxVisble && !isLoading ? true : false}
            onDismiss={onDismissSnackBar}
            style={{ backgroundColor: isSubmitted ? "#6495ED" : "#b22442" }}
            theme={{ colors: { accent: "white" } }}
            action={{
              label: isSubmitted ? "Done" : "Close",
              onPress: () => {
                onDismissSnackBar;
              },
            }}
          >
            {snackBoxMessage}
          </Snackbar>
        </View>
        <Modal.ModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          childern={CustomEnlargeImageComponent}
        />
        <ConfirmDeleteForm />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
});
