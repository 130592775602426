export const headersPMXOverview = {
  ItemCode: {
    name: "Item Code",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 5.0,
    textAlign: "left",
  },
  // Warehouse: {
  //   name: "Warehouse",
  //   width: 1,
  //   textAlign: 'left'
  // },
  Location: {
    name: "Location",
    width: 1.3,
    textAlign: "left",
  },
  QuantityUom: {
    name: "Uom1",
    width: 0.5,
    textAlign: "center",
  },
  QuantityUom2: {
    name: "Uom2",
    width: 0.5,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdHeadersPMXOverview = {
  ItemCode: {
    name: "Item Code",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 5.0,
    textAlign: "left",
  },
  // Warehouse: {
  //   name: "Warehouse",
  //   width: 1,
  //   textAlign: 'left'
  // },
  Location: {
    name: "Location",
    width: 1.3,
    textAlign: "left",
  },
  QuantityUom: {
    name: "Uom1",
    width: 0.5,
    textAlign: "center",
  },
  QuantityUom2: {
    name: "Uom2",
    width: 0.5,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smHeadersPMXOverview = {
  ItemName: {
    name: "Item Name",
    width: 3.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  // Warehouse: {
  //   name: "Warehouse",
  //   width: 1,
  //   textAlign: 'left'
  // },
  Location: {
    name: "Location",
    width: 1.3,
    textAlign: "left",
  },
  QuantityUom2: {
    name: "Uom2",
    width: 1,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const headersPmxReport = {
  ItemCode: {
    name: "Item Code",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 3.5,
    textAlign: "left",
  },
  BatchNumber1: {
    name: "Batch number",
    width: 1.5,
    textAlign: "left",
  },
  SsccNumber: {
    name: "SSCC number",
    width: 1.5,
    textAlign: "left",
  },
  QuantityStatus: {
    name: "Status",
    width: 1,
    textAlign: "left",
  },
  StorLocName: {
    name: "Aisle",
    width: 1.3,
    textAlign: "left",
  },
  QuantityUom: {
    name: "Uom1",
    width: 1,
    textAlign: "center",
  },
  QuantityUom2: {
    name: "Uom2",
    width: 0.7,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdHeadersPmxReport = {
  ItemCode: {
    name: "Item Code",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  ItemName: {
    name: "Item Name",
    width: 3.5,
    textAlign: "left",
  },
  BatchNumber1: {
    name: "Batch number",
    width: 1.5,
    textAlign: "left",
  },
  SsccNumber: {
    name: "SSCC number",
    width: 1.5,
    textAlign: "left",
  },
  QuantityStatus: {
    name: "Status",
    width: 1,
    textAlign: "left",
  },
  StorLocName: {
    name: "Aisle",
    width: 1.3,
    textAlign: "left",
  },
  QuantityUom: {
    name: "Uom1",
    width: 1,
    textAlign: "center",
  },
  QuantityUom2: {
    name: "Uom2",
    width: 0.7,
    textAlign: "center",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smHeadersPmxReport = {
  ItemName: {
    name: "Item Name",
    width: 2.9,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  BatchNumber1: {
    name: "Batch number",
    width: 2,
    textAlign: "left",
  },
  StorLocName: {
    name: "Aisle",
    width: 1.6,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};
