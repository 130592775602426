import { companyModel } from "../../models/companyModel";
import { userClaimInformation } from "../../models/usersModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  isUserAuthorized: boolean;
  companies: Array<companyModel>;
  currentCompany: companyModel;
  navigation: any;
  logo: string;
  currentRole: string;
  initial: string;
  userInfo: userClaimInformation;
  userRoles: Array<string>;
  hasAvatar: boolean;
  hasAvatarMenu: boolean;
  isDrawer: boolean;
  profilePhoto: string;
}

export const configurationsState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  isUserAuthorized: false,
  companies: [],
  currentCompany: {
    owner_ids: [],
    admin_ids: [],
    member_ids: [],
    _id: "",
    company_id: "",
    company_name: "",
  },
  navigation: null,
  logo: "",
  currentRole: "",
  initial: "",
  userInfo: {
    login: undefined,
    auto_sync: false,
    team: undefined,
    user_id: "",
  },
  userRoles: [],
  hasAvatar: false,
  hasAvatarMenu: false,
  isDrawer: false,
  profilePhoto: "",
};
