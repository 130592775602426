import { useContext, useEffect, useRef, useState } from "react";
import { Text, View } from "react-native";
import { Divider } from "react-native-paper";

import { scanPerLinesModal } from "../../../models/barcodeModel";
import { ReadOnlyProps } from "../../../utils/templates";
import { lineLabels } from "./scanCalculations";
import _ from "lodash";
import { NoDataBanner } from "../../../components/shared/NoDataBanner";
import {
  formatDate,
  formatDateToMongoISOFormat,
  getYesterdayFromDate,
  isToday,
} from "../../../utils/dateUtility";
import { ProgressChart } from "../../../components/custom/Charts/ProgressChart";
import { chartColors } from "./constants/ChartColors";
import { BarChartControl } from "../../../components/custom/Charts/BarChartControl";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { Bar } from "react-chartjs-2";
import { getScanPerLines } from "../../../services/barcodeService";
import Spinner from "../../../components/shared/Spinner";

interface Props {
  selectedScanDate: Date;
  startDate: string;
  endDate: string;
  operatorName: string;
  isRefresh: boolean;
}

export const ScanByLinesContent = ({
  selectedScanDate,
  startDate,
  endDate,
  operatorName,
  isRefresh,
}: ReadOnlyProps<Props>) => {
  const chartRef = useRef<Bar>(null);
  const isMountedRef = useRef<boolean>();
  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );

  const [processedScanStats, setProcessedScanStats] =
    useState<scanPerLinesModal>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isSectionLoadedBefore, setIsSectionLoadedBefore] =
    useState<boolean>(false);

  const data = {
    labels: processedScanStats?.labels,
    datasets: [
      {
        label: isToday(selectedScanDate)
          ? `Today`
          : formatDate(selectedScanDate, "short"),
        fill: false,
        data: processedScanStats?.todayScans,
        borderColor: `rgb(${(chartColors as unknown as string[])[0]})`,
        backgroundColor: `rgba(${
          (chartColors as unknown as string[])[0]
        }, 0.5)`,
      },
      {
        label: isToday(selectedScanDate)
          ? `Yesterday`
          : formatDate(getYesterdayFromDate(selectedScanDate), "short"),
        fill: false,
        data: processedScanStats?.yesterdayScans,
        borderColor: `rgb(${(chartColors as unknown as string[])[1]})`,
        backgroundColor: `rgba(${
          (chartColors as unknown as string[])[1]
        }, 0.5)`,
      },
    ],
  };

  const ProgressSubTitle = ({ value }: { value: number }) => {
    return (
      <Text>
        (
        <Text style={{ fontStyle: "italic", fontWeight: "bold" }}>{value}</Text>{" "}
        Boxes)
      </Text>
    );
  };

  const fetchScansPerLinesStats = async (): Promise<void> => {
    try {
      const scanResults = await getScanPerLines(
        startDate,
        endDate,
        formatDateToMongoISOFormat(selectedScanDate),
        operatorName
      );

      setProcessedScanStats(scanResults.data);
      setIsDataLoading(scanResults.isLoading);
    } catch (error) {
      setProcessedScanStats({} as unknown as scanPerLinesModal);
      setIsDataLoading(false);

      console.log(
        "onDateChange in TotalScanStats section the stats loading method failed of the following reason: ",
        error
      );
    }
  };

  const isTodayScansEmpty = () => {
    if (processedScanStats) {
      const todayScans = Object.keys(_.groupBy(processedScanStats?.todayScans));
      return todayScans.length === 1 && todayScans[0] === "0" ? true : false;
    }

    return false;
  };

  const isYesterdaysScansEmpty = () => {
    if (processedScanStats) {
      const yesterdayScans = Object.keys(
        _.groupBy(processedScanStats?.yesterdayScans)
      );
      return yesterdayScans.length === 1 && yesterdayScans[0] === "0"
        ? true
        : false;
    }

    return false;
  };

  useEffect(() => {
    if (isSectionLoadedBefore) {
      //Destory the chart
      if (chartRef.current && chartRef.current !== null) {
        chartRef.current.chartInstance.clear();
      }

      setIsDataLoading(true);
      fetchScansPerLinesStats();
    }
  }, [selectedScanDate, operatorName, isRefresh]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchScansPerLinesStats();
    setIsSectionLoadedBefore(true);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <View style={{ margin: "3%" }}>
      {!isDataLoading ? (
        <>
          <View>
            {processedScanStats &&
            processedScanStats?.groupScanPerLineByTodayCount?.length > 0 ? (
              processedScanStats?.groupScanPerLineByTodayCount?.map(
                (value, index) => {
                  return (
                    <View
                      key={index}
                      style={{ marginTop: index !== 0 ? "2%" : "0%" }}
                    >
                      <ProgressChart
                        title={lineLabels(value.line)}
                        subTitle={
                          <ProgressSubTitle value={value.totalScannedBoxes} />
                        }
                        progressValue={value.progress}
                      />
                    </View>
                  );
                }
              )
            ) : (
              <NoDataBanner />
            )}
          </View>
          <Divider style={{ marginTop: "3%" }} />
          <View style={{ marginTop: "3%" }}>
            {!isTodayScansEmpty() || !isYesterdaysScansEmpty() ? (
              <BarChartControl
                chartId="scanByLinesChart"
                chartHeight={195}
                xAxisLabel="Per line"
                hasXAxisGridlines={false}
                yAxisLabel="Total Scans"
                yAxisStepSize={50}
                dataRecords={data}
                chartReference={chartRef}
                isAnimate={isMobileDevice || isSmallDevice ? false : true}
              />
            ) : (
              <NoDataBanner />
            )}
          </View>
        </>
      ) : (
        <Spinner
          size="small"
          color={"#00ff00"}
          styles={{ flexDirection: "row", margin: "20%" }}
        />
      )}
    </View>
  );
};
