import mapiConfig from "../configs/mapi";
import { artworkModel } from "../models/artworkModel";
import { actionTypesArtwork } from "../redux/constants/actionTypesArtwork";
import { ArtWorkDispatcher } from "../redux/dispatcher/artworkDispatcher";
import { convertObjectToReadableForm_Artwork } from "../screens/Artworks/utils/utility";
import { getUserInfo } from "./usersService";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = mapiConfig.api.domain;
const Controller = "/artworks";

export const getAllOrByOneArtwork = async (
  searchStr: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  const methodPath = `${Controller}/search/${searchStr}`;
  let artworkResult: Array<artworkModel> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      artworkResult = sortCollection(
        tableHeaders,
        "sortDirection",
        convertObjectToReadableForm_Artwork(result.data)
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ArtWorkDispatcher(actionTypesArtwork.ListOfArtwork, artworkResult);
};

export const addArtwork = async (data: artworkModel) => {
  const methodPath = `${Controller}/add`;
  const userInfo = await getUserInfo();

  var httpBody = {
    created_by: `${userInfo.given_name} ${userInfo.family_name}`,
    ...data,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        status: "ERROR",
        isLoading: false,
      };
    });
};

export const deleteArtwork = async (uniqueId: string) => {
  const methodPath = `${Controller}/delete/${uniqueId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "DELETE",
    path: methodPath,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        status: "ERROR",
        isLoading: false,
      };
    });
};

export const updateArtwork = async (data: artworkModel) => {
  const methodPath = `${Controller}/update/${data.unique_id}`;
  const userInfo = await getUserInfo();

  var httpBody = {
    created_by: `${userInfo.given_name} ${userInfo.family_name}`,
    ...data,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "PUT",
    path: methodPath,
    body: httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        status: "ERROR",
        isLoading: false,
      };
    });
};
