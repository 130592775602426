export enum actionTypesConfigurations {
  IsUserAuthorized = "isUserAuthorized",
  Companies = "companies",
  CurrentCompany = "currentCompany",
  Navigation = "navigation",
  Logo = "logo",
  CurrentRole = "currentRole",
  Initial = "initial",
  UserInfo = "userInfo",
  HasAvatar = "hasAvatar",
  HasAvatarMenu = "hasAvatarMenu",
  IsDrawer = "isDrawer",
  ProfilePhoto = "profilePhoto",
  UserRoles = "userRoles",
}
