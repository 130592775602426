import React, { useContext } from "react";
import { StyleSheet, Image } from "react-native";
import { View } from "../shared/Themed";
import DeviceOrientationContext from "../context/DeviceOrientationContext";

export default function CompanyLogo() {
  const { isSmallDevice } = useContext(DeviceOrientationContext);

  return (
    <View style={[styles.box, { marginLeft: isSmallDevice ? "5%" : "23%" }]}>
      <Image
        style={styles.tinyLogo}
        resizeMode="contain"
        source={require("../../assets/logo2.png")}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  box: {
    width: "10%",
    height: 45,
    marginTop: 15,
    marginLeft: "25%",
    position: "absolute",
  },
  tinyLogo: {
    width: 50,
    height: 30,
  },
});
