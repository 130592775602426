import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity, TextInput } from "react-native";
import { Button } from "react-native-paper";
import { Text, View } from "../../shared/Themed";
import Icon from "react-native-vector-icons/FontAwesome5";
import { ReadOnlyProps } from "../../../utils/templates";
import DeviceOrientationContext from "../../context/DeviceOrientationContext";
import { exportDataToExcel } from "../../../utils/exportDataToExcel";

interface Props {
  childern?: React.ElementType;
  titleName: string;
  isSmallDevice?: boolean;
  isMobileDevice?: boolean;
  isEditable?: boolean;
  ref?: any;
  isResetFields?: boolean;
  handleSearch: (searchText: string) => void;
  setRefresh: (event: any) => void;
  setEditable?: (event: any) => void;

  //for excel download
  dataToExport?: Array<unknown>;
  fileName?: string;
  sheetName?: string;
}

export const LabelBannerWithSearchBar = (props: ReadOnlyProps<Props>) => {
  const Children = (props.childern || {}) as React.ElementType;
  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );

  const [_searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    // if search text is empty, then return all data
    if (_searchText.trim().length === 0) {
      props.handleSearch("");
      return;
    }

    props.handleSearch(_searchText.trim());
  }, [_searchText]);

  useEffect(() => {
    if (props.isResetFields) {
      setSearchText("");
    }
  }, [props.isResetFields]);

  const submitSearch = () => {
    props.handleSearch(_searchText.trim());
  };

  const isExportAllowed = () => {
    return (
      !isSmallDevice &&
      !isMobileDevice &&
      props.dataToExport &&
      props.dataToExport.length > 0
    );
  };

  return (
    <View>
      <View style={[styles.container]}>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: 50,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: props.isEditable ? "#fece85" : "white",
            borderBottomWidth: 1,
            borderColor: "darkgrey",
          }}
        >
          {!isSmallDevice && !isMobileDevice && (
            <Text
              style={[styles.title, { width: "80%" }]}
              // TODO: Enable only when auto-theming is applied
              // lightColor="rgba(0,0,0,0.8)"
              // darkColor="rgba(255,255,255,0.8)"
            >
              {props.titleName}
            </Text>
          )}
          {props.isEditable && (
            <Button
              mode="contained"
              uppercase={false}
              onPress={() => props.setEditable?.(!props.isEditable)}
              color="grey"
              style={{ marginRight: "1%" }}
            >
              Cancel
            </Button>
          )}
          <TouchableOpacity
            onPress={() => {
              setSearchText("");
              props.setRefresh(true);
            }}
          >
            <Icon name={"sync"} size={15} color="#000080" />
          </TouchableOpacity>
          <TextInput
            ref={props.ref}
            style={[styles.input, { width: !props.isMobileDevice ? 250 : 180 }]}
            onChangeText={(value) => {
              setSearchText(value);
            }}
            value={_searchText || ""}
            placeholder="Type your search"
            onSubmitEditing={() =>
              _searchText.trim().length < 3 ? {} : submitSearch()
            }
          />
          <View style={{ flexDirection: "row" }}>
            <Button
              icon="magnify"
              disabled={_searchText.trim().length < 3}
              mode="contained"
              uppercase={false}
              onPress={() => submitSearch()}
              color={props.isEditable ? "#7F00FF" : "#b22442"}
              style={isExportAllowed() ? styles.searchBtn : styles.margin_10PX}
              contentStyle={{
                paddingTop: "10%",
                paddingBottom: "10%",
                paddingLeft: "25%",
              }}
            >
              {" "}
            </Button>
            {isExportAllowed() && (
              <Button
                icon="file-excel"
                mode="contained"
                uppercase={false}
                onPress={() =>
                  exportDataToExcel(
                    props.dataToExport || [],
                    props.fileName,
                    props.sheetName
                  )
                }
                color="rgb(47, 149, 220)"
                style={styles.excelBtn}
                contentStyle={{
                  paddingTop: "10%",
                  paddingBottom: "10%",
                  paddingLeft: "25%",
                }}
              >
                {" "}
              </Button>
            )}
          </View>
        </View>
        {props.childern && <Children {...props} />}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    borderWidth: 0.1,
    width: "100%",
    borderRadius: 2,
    borderColor: "#F5F5F5",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginLeft: "2%",
  },
  input: {
    height: 35,
    borderColor: "#F5F5F5",
    margin: 12,
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  padding_1_Percent: {
    padding: "1%",
  },
  margin_10PX: { marginRight: "10px" },
  searchBtn: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: "1%",
  },
  excelBtn: {
    marginRight: "10px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
