import sapiConfig from "../configs/sapi";
import { customerStatusModel } from "../models/reportsModel";
import { actionTypesReports } from "../redux/constants/actionTypesReports";
import { ReportsDispatcher } from "../redux/dispatcher/reportsDispatcher";
import { sortDirection } from "../utils/constants/genericTypes";
import { getItemsHavingSortDirection } from "../utils/searchInArrays";
import { sortByDirection } from "../utils/sortDataTable";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

//BASE_URL
const BASE_URL = sapiConfig.api.domain;

export const getCustomerStatus = async (
  searchText: string,
  team: string,
  name: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  let csResult: Array<customerStatusModel> = [];
  const methodPath = team.toLowerCase() === "sales" ? 
    `/report/customer_status_report?${name}&${searchText}`:
    `/report/customer_status_report?null&${searchText}` ;
   

  const { sortDirections, columns } = getItemsHavingSortDirection(
    tableHeaders,
    "sortDirection"
  );
  

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      const sortedCustomerStatusList: Array<customerStatusModel> = sortByDirection(
        sortDirections as Array<sortDirection>,
        columns as Array<string>,
        result.data.recordset
      );

      csResult = sortedCustomerStatusList;

    })
    .catch((error) => {
      logError(error);
    });

  ReportsDispatcher(actionTypesReports.ListOfCustomerStatus, csResult);
};