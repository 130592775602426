export const headersProduct = {
  ItemCode: {
    name: "Item Code",
    width: 1.5,
    textAlign: "left",
  },
  ItemName: {
    name: "Item Name",
    width: 4.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  FrgnName: {
    name: "Item Description",
    width: 3.5,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 2.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  PerCtns: {
    name: "Pcs (Carton)",
    width: 1.3,
    textAlign: "left",
  },
  PerPallet: {
    name: "Ctns (Pallet)",
    width: 1.3,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mediumDeviceHeadersProduct = {
  ItemName: {
    name: "Item Name",
    width: 4.0,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 4,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  PerCtns: {
    name: "Pcs (Carton)",
    width: 1.2,
    textAlign: "left",
  },
  PerPallet: {
    name: "Ctns (Pallet)",
    width: 1.2,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smallDeviceHeadersProduct = {
  ItemName: {
    name: "Item Name",
    width: 2.3,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  PerCtns: {
    name: "Pcs (Carton)",
    width: 1.2,
    textAlign: "left",
  },
  PerPallet: {
    name: "Ctns (Pallet)",
    width: 1.2,
    textAlign: "left",
  },
  "": { name: "", width: 0.1, textAlign: "" },
};
