import * as React from "react";
import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { drawerItemsMain } from "./ScansDrawerItemsMain ";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";
import ScanByOperatorScreen from "../../../screens/Scans/Operator/ScanByOperatorScreen";

const Drawer = createDrawerNavigator();

export default function ScansDrawerNavigator() {
  const dimensions = useWindowDimensions();

  return (
    <Drawer.Navigator
      screenOptions={{
        drawerStyle: {
          width: 200,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent drawerItems={drawerItemsMain} {...props} />
      )}
    >
      <Drawer.Screen
        options={{
          drawerLabel: "Scan",
          title: "Scan",
        }}
        name="ScanByOperator"
        component={ScanByOperatorScreen}
      />
    </Drawer.Navigator>
  );
}
