import React, { useState, useEffect } from "react";
import { StyleSheet, Image, TouchableOpacity, Dimensions } from "react-native";
import { Text, View } from "../../../components/shared/Themed";
import * as Linking from "expo-linking";
import { tokenManager } from "../../../services/oktaAuthService";
import roleConfig from "../../../utils/constants/roles";
import teamConfig from "../../../utils/constants/teams";
import oktaConfig from "../../../configs/okta";
import { addUser, getUsers, updateUser } from "../../../services/usersService";
import {
  getInviteByInviteId,
  updateInvite,
} from "../../../services/inviteService";
import {
  getCompanyDetails,
  updateCompany,
} from "../../../services/companyService";
import { IDToken } from "@okta/okta-auth-js";
import Spinner from "../../../components/shared/Spinner";
import { removeItemFromSessionStorage } from "../../../services/utils/storageService";

export default function AcceptInviteForm() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [invite, setInvite] = useState<unknown>({});
  const [inviteId, setInviteId] = useState<string>("");
  const [userInfo, setUserInfo] = useState<unknown>({});
  const [company, setCompany] = useState<unknown>({});
  const [dimensionsWidth, setDimensionWidth] = useState(
    Dimensions.get("window").width
  );

  Dimensions.addEventListener("change", () => {
    try {
      setDimensionWidth(Dimensions.get("window").width);
    } catch (e) {
      console.log(`Dimensions event error: ${e}`);
    }
  });

  const submitHandler = () => {
    setLoading(true);
    updateInvite({ status: "completed" }, inviteId as string).then((data) => {
      if (data?.status === "ACTIVE") {
        removeItemFromSessionStorage('invite-context"');
        const userBody = {
          company_ids: company.company_id,
          roles: { company_id: company.company_id, role: invite.role },
          team:
            company.company_name === "Multisteps"
              ? invite.team
              : teamConfig.teams.external,
        };

        updateUser(userBody, userInfo.sub).then((data) => {
          if (data.status === "ACTIVE") {
            const companyBody = {
              $push:
                invite.role === roleConfig.roles.owner
                  ? { owner_ids: userInfo.sub }
                  : invite.role === roleConfig.roles.admin
                  ? { admin_ids: userInfo.sub }
                  : { member_ids: userInfo.sub },
            };

            updateCompany(companyBody, company.company_id).then((data) => {
              if (data?.status === "ACTIVE") {
                window.location.href = `${oktaConfig.api.redirectUri}`;
              }
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    getUsers(false).then((data) => {
      !data.users.length && addUser().then((mapiData) => {});
    });
    if (!inviteId && isMounted) {
      (async () => {
        const idToken: IDToken = (await tokenManager.get("idToken")) as IDToken;
        if (idToken) {
          const claims = idToken.claims;
          setUserInfo(claims);
          Linking.getInitialURL().then((data) => {
            if (data?.includes("invites/")) {
              const url = data.split("/");
              const inviteId = url[4];
              setInviteId(inviteId);
              getInviteByInviteId(inviteId).then((data) => {
                // hardcode fix for multisteps.me user as they may not have email address
                const domainUserEmail = claims.primaryEmail
                  .toString()
                  .includes("multisteps.me")
                  ? claims.primaryEmail
                      .toString()
                      .replace(/.me/g, "")
                      .replace(/_/g, ".")
                  : claims.primaryEmail;
                if (
                  data?.invite.length &&
                  (data.invite[0].email === claims.primaryEmail ||
                    data.invite[0].email.includes(domainUserEmail))
                ) {
                  setInvite(data.invite[0]);
                  const companyId = data.invite[0].company_id;
                  getCompanyDetails(companyId).then((data) => {
                    setCompany(data?.company);
                  });
                } else {
                  window.location.href = `${oktaConfig.api.redirectUri}/permission/issue`;
                }
              });
            }
          });
        } else {
          window.location.href = `${oktaConfig.api.redirectUri}`;
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <View style={styles.rootContainer}>
      {dimensionsWidth > 700 && company.company_name && (
        <View style={styles.leftContainer}>
          <Image
            style={styles.backgroundImage}
            source={require("../../../assets/invite.png")}
            resizeMode="contain"
          />
        </View>
      )}
      {company.company_name && (
        <View style={styles.rightContainer}>
          <View style={styles.box}>
            <View style={styles.subBox}></View>
            <Image
              style={styles.tinyLogo}
              source={require("../../../assets/logo2.svg")}
            />
            {invite.status && invite.status === "pending" ? (
              <View
                style={{
                  flexDirection: "row",
                  margin: 25,
                  minWidth: "75%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={[styles.label, { color: "#808080" }]}>
                  Accept invitation from{" "}
                </Text>
                <Text style={[styles.label, { color: "darksalmon" }]}>
                  {company.company_name}
                </Text>
              </View>
            ) : (
              <Text style={[styles.label, { margin: 25, color: "red" }]}>
                {" "}
                This link is expired{" "}
              </Text>
            )}
            {invite.status && invite.status === "pending" && (
              <TouchableOpacity style={styles.button} onPress={submitHandler}>
                {isLoading ? (
                  <Spinner
                    styles={[styles.spinner]}
                    size="small"
                    color="#00ff00"
                  />
                ) : (
                  <Text style={styles.buttonText}>Accept</Text>
                )}
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    flexDirection: "row",
  },
  rightContainer: {
    flex: 1,
    alignItems: "center",
    borderLeftWidth: 1,
    borderColor: "#ccc",
  },
  leftContainer: {
    flex: 1,
    backgroundColor: "#dedee0",
  },
  box: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  subBox: {
    margin: 20,
    width: "100%",
  },
  button: {
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#7F00FF",
    margin: 20,
  },
  buttonText: {
    fontSize: 20,
    lineHeight: 21,
    fontWeight: "bold",
    textAlign: "center",
    letterSpacing: 0.25,
    color: "white",
  },
  hyperLink: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  label: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  tinyLogo: {
    width: 250,
    height: 60,
    alignSelf: "center",
    marginTop: 10,
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
  },
  spinner: {
    flexDirection: "column",
  },
});
