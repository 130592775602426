import React from "react";
import { View, Text } from "../../shared/Themed";
import { formatValueAsDataType, renameLabel } from "../../../utils/common";
import { Divider } from "react-native-paper";
import { ReadOnlyProps } from "../../../utils/templates";

interface Props {
  listData: any;
  excludedKeys: Array<string>;
  orderedKeys?: Array<string>;
  renameList?: Record<string, Record<string, string | boolean | number>>;
  specialLabels?: Record<string, Record<string, string>>;
  isDisplayTime?: boolean;

  //exclude column to not have thousand separator format
  columnsToNotHaveThousandSeparator?: Array<string>;
}

export const ModalBody: React.FC<ReadOnlyProps<Props>> = ({
  listData,
  excludedKeys,
  orderedKeys,
  renameList,
  specialLabels,
  columnsToNotHaveThousandSeparator,
  isDisplayTime = false,
}: ReadOnlyProps<Props>) => {
  const filteredContainerData =
    orderedKeys ||
    (
      Object.keys(listData).filter(
        (container) => !excludedKeys.includes(container)
      ) as Array<string>
    ).sort((a, b) => a.localeCompare(b));

  return (
    <View>
      {filteredContainerData.map((value) => {
        const _value = ["", undefined, null].includes(listData[value])
          ? "N/A"
          : listData[value];

        const _filteredValue = columnsToNotHaveThousandSeparator?.includes(
          value
        )
          ? _value
          : formatValueAsDataType(_value, isDisplayTime);

        const _specialLabel =
          specialLabels &&
          specialLabels[value] &&
          specialLabels[value].newLabel;

        const _specialValue =
          specialLabels &&
          specialLabels[value] &&
          formatValueAsDataType(specialLabels[value].newValue, isDisplayTime);

        const _supportingValue =
          specialLabels &&
          specialLabels[value] &&
          formatValueAsDataType(
            specialLabels[value].supportingValue,
            isDisplayTime
          );

        const _formattedLabel =
          _specialLabel !== "" && _specialLabel !== undefined
            ? _specialLabel
            : renameList && renameList[value]
            ? renameList[value].name
            : renameLabel(value);

        return (
          <View key={value}>
            <View
              style={{
                flexDirection: "row",
                marginVertical: "1%",
                flexWrap: "wrap",
              }}
            >
              <Text style={{ fontWeight: "bold" }}>
                {_formattedLabel}
                &nbsp;-&nbsp;
              </Text>
              <Text>{`${_specialValue || _filteredValue} ${
                _supportingValue ? `(${_supportingValue})` : ""
              }`}</Text>
            </View>
            <Divider />
          </View>
        );
      })}
    </View>
  );
};
