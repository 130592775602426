import React from "react";
import { StyleSheet, TextInput } from "react-native";
import Icons from "@expo/vector-icons/MaterialCommunityIcons";
import { Text, View } from "../shared/Themed";

type PropsFunction = () => void;

export default function CustomTextInput({
  title,
  required,
  borderColor,
  placeHolder,
  value,
  marginLeft,
  marginTop,
  disabled,
  setChangeText,
  width,
}: {
  required?: Boolean;
  borderColor: String;
  title: String;
  placeHolder: String;
  value: String;
  marginTop: Number;
  marginLeft: Number;
  width: String;
  disabled: boolean;
  setChangeText: PropsFunction;
}) {
  return (
    <View
      style={{
        width: width,
        marginTop: marginTop,
        marginLeft: marginLeft,
        alignSelf: "flex-start",
        justifySelf: "flex-start",
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <Text style={[styles.label]}>{title}</Text>
        {required && <Icons name="star" size={10} color="red" />}
      </View>
      <TextInput
        style={[styles.input, { borderColor: borderColor, width: "100%" }]}
        onChangeText={setChangeText}
        placeholder={placeHolder}
        value={value}
        editable={disabled}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  label: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    marginLeft: 20,
  },
  input: {
    height: 60,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderWidth: 0.5,
    borderRadius: 5,
    fontSize: 15,
    margin: 20,
  },
});
