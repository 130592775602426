import store from "../configureStore";
import allActions from "../actions";

import { actionTypesConfigurations } from "./../constants/actionTypesConfigurations";
import { companyModel } from "../../models/companyModel";
import { userClaimInformation } from "../../models/usersModel";

const { dispatch } = store;

export const ConfigurationDispatcher = (
  actionType: actionTypesConfigurations,
  data: unknown
) => {
  const configurationAction = allActions.configurationsActions;

  switch (actionType) {
    case actionTypesConfigurations.IsUserAuthorized:
      dispatch(configurationAction.setIsUserAuthorized(data as boolean));
      break;
    case actionTypesConfigurations.Companies:
      dispatch(configurationAction.setCompanies(data as Array<companyModel>));
      break;
    case actionTypesConfigurations.CurrentCompany:
      dispatch(configurationAction.setCurrentCompany(data as companyModel));
      break;
    case actionTypesConfigurations.Navigation:
      dispatch(configurationAction.setNavigation(data));
      break;
    case actionTypesConfigurations.Logo:
      dispatch(configurationAction.setLogo(data as string));
      break;
    case actionTypesConfigurations.CurrentRole:
      dispatch(configurationAction.setCurrentRole(data as string));
      break;
    case actionTypesConfigurations.Initial:
      dispatch(configurationAction.setInitials(data as string));
      break;
    case actionTypesConfigurations.UserInfo:
      dispatch(configurationAction.setUserInfo(data as userClaimInformation));
      break;
    case actionTypesConfigurations.HasAvatar:
      dispatch(configurationAction.setHasAvatar(data as boolean));
      break;
    case actionTypesConfigurations.HasAvatarMenu:
      dispatch(configurationAction.setHasAvatarMenu(data as boolean));
      break;
    case actionTypesConfigurations.IsDrawer:
      dispatch(configurationAction.setIsDrawer(data as boolean));
      break;
    case actionTypesConfigurations.ProfilePhoto:
      dispatch(configurationAction.setProfilePhoto(data as string));
      break;
    case actionTypesConfigurations.UserRoles:
      dispatch(configurationAction.setUserRoles(data as Array<string>));
      break;
  }
};
