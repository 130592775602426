import { checkS3Object, getS3SignUrl } from "../services/s3BucketService";

export const validSignedURL = (
  itemId: string,
  bucketName: string,
  setImageLoading: (e: boolean) => void,
  setSelectedImage: (e: string) => void
) => {
  const keyTypeJpg = `${itemId}.jpg`;
  const keyTypePng = `${itemId}.png`;
  const keys = [
    keyTypePng,
    keyTypePng.toUpperCase(),
    keyTypeJpg,
    keyTypeJpg.toUpperCase(),
  ];

  // `no-image.png`]
  const bucket = `multisteps-${bucketName}`;

  // set default image to no-image.png
  getS3SignUrl("multisteps-label", "no-image.png", "getObject", "image/*").then(
    (data) => {
      setSelectedImage(data?.signedUrl as string);
      setImageLoading(true);
    }
  );

  keys.forEach((key) => {
    checkS3Object(bucket, key).then((result) => {
      if (result) {
        getS3SignUrl(bucket, key, "getObject", "image/*").then((data) => {
          setSelectedImage(data?.signedUrl);
        });
      }
    });
  });

  setTimeout(setImageLoading, 2000, false);
};
