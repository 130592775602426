import { useContext, useEffect, useRef, useState } from "react";
import { StyleSheet, ScrollView, Image } from "react-native";
import { Button, Card, Divider, Text } from "react-native-paper";
import { View } from "../../../components/shared/Themed";

import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { useForm, Controller } from "react-hook-form";
import { useToast } from "react-native-toast-notifications";
import { ReadOnlyProps } from "../../../utils/templates";
import { TextInputControl } from "../../../components/custom/TextInputControl";
import { addBulkBarcodesForBoxes } from "../../../services/barcodeService";
import { useSelector } from "react-redux";
import { userClaimInformation } from "../../../models/usersModel";

import Spinner from "../../../components/shared/Spinner";
import _ from "lodash";
import { LabelBannerWithSearchTags } from "../../../components/custom/Banners/LabelBannerWithSearchTags";
import * as Okta from "../../../services/oktaAuthService";
import oktaConfig from "../../../configs/okta";
import { convertScannerFeedToModel } from "../utils/scanCalculations";
import { SelectBoxControl } from "../../../components/custom/SelectBoxControl";
import { scanState } from "../../../redux/state/scan";
import { clearSessionStorage } from "../../../services/utils/storageService";

interface Props {
  route: any;
  navigation: any;
}

export default function ScanByOperatorScreen({
  route,
  navigation,
}: ReadOnlyProps<Props>) {
  const toast = useToast();

  const scanDefaultValues = {
    itemsBarCode: "",
    operatingLine: "",
  };

  const { isSmallDevice } = useContext(DeviceOrientationContext);
  const [defaultOperatingLine, setDefaultOperatingLine] = useState<string>("");
  const [displaySpinner, setDisplaySpinner] = useState<boolean>(true);
  const [disableControls, setDisableControls] = useState<boolean>(false);
  const refTextInput = useRef<any>(null);

  const { userInfo } = useSelector((state: any) => state.configurations);

  useEffect(() => {
    if (refTextInput.current) {
      refTextInput.current.focus();
    }
  }, []);

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    defaultValues: scanDefaultValues,
  });

  const CardTitleWithIcon = () => {
    return (
      <>
        <Text style={styles.cardTitleStyles}>&nbsp;Upload Barcodes</Text> &nbsp;
        <Spinner
          isAnimating={!displaySpinner}
          size={18}
          color="rgb(47, 149, 220)"
        />
      </>
    );
  };

  const resetOperatingLine = () => {
    resetField("operatingLine", {
      defaultValue: defaultOperatingLine,
      keepDirty: true,
      keepError: true,
      keepTouched: true,
    });
  };

  const resetBarCode = () => {
    resetField("itemsBarCode", {
      keepDirty: false,
      keepError: false,
      keepTouched: false,
    });
  };

  const resetScreen = () => {
    //Resetting individual fields
    resetBarCode();
    resetOperatingLine();
    refTextInput.current.focus();

    setDisableControls(true);
  };

  const terminateOrResetSession = (message: string, displayType: string) => {
    toast.show(message, {
      type: displayType,
    });
    resetScreen();
  };

  const onSubmit = (formData: any) => {
    setDisplaySpinner(false);

    const { itemsBarCode, operatingLine } = formData;
    const _userInfo: userClaimInformation = userInfo;
    const formattedBarcodeModels = convertScannerFeedToModel(
      itemsBarCode,
      _userInfo.user_id,
      `${userInfo.first_name} ${userInfo.last_name}`,
      operatingLine
    );

    addBulkBarcodesForBoxes(formattedBarcodeModels)
      .then((result) => {
        terminateOrResetSession(result.data.message, "success");
      })
      .catch((err) => {
        terminateOrResetSession(err.message, "danger");
      })
      .finally(() => {
        setDisplaySpinner(true);
      });
  };

  const LogOutButton = () => {
    return (
      <View>
        <Button
          mode="contained"
          onPress={() => {
            Okta.deleteSession().then(() => {
              clearSessionStorage;
              window.location.href = `${oktaConfig.api.redirectUri}`;
            });
          }}
          loading={false}
          disabled={false}
          color="#c40c0c"
          style={{ marginRight: 5 }}
          icon="logout"
        >
          Log out
        </Button>
      </View>
    );
  };

  return (
    <ScrollView>
      <LabelBannerWithSearchTags
        bannerHeight="51px"
        childern={<LogOutButton />}
      />
      <View
        style={[
          styles.container,
          {
            backgroundColor: "white",
            paddingBottom: isSmallDevice ? "13%" : "0%",
          },
        ]}
      >
        <Card testID="scanForm" mode="elevated" style={styles.card}>
          <Card.Title
            title={<CardTitleWithIcon />}
            style={[styles.cardTitle, styles.bgLightSilver]}
          />
          <Card.Content style={{ padding: 0 }}>
            <View style={[styles.box, { width: isSmallDevice ? 300 : 400 }]}>
              <View>
                <Text style={{ fontWeight: "bold" }}>
                  Upload data from Scanner
                </Text>
                <Image
                  source={{
                    uri: require("../../../assets/UPLOAD_DATA.png"),
                  }}
                  style={{ width: "100%", height: 30, marginTop: "1%" }}
                />
              </View>
              <Divider style={{ marginTop: "2%" }} />
              <View>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="operatingLine"
                  render={({ field: { onChange, value } }) => (
                    <SelectBoxControl
                      Items={scanState.operatingLines}
                      defaultOption={value}
                      title="Operating Line"
                      width="100%"
                      placeholder="Select Operating Line"
                      required={true}
                      onSelectedValue={(event) => {
                        onChange(event);
                        setDefaultOperatingLine(event.toString());
                        resetBarCode();
                        refTextInput.current.focus();
                      }}
                    />
                  )}
                />
                {errors.operatingLine && (
                  <Text style={styles.errorColor}>* This is required.</Text>
                )}
                <Controller
                  control={control}
                  rules={{
                    validate: {
                      required: async (value) => {
                        if (!value) return "* This is required.";
                      },
                    },
                  }}
                  name="itemsBarCode"
                  render={({ field: { onChange, value } }) => (
                    <TextInputControl
                      title="Barcodes"
                      required={true}
                      isMultiline={true}
                      width="100%"
                      height={250}
                      placeholder="Scan the upload barcode to let the scanner write the data in here."
                      value={value}
                      setChangeText={onChange}
                      blurOnSubmit={false}
                      editable={!disableControls}
                      refInput={refTextInput}
                      handleOnSubmitEditing={handleSubmit(onSubmit)}
                      showSoftInputOnFocus={false}
                    />
                  )}
                />
                {errors.itemsBarCode && (
                  <Text style={styles.errorColor}>* This is required.</Text>
                )}
              </View>
              <View>
                <Button
                  mode="contained"
                  onPress={handleSubmit(onSubmit)}
                  loading={!displaySpinner}
                  disabled={disableControls}
                  color="rgb(47, 149, 220)"
                  style={{ marginTop: 15 }}
                >
                  Save
                </Button>
              </View>
              <Divider style={{ marginTop: "2%" }} />
              <View style={{ marginTop: "2%" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Clean data from Scanner
                </Text>
                <Image
                  source={{
                    uri: require("../../../assets/CLEAR_DATA.png"),
                  }}
                  style={{ width: "100%", height: 30, marginTop: "1%" }}
                />
              </View>
            </View>
          </Card.Content>
        </Card>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  errorColor: {
    color: "red",
  },
  bgLightSilver: {
    backgroundColor: "#f2f2f2",
  },
  successColor: {
    color: "#037d50",
  },
  backToButton: {
    fontSize: 11,
  },
  card: {
    marginTop: "3%",
    marginBottom: "3%",
  },
  cardTitle: {
    borderWidth: 1,
    borderColor: "rgba(216, 216, 216, 0.4)",
  },
  cardTitleStyles: {
    color: "black",
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingBottom: "1%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
    margin: 20,
    padding: 0,
  },
  subBox: {
    flexDirection: "column",
    padding: 0,
  },
  subTitle: {
    color: "#808080",
    fontSize: 12,
    fontWeight: "bold",
    marginTop: "1%",
  },
});
