import React, { useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, Dimensions, ScrollView } from "react-native";
import { FAB } from "react-native-paper";
import { View } from "../../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../../components/custom/Modal";
import Spinner from "../../../components/shared/Spinner";
import useColorScheme from "../../../hooks/useColorScheme";
import { getAllProducts } from "../../../services/productService";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import {
  headersProduct,
  mediumDeviceHeadersProduct as mdProduct,
  smallDeviceHeadersProduct as smProduct,
} from "../utils/productHeaders";
import { productModel } from "../../../models/reportsModel";
import { validSignedURL } from "../../../utils/validateSignedUrl";
import { handleTableLoader } from "../../../utils/common";
import { useSelector } from "react-redux";
import { userClaimInformation } from "../../../models/usersModel";
import { useIsFocused } from "@react-navigation/native";
import { sortColumns } from "../../../utils/sortColumns";
import { filterBasedOnAnyKey } from "../../../utils/searchInArrays";
import { LabelBannerWithSearchTags } from "../../../components/custom/Banners/LabelBannerWithSearchTags";
import { ReportsDispatcher } from "../../../redux/dispatcher/reportsDispatcher";
import { actionTypesReports } from "../../../redux/constants/actionTypesReports";
import { formatDate } from "../../../utils/dateUtility";

export default function ProductsReportScreen({ route, navigation }: any) {
  const colorScheme = useColorScheme();
  const isFocused = useIsFocused();
  const listRef = useRef<ScrollView>(null);

  const [productsPageLevelList, setProductsPageLevelList] = useState<
    Array<productModel>
  >([]);

  const [isSearched, setIsSearched] = useState<boolean>(false);

  const { userInfo } = useSelector((state: any) => state.configurations);
  const {
    productReportList,
    isProductReportListLoaded,
    productReportFilterCategoryList,
    lastRefreshProductReport,
  } = useSelector((state: any) => state.reports);

  const [selected, setSelected] = useState<productModel>({} as productModel);
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headersProduct
    );
  const [searchedText, setSearchedText] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [lastRefresh, setLastRefresh] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [, setFabOpen] = useState<Record<string, boolean>>({
    open: false,
  });
  const [fabPosition] = useState<number>(320);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [contentOffSet, setContentOffSet] = useState<number>(0);

  const [_isDisplayViewOn, _setIsDisplayViewOn] = useState(false);
  const [_filterCategory, _setFilterCategory] = useState<string>("all");

  const { isLargeDevice, isMediumDevice, isMobileDevice, isSmallDevice } =
    useContext(DeviceOrientationContext);

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const handleScroll = (event: any) => {
    setContentOffSet(event.nativeEvent.contentOffset.y);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedProductsReportList: Array<productModel> = sortColumns(
      productsPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setProductsPageLevelList(_sortedProductsReportList);
  };

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      setSearchedText(searchText);

      const filteredListBasedOnSearchOrCondition: Array<productModel> =
        filterBasedOnAnyKey(productReportList, searchText);

      const _filterProductByCategory = filterProductByCategory(
        filteredListBasedOnSearchOrCondition,
        _filterCategory
      );

      //filter based on tags or display switch
      setProductsPageLevelList(
        _isDisplayViewOn ? _filterProductByCategory : _filterProductByCategory
      );
    } else {
      setSearchedText("");
    }

    fadeTableLoader();
  };

  const onFabOpenChange = ({ open }: { open: boolean }) => setFabOpen({ open });

  const filterProductByCategory = (
    _productsReport: Array<productModel>,
    _filterCategory: string
  ) => {
    return _productsReport.filter((product: productModel) => {
      switch (_filterCategory) {
        case "produce":
          return [101, 102, 103, 104, 106].includes(product.ItmsGrpCod);
          break;
        case "berry":
          return product.ItmsGrpCod === 100;
          break;
        case "meat":
          return product.ItmsGrpCod === 110;
          break;
        case "meals":
          return [111, 116].includes(product.ItmsGrpCod);
          break;
        case "salad":
          return product.ItmsGrpCod === 107;
          break;
        case "bakery":
          return product.ItmsGrpCod === 108;
          break;
        default:
          return product;
          break;
      }
    });
  };

  useEffect(() => {
    setLastRefresh(lastRefreshProductReport);
  }, [lastRefreshProductReport]);

  useEffect(() => {
    let isMounted: boolean = true;
    const _userInfo = userInfo as userClaimInformation;

    if (isMounted) {
      //To restrict fetching data from endpoint, if data is available in store
      if (productsPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();

        //set filtering by category & display by carton to default
        _setIsDisplayViewOn(false);
        _setFilterCategory("all");
        setSearchedText("");

        //set last refresh date
        const formattedLastRefreshDate = formatDate(new Date(), "short", true);
        ReportsDispatcher(
          actionTypesReports.LastRefreshProductReport,
          formattedLastRefreshDate
        );

        getAllProducts(_userInfo.team, _userInfo.zone_info, tableHeaders);

        fadeTableLoader();
      }
    }

    setTableHeaders(
      isLargeDevice ? headersProduct : isMobileDevice ? smProduct : mdProduct
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [isFocused, isRefresh]);

  useEffect(() => {
    setImageLoading(true);
  }, [modalVisible]);

  useEffect(() => {
    displayTableLoader();

    const _productsReport = _isDisplayViewOn
      ? productsPageLevelList
      : (productReportList as Array<productModel>);
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_productsReport, searchedText)
        : _productsReport;

    if (_isDisplayViewOn) {
      setProductsPageLevelList(filteredListBasedOnSearchOrCondition);
    } else {
      setProductsPageLevelList(
        filterProductByCategory(
          filteredListBasedOnSearchOrCondition,
          _filterCategory
        )
      );
    }

    fadeTableLoader();
  }, [_isDisplayViewOn]);

  useEffect(() => {
    displayTableLoader();

    const _productsReport = productReportList as Array<productModel>;
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_productsReport, searchedText)
        : _productsReport;

    if (_isDisplayViewOn) {
      setProductsPageLevelList(
        filterProductByCategory(
          filteredListBasedOnSearchOrCondition,
          _filterCategory
        )
      );
    } else {
      setProductsPageLevelList(
        filterProductByCategory(
          filteredListBasedOnSearchOrCondition,
          _filterCategory
        )
      );
    }

    fadeTableLoader();
  }, [_filterCategory]);

  useEffect(() => {
    displayTableLoader();

    const _productsReport = productReportList as Array<productModel>;
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_productsReport, searchedText)
        : _productsReport;

    if (_filterCategory === "all" && !_isDisplayViewOn) {
      setProductsPageLevelList(filteredListBasedOnSearchOrCondition);
    }

    fadeTableLoader();
  }, [productReportList, _isDisplayViewOn, _filterCategory === "all"]);

  useEffect(() => {
    validSignedURL(
      selected.ItemCode || "",
      "label",
      setImageLoading,
      setSelectedImage
    );
  }, [selected]);

  const ModalContent = () => {
    const _excludedKeys = [
      "ItemCode",
      "PerCtns",
      "PerPallet",
      "FrgnName",
      "CardName",
    ];

    const _orderedKeys = [
      "ItemCode",
      "PerCtns",
      "PerPallet",
      "FrgnName",
      "CardName",
    ];

    const _specialLabels = {
      PerPallet: {
        oldLabel: "PerPallet",
        newLabel: "Total Per Pallet",
        newValue: (selected.PerPallet * selected.PerCtns) as unknown as string,
        supportingValue: ((selected.PerPallet * selected.PerCtns) /
          selected.PerCtns) as unknown as string,
      },
      ItmsGrpCod: {
        oldLabel: "ItmsGrpCod",
        newLabel: "Display",
        newValue: _isDisplayViewOn ? "Per CARTON" : "Per PIECE",
      },
    };

    return (
      <Modal.ModalContent
        title={selected.ItemCode || ""}
        subTitle={selected.FrgnName}
        icon="folder"
        uri={selectedImage}
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headersProduct}
            orderedKeys={_orderedKeys}
            specialLabels={_specialLabels}
          />
        }
      />
    );
  };

  return (
    <ScrollView
      style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      onScroll={handleScroll}
      ref={listRef}
    >
      <View
        style={[
          styles.container,
          { backgroundColor: colorScheme === "light" ? "#f2f2f2" : "#f2f2f2" },
        ]}
      >
        <View
          style={[
            styles.containerContent,
            {
              width: isLargeDevice ? "100%" : isMediumDevice ? "100%" : "100%",
            },
          ]}
        >
          {isProductReportListLoaded ? (
            <View>
              <FAB
                icon="arrow-up"
                style={[
                  styles.fab,
                  {
                    top:
                      contentOffSet +
                      Dimensions.get("window").height -
                      fabPosition,
                  },
                ]}
                onPress={() =>
                  listRef.current?.scrollTo({ x: 0, y: 0, animated: true })
                }
                small
              />
              <LabelBannerWithSearchBar
                titleName={`Total Items: ${productsPageLevelList.length}`}
                handleSearch={handleSearch}
                setRefresh={setRefresh}
                dataToExport={[]}
                fileName="productReportScreen"
                sheetName="Product Report Screen"
              />
              <LabelBannerWithSearchTags
                isMobileDevice={isMobileDevice}
                isSmallDevice={isSmallDevice}
                label="Filter by Category"
                filterCategoryList={productReportFilterCategoryList}
                filterCategory={_filterCategory}
                setFilterCategory={_setFilterCategory}
              />
              <DataTableWithPagination
                headers={tableHeaders}
                rows={productsPageLevelList}
                itemsPerPage={20}
                useModal={true}
                isDataLoading={isSearched}
                isRowsExpandable={false}
                setModalVisible={setModalVisible}
                setSelected={setSelected}
                isDetail={true}
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={ModalContent}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    zIndex: 1,
  },
});
