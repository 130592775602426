import { createContext } from "react";
import { DeviceOrientation } from "../../models/deviceOrientationModel";

const DeviceOrientationContext = createContext<DeviceOrientation>({
  isLargeDevice: false,
  isMediumDevice: false,
  isSmallDevice: false,
  isMobileDevice: false,
});

export default DeviceOrientationContext;
