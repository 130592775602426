import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { FontAwesome } from "@expo/vector-icons";
import React, { useState, useEffect, useContext } from "react";
import { Pressable, TouchableOpacity } from "react-native";
import * as Linking from "expo-linking";
import { View } from "../../../components/shared/Themed";
import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import ContentTitleHeader from "../../../components/core/ContentTitleHeader";
import CompanyInfoScreen from "../../../screens/Settings/CompanySettingScreens/CompanySettingAccountScreen";
import CompanyBrandingScreen from "../../../screens/Settings/CompanySettingScreens/CompanySettingBrandingScreen";
import CompanyLogo from "../../../components/core/CompanyLogo";
import TabBarIcon from "../../../components/core/TabBarIcon";
import TeamSettingScreen from "../../../screens/Settings/TeamMemberScreens/TeamSettingMemberScreen";
import { RootTabParamList, RootTabScreenProps } from "../../../types";

import roleConfig from "../../../utils/constants/roles";
import oktaConfig from "../../../configs/okta";
import { getRole } from "../../../services/usersService";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

export default function OrgSettingsTabNavigator({ navigation }: any) {
  const colorScheme = useColorScheme();
  const { isSmallDevice } = useContext(DeviceOrientationContext);

  const companyContext = JSON.parse(
    getItemFromSessionStorage("company-context") as string
  );
  const [currentRole, setCurrentRole] = useState<String>("");

  const Tab = createMaterialTopTabNavigator<RootTabParamList>();
  const ReturnHomeButton = (navigation: any) => {
    return (
      <Pressable
        onPress={() =>
          navigation.navigate("Root", {
            screen: "Home",
            params: {
              screen: "TabTwo",
            },
          })
        }
        style={({ pressed }) => ({
          opacity: pressed ? 0.5 : 1,
        })}
      >
        <FontAwesome
          name="home"
          size={25}
          color={Colors[colorScheme].text}
          style={{ marginRight: 15 }}
        />
      </Pressable>
    );
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getRole(companyContext.company_id).then((mapiData) => {
        setCurrentRole(mapiData?.role);
        Linking.getInitialURL().then((data) => {
          if (
            mapiData?.role !== roleConfig.roles.admin &&
            mapiData?.role !== roleConfig.roles.owner
          ) {
            window.location.href = `${oktaConfig.api.redirectUri}`;
          }
        });
      });
    }
    return () => {
      isMounted = false;
    };
  });

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: colorScheme === "light" ? "#fafafa" : "black",
      }}
    >
      {!isSmallDevice && (
        <View style={{ flexDirection: "row", width: "100%", height: 60 }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              height: 60,
            }}
          >
            <CompanyLogo />
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "60%",
              height: 60,
            }}
          >
            <TouchableOpacity
              onPress={() =>
                (window.location.href = `${oktaConfig.api.redirectUri}`)
              }
            >
              <TabBarIcon name="home" color={"black"} />
            </TouchableOpacity>
          </View>
        </View>
      )}
      {!isSmallDevice && (
        <ContentTitleHeader
          name="Organization Settings"
          navigation={navigation}
        />
      )}
      <Tab.Navigator
        initialRouteName="Company"
        title="Company"
        screenOptions={{
          tabBarActiveTintColor: Colors[colorScheme].tint,
          tabBarStyle: {
            height: 50,
            width: isSmallDevice ? "100%" : "50%",
            alignSelf: "center",
            justifySelf: "center",
            borderTopWidth: 0,
            backgroundColor: colorScheme === "light" ? "#fafafa" : "#fafafa",
          },
          tabBarLabelStyle: {
            fontSize: 14,
            fontWeight: "bold",
            textTransform: "none",
          },
          tabBarItemStyle: {
            width: 100,
          },
        }}
      >
        <Tab.Screen
          name="Company"
          component={CompanyInfoScreen}
          options={({ navigation }: RootTabScreenProps<"Company">) =>
            isSmallDevice
              ? {
                  title: "",
                  headerTitleAlign: "center",
                  tabBarIcon: ({ color }) =>
                    isSmallDevice && (
                      <TabBarIcon name="briefcase" color={color} />
                    ),
                  headerRight: () => ReturnHomeButton(navigation),
                }
              : {
                  title: "Company",
                }
          }
        />

        {currentRole !== roleConfig.roles.member && (
          <Tab.Screen
            name="Branding"
            component={CompanyBrandingScreen}
            options={({ navigation }: RootTabScreenProps<"Branding">) =>
              isSmallDevice
                ? {
                    title: "",
                    headerTitleAlign: "center",
                    tabBarIcon: ({ color }) =>
                      isSmallDevice && (
                        <TabBarIcon name="image" color={color} />
                      ),
                    headerRight: () => ReturnHomeButton(navigation),
                  }
                : {
                    title: "Branding",
                  }
            }
          />
        )}

        {currentRole !== roleConfig.roles.member && (
          <Tab.Screen
            name="Teams"
            component={TeamSettingScreen}
            options={({ navigation }: RootTabScreenProps<"Teams">) =>
              isSmallDevice
                ? {
                    title: "",
                    headerTitleAlign: "center",
                    tabBarIcon: ({ color }) =>
                      isSmallDevice && (
                        <TabBarIcon name="users" color={color} />
                      ),
                    headerRight: () => ReturnHomeButton(navigation),
                  }
                : {
                    title: "Teams",
                  }
            }
          />
        )}
      </Tab.Navigator>
    </View>
  );
}
