import { useEffect, useRef, useState } from "react";
import { Text, View } from "../../../components/shared/Themed";
import { ReadOnlyProps } from "../../../utils/templates";
import _ from "lodash";
import { NoDataBanner } from "../../../components/shared/NoDataBanner";
import { ProgressChart } from "../../../components/custom/Charts/ProgressChart";
import { getGroupScanPerOperator } from "../../../services/barcodeService";
import { formatDateToMongoISOFormat } from "../../../utils/dateUtility";
import Spinner from "../../../components/shared/Spinner";

interface Props {
  selectedScanDate: Date;
  startDate: string;
  endDate: string;
  operatorName: string;
  isRefresh: boolean;
}

interface OperatorProps {
  operatorName: string;
  totalScannedBoxes: number;
  progress: number;
}

export const ScanByOperator = ({
  selectedScanDate,
  startDate,
  endDate,
  operatorName,
  isRefresh,
}: ReadOnlyProps<Props>) => {
  const isMountedRef = useRef<boolean>();
  const [processedScanStats, setProcessedScanStats] = useState<{
    groupScanPerOperators: Array<OperatorProps>;
  }>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isSectionLoadedBefore, setIsSectionLoadedBefore] =
    useState<boolean>(false);

  const fetchGroupScanPerOperator = async () => {
    try {
      const scanResults = await getGroupScanPerOperator(
        startDate,
        endDate,
        formatDateToMongoISOFormat(selectedScanDate),
        operatorName
      );

      setProcessedScanStats(scanResults.data);
      setIsDataLoading(scanResults.isLoading);
    } catch (error) {
      setProcessedScanStats({} as unknown as { groupScanPerOperators: [] });
      setIsDataLoading(false);

      console.log(
        "onDateChange in ScanByOperator section the stats loading method failed of the following reason: ",
        error
      );
    }
  };

  useEffect(() => {
    if (isSectionLoadedBefore) {
      setIsDataLoading(true);
      fetchGroupScanPerOperator();
    }
  }, [selectedScanDate, operatorName, isRefresh]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchGroupScanPerOperator();
    setIsSectionLoadedBefore(true);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const ProgressSubTitle = ({ value }: { value: number }) => {
    return (
      <Text>
        (
        <Text style={{ fontStyle: "italic", fontWeight: "bold" }}>{value}</Text>{" "}
        Boxes)
      </Text>
    );
  };

  return (
    <View style={{ margin: "3%" }}>
      {!isDataLoading ? (
        processedScanStats &&
        processedScanStats?.groupScanPerOperators?.length > 0 ? (
          processedScanStats?.groupScanPerOperators?.map((value, index) => {
            return (
              <View
                key={index}
                style={{ marginTop: index !== 0 ? "2%" : "0%" }}
              >
                <ProgressChart
                  title={value.operatorName}
                  subTitle={
                    <ProgressSubTitle value={value.totalScannedBoxes} />
                  }
                  progressValue={value.progress}
                />
              </View>
            );
          })
        ) : (
          <NoDataBanner />
        )
      ) : (
        <Spinner
          size="small"
          color={"#00ff00"}
          styles={{ flexDirection: "row", margin: "20%" }}
        />
      )}
    </View>
  );
};
