import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { useEffect, useState } from "react";
import { Image } from "react-native";
import HomeTabNavigator from "./HomeTabNavigator";
import UserSettingsTabNavigator from "../../SharedNavigator/TabNavigator/UserSettingsTabNavigator";
import OrgSettingsTabNavigator from "../../SharedNavigator/TabNavigator/OrgSettingsTabNavigator";
import TabBarIcon from "../../../components/core/TabBarIcon";
import { RootTabScreenProps } from "../../../types";
import roleConfig from "../../../utils/constants/roles";
import { useSelector } from "react-redux";

import { InitUser } from "../../utils/initUser";
import { ConfigurationDispatcher } from "../../../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../../../redux/constants/actionTypesConfigurations";

const Tab = createBottomTabNavigator();

export default function BottomTabNavigator({}) {
  const [logo, setLogo] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const { currentRole } = useSelector((state: any) => state.configurations);

  const _setLogo = (data: string) => {
    ConfigurationDispatcher(actionTypesConfigurations.Logo, data);
  };

  useEffect(() => {
    _setLogo(logo);
  }, [logo]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      InitUser(isMounted, setLoading, setLogo);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Tab.Navigator initialRouteName="Home">
      <Tab.Screen
        name="UserSettings"
        component={UserSettingsTabNavigator}
        options={({ navigation }: RootTabScreenProps<"UserSettings">) => ({
          title: "Accounts",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => <TabBarIcon name="user" color={color} />,
        })}
        listeners={{
          tabPress: (e) => {
            // Prevent default action
          },
        }}
      />
      <Tab.Screen
        name="Home"
        component={HomeTabNavigator}
        options={({ navigation }: RootTabScreenProps<"Home">) => ({
          title: "Home",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        })}
      />
      <Tab.Screen
        name="Company"
        component={OrgSettingsTabNavigator}
        options={{
          title: "",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => (
            <Image
              source={{ uri: logo }}
              style={{
                width: 60,
                height: 60,
              }}
              resizeMode="contain"
            />
          ),
        }}
        listeners={{
          tabPress: (e) => {
            currentRole !== roleConfig.roles.owner &&
              currentRole !== roleConfig.roles.admin &&
              e.preventDefault();
          },
        }}
      />
      <Tab.Screen
        name="Refresh"
        component={HomeTabNavigator}
        options={({}: RootTabScreenProps<"Home">) => ({
          title: "",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="refresh" color={color} />
          ),
        })}
        listeners={{
          tabPress: (e) => {
            window.location.reload();
          },
        }}
      />
    </Tab.Navigator>
  );
}
