import React from "react";
import { StyleSheet, Text } from "react-native";
import Icons from "@expo/vector-icons/MaterialCommunityIcons";
import { View } from "../shared/Themed";
import { DatePickerInput } from "react-native-paper-dates";
import { ReadOnlyProps } from "../../utils/templates";
import { ValidRangeType } from "react-native-paper-dates/lib/typescript/Date/Calendar";

interface Props {
  required?: boolean;
  disabled?: boolean;
  title: string;
  value: Date;
  width: string;
  defaultText: string;
  validRange?: ValidRangeType;
  setDate: (event: any) => void;
}

export const DateInputControl: React.FC<ReadOnlyProps<Props>> = ({
  required = false,
  title,
  disabled = false,
  value,
  width,
  defaultText,
  validRange,
  setDate,
}: ReadOnlyProps<Props>) => {
  return (
    <View style={{ flexDirection: "column", width: width, marginTop: 15 }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={[styles.label, { color: "black", marginBottom: "5px" }]}>
          {title}
        </Text>
        {required && <Icons name="star" size={10} color="red" />}
      </View>
      <View>
        <DatePickerInput
          style={styles.dateInput}
          disabled={disabled}
          mode="single"
          label=""
          locale="en-AU"
          validRange={validRange}
          value={value}
          onChange={(val) => setDate(val)}
          inputMode="start"
          key="datePickerInput"
          withDateFormatInLabel={false}
          activeUnderlineColor="white"
          underlineColor="white"
          defaultValue={undefined}
          outlineColor={undefined}
          activeOutlineColor={undefined}
          children={undefined}
          hitSlop={undefined}
          onLayout={undefined}
          pointerEvents={undefined}
          removeClippedSubviews={undefined}
          testID={undefined}
          nativeID={undefined}
          collapsable={undefined}
          needsOffscreenAlphaCompositing={undefined}
          renderToHardwareTextureAndroid={undefined}
          focusable={undefined}
          shouldRasterizeIOS={undefined}
          isTVSelectable={undefined}
          hasTVPreferredFocus={undefined}
          tvParallaxProperties={undefined}
          tvParallaxShiftDistanceX={undefined}
          tvParallaxShiftDistanceY={undefined}
          tvParallaxTiltAngle={undefined}
          tvParallaxMagnification={undefined}
          onStartShouldSetResponder={undefined}
          onMoveShouldSetResponder={undefined}
          onResponderEnd={undefined}
          onResponderGrant={undefined}
          onResponderReject={undefined}
          onResponderMove={undefined}
          onResponderRelease={undefined}
          onResponderStart={undefined}
          onResponderTerminationRequest={undefined}
          onResponderTerminate={undefined}
          onStartShouldSetResponderCapture={undefined}
          onMoveShouldSetResponderCapture={undefined}
          onTouchStart={undefined}
          onTouchMove={undefined}
          onTouchEnd={undefined}
          onTouchCancel={undefined}
          onTouchEndCapture={undefined}
          accessible={undefined}
          accessibilityActions={undefined}
          accessibilityLabel={undefined}
          accessibilityRole={undefined}
          accessibilityState={undefined}
          accessibilityHint={undefined}
          accessibilityValue={undefined}
          onAccessibilityAction={undefined}
          accessibilityLiveRegion={undefined}
          importantForAccessibility={undefined}
          accessibilityElementsHidden={undefined}
          accessibilityViewIsModal={undefined}
          onAccessibilityEscape={undefined}
          onAccessibilityTap={undefined}
          onMagicTap={undefined}
          accessibilityIgnoresInvertColors={undefined}
          textAlign={undefined}
          left={undefined}
          right={undefined}
          allowFontScaling={undefined}
          numberOfLines={undefined}
          maxFontSizeMultiplier={undefined}
          selectionColor={undefined}
          textBreakStrategy={undefined}
          selection={undefined}
          autoCapitalize={undefined}
          autoCorrect={undefined}
          autoFocus={undefined}
          blurOnSubmit={undefined}
          caretHidden={undefined}
          contextMenuHidden={undefined}
          editable={undefined}
          keyboardType={undefined}
          maxLength={undefined}
          multiline={undefined}
          onBlur={undefined}
          onContentSizeChange={undefined}
          onEndEditing={undefined}
          onPressIn={undefined}
          onPressOut={undefined}
          onFocus={undefined}
          onSelectionChange={undefined}
          onSubmitEditing={undefined}
          onTextInput={undefined}
          onScroll={undefined}
          onKeyPress={undefined}
          placeholder={defaultText}
          placeholderTextColor={undefined}
          returnKeyType={undefined}
          secureTextEntry={undefined}
          selectTextOnFocus={undefined}
          inputAccessoryViewID={undefined}
          clearButtonMode={undefined}
          clearTextOnFocus={undefined}
          dataDetectorTypes={undefined}
          enablesReturnKeyAutomatically={undefined}
          keyboardAppearance={undefined}
          passwordRules={undefined}
          rejectResponderTermination={undefined}
          selectionState={undefined}
          spellCheck={undefined}
          textContentType={undefined}
          scrollEnabled={undefined}
          autoComplete={undefined}
          importantForAutofill={undefined}
          disableFullscreenUI={undefined}
          inlineImageLeft={undefined}
          inlineImagePadding={undefined}
          returnKeyLabel={undefined}
          underlineColorAndroid={undefined}
          textAlignVertical={undefined}
          showSoftInputOnFocus={undefined}
          render={undefined}
          error={undefined}
          dense={undefined}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  label: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
  },
  dateInput: {
    height: 35,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 4,
    borderColor: "rgba(0, 0, 0, 0.29)",
    borderWidth: 1,
  },
});
