import { actionTypesItems as actionTypes } from "../constants/actionTypesItems";
import { itemsState } from "../state/items";

const itemsReducer = (state = itemsState, action: any) => {
  switch (action.type) {
    case actionTypes.ListOfItems:
      const isItemsHaveRecords =
        Object.keys(action.payload).length > 0 ? true : false;

      return {
        ...state,
        itemsList: action.payload,
        isItemsListLoaded: isItemsHaveRecords,
      };
    case actionTypes.ListOfNestedItems:
      return {
        ...state,
        nestedItemsList: action.payload,
        isNestedItemsListLoaded: true,
      };
    case actionTypes.IsNestedItemsListLoaded:
      return {
        ...state,
        isNestedItemsListLoaded: action.payload,
      };
    default:
      return state;
  }
};

export default itemsReducer;
