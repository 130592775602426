import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import React, { useState, useEffect } from "react";
import { Image } from "react-native";
import HomeTabNavigator from "./HomeTabNavigator";
import UserSettingsTabNavigator from "../../SharedNavigator/TabNavigator/UserSettingsTabNavigator";
import OrgSettingsTabNavigator from "../../SharedNavigator/TabNavigator/OrgSettingsTabNavigator";
import TabBarIcon from "../../../components/core/TabBarIcon";
import roleConfig from "../../../utils/constants/roles";
import { RootTabScreenProps } from "../../../types";
import { useSelector } from "react-redux";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

const Tab = createBottomTabNavigator();

export default function TopTabNavigator({}) {
  const logoContext = getItemFromSessionStorage("logo-context") as string;
  const [logo, setLogo] = useState<string>(logoContext);
  const { currentRole } = useSelector((state: any) => state.configurations);

  useEffect(() => {
    let isMounted = true;
    setLogo(logoContext);
    return () => {
      isMounted = false;
    };
  }, [logoContext]);

  return (
    <Tab.Navigator initialRouteName="Home">
      <Tab.Screen
        name="UserSettings"
        component={UserSettingsTabNavigator}
        options={({}: RootTabScreenProps<"UserSettings">) => ({
          title: "",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => <TabBarIcon name="user" color={color} />,
        })}
        listeners={{
          tabPress: (e) => {
            // Prevent default action
          },
        }}
      />
      <Tab.Screen
        name="Home"
        component={HomeTabNavigator}
        options={({}: RootTabScreenProps<"Home">) => ({
          title: "",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        })}
      />
      <Tab.Screen
        name="Company"
        component={OrgSettingsTabNavigator}
        options={{
          title: "",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => (
            <Image
              source={{ uri: logo }}
              style={{
                width: 60,
                height: 60,
              }}
              resizeMode="contain"
            />
          ),
        }}
        listeners={{
          tabPress: (e) => {
            currentRole !== roleConfig.roles.owner &&
              currentRole !== roleConfig.roles.admin &&
              e.preventDefault();
          },
        }}
      />
      <Tab.Screen
        name="Refresh"
        component={HomeTabNavigator}
        options={({}: RootTabScreenProps<"Home">) => ({
          title: "",
          headerTitleAlign: "center",
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="refresh" color={color} />
          ),
        })}
        listeners={{
          tabPress: (e) => {
            window.location.reload();
          },
        }}
      />
    </Tab.Navigator>
  );
}
