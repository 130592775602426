import { artworkModel } from "../../models/artworkModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  artworkList: Array<artworkModel>;
  isArtWorkListLoaded: boolean;
  setArtworkFilteredList: Array<artworkModel>;
}

export const artworkState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  artworkList: [],
  isArtWorkListLoaded: false,
  setArtworkFilteredList: [],
};
