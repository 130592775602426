import { SAPI_URL } from "@env";

export default {
  api: {
    domain:
      process.env.NODE_ENV === "development"
        ? "https://testapi.msteps.net"
        : SAPI_URL,
  },
};
