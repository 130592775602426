import { Button, Divider } from "react-native-paper";
import { View, Text } from "../../components/shared/Themed";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useEffect, useState } from "react";
import { TextInputControl } from "../../components/custom/TextInputControl";
import * as Okta from "../../services/oktaAuthService";
import { ReadOnlyProps } from "../../utils/templates";
import appColors from "../../utils/constants/colors";

interface Props {
  setDisplayForgetPasswordScreen: (e: boolean) => void;
  setDisplayLoginWithTendaScreen: (e: boolean) => void;
}

export const LoginWithTandaScreen = ({
  setDisplayForgetPasswordScreen,
  setDisplayLoginWithTendaScreen,
}: ReadOnlyProps<Props>) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [userPasscode, setUserPasscode] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [errorValidationMessage, setErrorValidationMessage] =
    useState<string>("");

  const setPasscode = (passcode: string) => {
    setUserPasscode(userPasscode + passcode);
  };

  useEffect(() => {
    if (userPasscode.length === -1) {
      setErrorValidationMessage("* This is required.");
    } else if (userPasscode.length <= 3 && userPasscode.length > 0) {
      setErrorValidationMessage("* 4-digit passcode is required.");
    } else if (status === "FAILED") {
      setErrorValidationMessage("* Invalid passcode");
    }
  }, [userPasscode, status]);

  const onSubmit = () => {
    setLoading(true);
    if (userPasscode.length === 4) {
      Okta.getAuth(`${userPasscode}@multisteps.com.au`, userPasscode).then(
        (data: any) => {
          setStatus(data.status);
          setLoading(false);
          //Reset the form
          if (data.status !== "FAILED") {
            setUserPasscode("");
          }
        }
      );
    }
  };

  return (
    <>
      <View style={[styles.contentLabel]}>
        <Text style={[styles.boldFont, { fontSize: 20 }]}>
          Login with TANDA
        </Text>
      </View>
      <View style={[styles.px_5]}>
        <TextInputControl
          title="Passcode"
          required={true}
          width="100%"
          placeholder="Enter Passcode"
          value={userPasscode}
          setChangeText={() => {}}
          blurOnSubmit={false}
          isTextEntrySecure={true}
          showSoftInputOnFocus={false}
        />
        <Text style={styles.errorColor}>{errorValidationMessage}</Text>
      </View>
      <View style={[styles.px_5]}>
        <View style={{ flexDirection: "row", marginTop: 5, flexWrap: "wrap" }}>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              color={appColors.multiSteps.background}
              style={{ marginRight: 5 }}
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("1")}
            >
              1
            </Button>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("2")}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              2
            </Button>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("3")}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5 }}
            >
              3
            </Button>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5, flexWrap: "wrap" }}>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("4")}
              color={appColors.multiSteps.background}
              style={{ marginRight: 5 }}
            >
              4
            </Button>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("5")}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              5
            </Button>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("6")}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5 }}
            >
              6
            </Button>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5, flexWrap: "wrap" }}>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("7")}
              color={appColors.multiSteps.background}
              style={{ marginRight: 5 }}
            >
              7
            </Button>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("8")}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              8
            </Button>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("9")}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5 }}
            >
              9
            </Button>
          </View>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5, flexWrap: "wrap" }}>
          {/* <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              onPress={() => {
                const clearPasscode = userPasscode.substring(
                  0,
                  userPasscode.length - 1
                );
                setUserPasscode(clearPasscode);
              }}
              color={appColors.multiSteps.background}
              style={{ marginRight: 5 }}
              uppercase={false}
              disabled={userPasscode.length === 0}
            >
              Clear
            </Button>
          </View> */}
          <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              disabled={userPasscode.length > 3}
              onPress={() => setPasscode("0")}
              color={appColors.multiSteps.background}
            >
              0
            </Button>
          </View>
          {/* <View style={{ flexGrow: 1 }}>
            <Button
              mode="contained"
              onPress={() => onSubmit()}
              color={appColors.multiSteps.background}
              style={{ marginLeft: 5 }}
              uppercase={false}
              disabled={userPasscode.length !== 4}
              loading={isLoading}
            >
              Login
            </Button>
          </View> */}
        </View>
        <Divider style={{ marginBottom: "2%", marginTop: "3%" }} />
        <View style={{ flexGrow: 1 }}>
          <Button
            mode="contained"
            onPress={() => {
              const clearPasscode = userPasscode.substring(
                0,
                userPasscode.length - 1
              );
              setUserPasscode(clearPasscode);
            }}
            color={appColors.multiSteps.background}
            uppercase={false}
            disabled={userPasscode.length === 0}
          >
            Clear
          </Button>
          <Button
            mode="contained"
            onPress={() => onSubmit()}
            color={appColors.multiSteps.background}
            style={{ marginTop: 5 }}
            uppercase={false}
            disabled={userPasscode.length !== 4}
            loading={isLoading}
          >
            Login
          </Button>
        </View>
        <TouchableOpacity
          onPress={() => {
            setDisplayForgetPasswordScreen(false);
            setDisplayLoginWithTendaScreen(false);
          }}
        >
          <Text style={[styles.btnBackToLogin]}>Back to Login</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  contentLabel: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  errorColor: {
    color: "red",
  },
  px_5: { paddingLeft: "5%", paddingRight: "5%" },
  forgetPasswordLink: {
    marginTop: "2%",
    fontStyle: "italic",
    color: "#adacac",
    textDecorationLine: "underline",
  },
  boldFont: {
    fontWeight: "bold",
  },
  btnBackToLogin: {
    marginTop: "2%",
    color: "#adacac",
    textDecorationLine: "underline",
    justifyContent: "center",
    textAlign: "center",
  },
});
