import React, { useState, useEffect, useContext } from "react";
import {
  Picker,
  TouchableOpacity,
  StyleSheet,
  TextInput,
  ScrollView,
} from "react-native";
import { Button, Modal } from "react-native-paper";
import TeamTabNavigator from "../../../navigation/SharedNavigator/TabNavigator/TeamTabNavigator";
import * as Services from "../../../utils/common";
import roleConfig from "../../../utils/constants/roles";
import teamConfig from "../../../utils/constants/teams";
import { Text, View } from "../../../components/shared/Themed";
import {
  getUsersByCompanyId,
  updateUser,
} from "../../../services/usersService";
import { addInvite, sendInvite } from "../../../services/inviteService";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import Spinner from "../../../components/shared/Spinner";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

export default function TeamFrom() {
  let companyContext = JSON.parse(
    getItemFromSessionStorage("company-context") as string
  );
  const [visible, setVisible] = useState<Boolean>(false);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState<String>("");
  const [status, setStatus] = useState<String>("");
  const [selectedUser, setSelectedUser] = useState<Object>({});
  const [selectedTeam, setSelectedTeam] = useState<String>("");
  const [selectedRole, setSelectedRole] = useState<String>(
    roleConfig.roles.member
  );

  const { isSmallDevice } = useContext(DeviceOrientationContext);

  const [modalVisible, setModalVisible] = useState<Boolean>(false);

  const hideDialog = () => {
    setVisible(false);
  };

  const showDialog = (user: Object) => {
    setSelectedUser(user);
    setVisible(true);
  };

  const removeUserRole = () => {
    const roles = selectedUser.roles.filter(
      (r) => r.company_id !== companyContext.company_id
    );
    const company_ids = selectedUser.company_ids.filter(
      (r) => r !== companyContext.company_id
    );
    const body = {
      roles: roles,
      company_ids: company_ids,
    };
    updateUser(body, selectedUser.user_id).then((data) => {
      data.status === "ACTIVE" &&
        getUsersByCompanyId(companyContext.company_id).then((data) => {
          setUsers([data.users]);
        });
    });
    setVisible(false);
  };

  const rolePickerHandler = (itemValue: String) => {
    setSelectedRole(itemValue);
  };

  const teamPickerHandler = (itemValue: String) => {
    setSelectedTeam(itemValue);
  };

  const submitHandler = () => {
    setLoading(true);
    const result = Services.validateEmail(email as string);
    companyContext = JSON.parse(
      getItemFromSessionStorage("company-context") as string
    );

    if (result) {
      addInvite(
        companyContext.company_id as string,
        selectedTeam as string,
        selectedRole as string,
        email as string
      ).then((result) => {
        if (result.status === "ACTIVE") {
          sendInvite(
            companyContext.company_name,
            result.inviteId,
            email as string
          ).then((response) => {
            setStatus(response.status);
            setLoading(false);
          });
        }
      });
    } else {
      setStatus("Invalid email format");
      setLoading(false);
    }
  };

  const clearHandler = () => {
    setStatus("");
    setEmail("");
  };

  const memberItemView = (role: String) => {
    const views = [];
    if (users) {
      users.forEach((user, i) => {
        if (user.roles) {
          const roles = user.roles;
          if (
            roles.some(
              (r) =>
                r.role === role && r.company_id === companyContext.company_id
            )
          ) {
            views.push(
              <View
                key={Services.makeid(4)}
                style={{
                  margin: 20,
                  borderBottomWidth: 0.2,
                  borderColor: "lightgrey",
                  width: "100%",
                }}
              >
                <View style={{ flexDirection: "row", marginBottom: 5 }}>
                  <Text style={styles.label}>
                    {user.first_name} {user.last_name}
                  </Text>
                  <Text style={styles.label2}>{user.email}</Text>
                  <Button
                    style={styles.button2}
                    color="grey"
                    icon="delete-outline"
                    onPress={() => showDialog(user)}
                  />
                </View>
              </View>
            );
          }
        }
      });
      return views;
    }
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (!modalVisible && isMounted) {
        setEmail("");
        setStatus("");
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [modalVisible]);

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={[
        { alignItems: "center", justifyContent: "center" },
      ]}
    >
      <View style={[styles.box, { width: isSmallDevice ? "90%" : "90%" }]}>
        <TeamTabNavigator setModalVisible={setModalVisible} />
      </View>
      <Modal
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        contentContainerStyle={{
          backgroundColor: "white",
          width: isSmallDevice ? "40%" : "90%",
          height: "80%",
          alignSelf: "center",
          justifySelf: "center",
        }}
      >
        <View
          style={{
            height: "12%",
            width: "100%",
            backgroundColor: "#eceef2",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={[styles.title, { textAlign: "left", width: "90%" }]}>
            {" "}
            Invite a user{" "}
          </Text>
        </View>
        <View style={{ height: "100%" }}>
          {isSmallDevice && (
            <Text style={[styles.title3, { width: "80%", margin: 20 }]}>
              Invite a user to be a member of your organization so they can
              access/manage your data depending on the assigned permission
            </Text>
          )}
          <Text style={[styles.label, { color: "black", marginTop: "5%" }]}>
            Email address
          </Text>
          <TextInput
            style={[styles.input, { width: "80%" }]}
            onChangeText={setEmail}
            placeholder="Enter the user email address"
            value={email}
          />
          {companyContext.company_name === "Multisteps" && (
            <View>
              <Text style={[styles.label, { color: "black" }]}>
                Add to team
              </Text>
              <Picker
                style={[styles.input, { color: "#7F00FF", width: "80%" }]}
                selectedRole={selectedTeam}
                onValueChange={(itemValue, itemIndex) =>
                  teamPickerHandler(itemValue)
                }
              >
                <Picker.Item
                  label={teamConfig.teams.external}
                  value={teamConfig.teams.external}
                />
                <Picker.Item
                  label={teamConfig.teams.manager}
                  value={teamConfig.teams.manager}
                />
                <Picker.Item
                  label={teamConfig.teams.sales}
                  value={teamConfig.teams.sales}
                />
                <Picker.Item
                  label={teamConfig.teams.operation}
                  value={teamConfig.teams.operation}
                />
                <Picker.Item
                  label={teamConfig.teams.warehouse}
                  value={teamConfig.teams.warehouse}
                />
              </Picker>
            </View>
          )}
          <Text style={[styles.label, { color: "black" }]}>Add to role</Text>
          <Picker
            style={[styles.input, { color: "#7F00FF", width: "80%" }]}
            selectedRole={selectedRole}
            onValueChange={(itemValue, itemIndex) =>
              rolePickerHandler(itemValue)
            }
          >
            <Picker.Item
              label={roleConfig.roles.member}
              value={roleConfig.roles.member}
            />
            <Picker.Item
              label={roleConfig.roles.owner}
              value={roleConfig.roles.owner}
            />
            <Picker.Item
              label={roleConfig.roles.admin}
              value={roleConfig.roles.admin}
            />
          </Picker>
          {status !== "ACTIVE" && status ? (
            <Text style={{ color: "red", fontWeight: "bold", marginLeft: 20 }}>
              {status}
            </Text>
          ) : (
            <View />
          )}
          {status === "ACTIVE" && status ? (
            <Text style={{ color: "grey", fontWeight: "bold", marginLeft: 20 }}>
              An invitation email is sent
            </Text>
          ) : (
            <View />
          )}
          <TouchableOpacity
            style={styles.button}
            onPress={() =>
              status === "ACTIVE" ? clearHandler() : submitHandler()
            }
          >
            {isLoading ? (
              <Spinner
                styles={{ alignItems: "center", justifyContent: "center" }}
                size="small"
                color="#00ff00"
              />
            ) : (
              <Text style={styles.buttonText}>
                {status === "ACTIVE" ? "Invite another user" : "Invite User"}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </Modal>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    // alignItems: "center",
    // justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: 20,
  },
  button: {
    width: "40%",
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#6495ED",
    margin: 20,
  },
  button2: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "60%",
  },
  buttonText: {
    fontSize: 15,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    width: "50%",
    marginLeft: 20,
    color: "grey",
  },
  label2: {
    fontSize: 14,
    textAlign: "left",
    width: "50%",
    marginLeft: 20,
    color: "grey",
  },
  input: {
    minWidth: "10%",
    maxWidth: 500,
    backgroundColor: "#fff",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: "#ccc",
    borderWidth: 2,
    borderRadius: 15,
    fontSize: 15,
    margin: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 30,
  },
  title2: {
    fontSize: 14,
    textAlign: "center",
    marginBottom: 5,
    color: "#808080",
    borderBottomWidth: 0.2,
    borderColor: "lightgrey",
    marginLeft: "2%",
    width: "100%",
  },
  title3: {
    fontSize: 16,
    textAlign: "left",
    color: "#808080",
  },
});
