import { Bar } from "react-chartjs-2";
import { ReadOnlyProps } from "../../../utils/templates";
import { ChartOptions, ChartTooltipItem, PositionType } from "chart.js";

interface Props {
  chartId: string;
  chartHeight: number;
  legendLocation?: PositionType;
  hasXAxisGridlines?: boolean;
  xAxisLabel: string;
  hasXAxisLabel?: boolean;
  hasYAxisGridlines?: boolean;
  yAxisLabel: string;
  hasYAxisLabel?: boolean;
  isYAxisBeginAtZero?: boolean;
  yAxisStepSize: number;
  hasYAxisOffset?: boolean;
  isAnimate?: boolean;
  dataRecords: Record<string, Array<string> | unknown>;
  chartReference?: React.RefObject<Bar>;
  titleToolTip?: (tooltipItem: Array<ChartTooltipItem>) => string;
}

export const BarChartControl: React.FC<Props> = ({
  chartId,
  chartHeight,
  legendLocation = "bottom",
  hasXAxisGridlines = true,
  xAxisLabel,
  hasXAxisLabel = true,
  hasYAxisGridlines = true,
  yAxisLabel,
  hasYAxisLabel = true,
  isYAxisBeginAtZero = true,
  yAxisStepSize,
  hasYAxisOffset = true,
  isAnimate = true,
  dataRecords,
  chartReference,
  titleToolTip,
}: ReadOnlyProps<Props>) => {
  const options: ChartOptions = {
    responsive: true,
    animation: { ...(!isAnimate ? { duration: 0 } : {}) },
    plugins: {
      legend: {
        position: legendLocation,
      },
    },
    legend: {
      position: legendLocation,
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      ...(titleToolTip
        ? {
            callbacks: {
              title: titleToolTip,
            },
          }
        : {}),
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: hasYAxisGridlines,
          },
          scaleLabel: {
            display: hasYAxisLabel,
            labelString: yAxisLabel,
          },
          ticks: {
            beginAtZero: isYAxisBeginAtZero,
            stepSize: yAxisStepSize,
          },
          offset: hasYAxisOffset,
        },
      ],
      xAxes: [
        {
          gridLines: { display: hasXAxisGridlines },
          scaleLabel: {
            display: hasXAxisLabel,
            labelString: xAxisLabel,
          },
        },
      ],
    },
  };

  return (
    <Bar
      id={chartId}
      ref={chartReference}
      height={chartHeight}
      data={dataRecords}
      redraw={false}
      options={options}
    />
  );
};
