import _ from "lodash";
import { barcodeModal } from "../../../models/barcodeModel";
import {
  formatDateToMongoISOFormat,
  getStartEndDatesForAMonth,
} from "../../../utils/dateUtility";

export const getScanStatsByOneDay = (
  date: Date,
  scanStats: Array<barcodeModal>
) => {
  const filteredTodayScanStats = scanStats.filter((scan: barcodeModal) => {
    return (
      new Date(scan.date).toLocaleDateString().substring(0, 10) ===
      new Date(date).toLocaleDateString().substring(0, 10)
    );
  });

  return filteredTodayScanStats;
};

export const getCurrentMonthScans = (
  currentDate: Date,
  scanStats: Array<barcodeModal>
) => {
  const startEndDates = getStartEndDatesForAMonth(currentDate, true);

  const filteredCurrentMonthScanStats = scanStats.filter(
    (scan: barcodeModal) => {
      const _currentMonthStartDate = new Date(
        `${formatDateToMongoISOFormat(startEndDates.startDate)}T00:00:00.000Z`
      ).getTime();

      const _currentMonthEndDate = new Date(
        `${formatDateToMongoISOFormat(startEndDates.endDate)}T23:59:59.000Z`
      ).getTime();

      const currentDate = new Date(scan.date).getTime();
      return (
        currentDate >= _currentMonthStartDate &&
        currentDate <= _currentMonthEndDate
      );
    }
  );

  return filteredCurrentMonthScanStats;
};

export const convertScannerFeedToModel = (
  data: string,
  userId: string,
  userName: string,
  lineNo?: string
): Array<any> => {
  const barcodeRegex = new RegExp(
    /[A-Z]{1}\d{1,}\.[0-9]{1,}\.[0-9]{2}\.[0-9]{2}[A-Z]{3}[0-9]{5}\.[0-9]{1,}/gm,
    "i"
  );

  const barcodeWithoutMachineCodeRegex = new RegExp(
    /[A-Z]{1}\d{1,}\.[0-9]{1,}\.[0-9]{2}\.[0-9]{1,}/gm,
    "i"
  );

  const splittedBarCodes = data
    .trim()
    .toString()
    .split(",")
    .map((box) => {
      return box.replace(/\n/gi, "");
    });

  const filteredBarCodes = _.filter(splittedBarCodes, _.size) as string[];
  return filteredBarCodes.map((boxBarcode: string) => {
    if (barcodeRegex.test(boxBarcode.trim())) {
      const splittedBoxInfo = boxBarcode.split(".");
      const barcodeModelInfo = {
        employee_id: userId,
        employee_name: userName,
        line_number: splittedBoxInfo[3],
        date: new Date(),
        box_id: `${splittedBoxInfo[0]}.${splittedBoxInfo[1]}.${splittedBoxInfo[2]}.${splittedBoxInfo[4]}`,
      };

      return barcodeModelInfo;
    } else if (barcodeWithoutMachineCodeRegex.test(boxBarcode.trim())) {
      const barcodeModelInfo = {
        employee_id: userId,
        employee_name: userName,
        line_number: lineNo,
        date: new Date(),
        box_id: boxBarcode,
      };

      return barcodeModelInfo;
    }
  });
};

export const lineLabels = (lineLabel: string) => {
  let _label = "";
  switch (lineLabel) {
    case "Line 02PRD10001":
    case "02PRD10001":
      _label = "Line 1";
      break;
    case "Line 02PRD10002":
    case "02PRD10002":
      _label = "Line 2";
      break;
    case "Line 02PRD10003":
    case "02PRD10003":
      _label = "Line 3";
      break;
    default:
      _label = lineLabel;
      break;
  }

  return _label;
};
