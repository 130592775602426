export const headersCustomerStatus = {
  AccountStatus: {
    name: "Account Status",
    width: 1.2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  CardCode: {
    name: "Customer Code",
    width: 1.2,
    textAlign: "left",
  },
  CardName: {
    name: "Customer Name",
    width: 3.0,
    textAlign: "left",
  },
  PymntGroup: {
    name: "Payment Group",
    width: 1.0,
    textAlign: "left",
  },
  CreditLine: {
    name: "Credit Line",
    width: 1.0,
    textAlign: "left",
  },
  Variance: {
    name: "Variance",
    width: 1.0,
    textAlign: "left",
  },
  Balance: {
    name: "Balance",
    width: 1.0,
    textAlign: "left",
  },
  OpenDeliveries: {
    name: "Open Deliveries",
    width: 1.0,
    textAlign: "left",
  },
  CommittedOrders: {
    name: "Committed Orders",
    width: 1.0,
    textAlign: "left",
  },
  SlpName: {
    name: "Sales",
    width: 1.0,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const mdHeadersCustomerStatus = {
  CardName: {
    name: "Customer Name",
    width: 3.0,
    textAlign: "left",
  },
  PymntGroup: {
    name: "Payment Group",
    width: 1.2,
    textAlign: "left",
  },
  CreditLine: {
    name: "Credit Line",
    width: 1.0,
    textAlign: "left",
  },
  Variance: {
    name: "Variance",
    width: 1.0,
    textAlign: "left",
  },
  Balance: {
    name: "Balance",
    width: 1.0,
    textAlign: "left",
  },
  OpenDeliveries: {
    name: "Open Deliveries",
    width: 1.0,
    textAlign: "left",
  },
  CommittedOrders: {
    name: "Committed Orders",
    width: 1.0,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};

export const smHeadersCustomerStatus = {
  CardName: {
    name: "Customer Name",
    width: 3.0,
    textAlign: "left",
  },
  CreditLine: {
    name: "Credit Line",
    width: 1.0,
    textAlign: "left",
  },
  "": { name: "", width: 0.2, textAlign: "" },
};
