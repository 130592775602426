import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { View } from "../../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../../components/custom/Banners/LabelBannerWithSearchBar";
import { LabelBannerWithSearchTags } from "../../../components/custom/Banners/LabelBannerWithSearchTags";
import { DataTableWithPagination } from "../../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../../components/custom/Modal";
import Spinner from "../../../components/shared/Spinner";
import { getPmxDetailsInventory } from "../../../services/inventoryService";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import {
  headersPmxReport,
  mdHeadersPmxReport,
  smHeadersPmxReport,
} from "../utils/pmxInventoryHeaders";
import { validSignedURL } from "../../../utils/validateSignedUrl";
import { pmxDetailModel } from "../../../models/reportsModel";
import { useSelector } from "react-redux";
import { handleTableLoader } from "../../../utils/common";
import { userClaimInformation } from "../../../models/usersModel";
import { sortColumns } from "../../../utils/sortColumns";
import { filterBasedOnAnyKey } from "../../../utils/searchInArrays";
import { useIsFocused } from "@react-navigation/native";

export default function PmxDetailsInventoryReportScreen({
  route,
  navigation,
}: any) {
  const isFocused = useIsFocused();
  const { isLargeDevice, isMediumDevice, isMobileDevice, isSmallDevice } =
    useContext(DeviceOrientationContext);
  const {
    pmxInventoryDetailReport,
    pmxInventoryDetailFilterCategoryList,
    isPmxInventoryDetailReportListLoaded,
  } = useSelector((state: any) => state.reports);
  const { userInfo } = useSelector((state: any) => state.configurations);

  const [selected, setSelected] = useState<pmxDetailModel>(
    {} as unknown as pmxDetailModel
  );

  const [pmxDetailPageLevelList, setPmxDetailPageLevelList] = useState<
    Array<pmxDetailModel>
  >([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headersPmxReport
    );
  const [searchedText, setSearchedText] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);

  const columnsToNotHaveThousandsSeparator: Array<string> = [
    "Batch number",
    "SSCC number",
    "Uom1",
    "Uom2",
  ];

  const [_filterCategory, _setFilterCategory] = useState<string>("all");

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedPmxDetailList: Array<pmxDetailModel> = sortColumns(
      pmxDetailPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setPmxDetailPageLevelList(_sortedPmxDetailList);
  };

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      setSearchedText(searchText);

      const filteredListBasedOnSearchOrCondition: Array<pmxDetailModel> =
        filterBasedOnAnyKey(pmxInventoryDetailReport, searchText);

      const _filterPmxByCategory = filterPmxByCategory(
        filteredListBasedOnSearchOrCondition,
        _filterCategory
      );
      setPmxDetailPageLevelList(recordTransformation(_filterPmxByCategory));
    }

    setTimeout(() => {
      fadeTableLoader();
    }, 1000);
  };

  const recordTransformation = (data: Array<pmxDetailModel>) => {
    const pmxData: Array<pmxDetailModel> = [];
    data.forEach((item: pmxDetailModel) => {
      pmxData.push({
        ...item,
        BatchNumber1: item.BatchNumber1 === null ? "N/A" : item.BatchNumber1,
        SsccNumber: item.SsccNumber === null ? "N/A" : item.SsccNumber,
        QuantityUom:
          item.QuantityUom === null
            ? "0"
            : `${(item.QuantityUom as unknown as number).toLocaleString()}`,
        QuantityUom2:
          item.QuantityUom2 === null
            ? "0"
            : `${(item.QuantityUom2 as unknown as number).toLocaleString()}`,
      });
    });

    return pmxData;
  };

  const filterPmxByCategory = (
    _pmxsReport: Array<pmxDetailModel>,
    _filterCategory: string
  ) => {
    return _pmxsReport.filter((pmx: pmxDetailModel) => {
      switch (_filterCategory) {
        case "NSW":
          return pmx.Warehouse === "01";
          break;
        case "VIC":
          return pmx.Warehouse === "04";
          break;
        case "QLD":
          return ["02", "14"].includes(pmx.Warehouse);
          break;
        case "TAS":
          return ["06", "16", "12"].includes(pmx.Warehouse);
          break;
        case "WA":
          return pmx.Warehouse === "05";
          break;
        default:
          return pmx;
          break;
      }
    });
  };

  useEffect(() => {
    let isMounted: boolean = true;
    const _userInfo = userInfo as userClaimInformation;

    // Avoid keep refreshing table when item is selected
    if (isMounted && modalVisible === false) {
      if (pmxDetailPageLevelList || isRefresh) {
        displayTableLoader();

        _setFilterCategory("all");
        setSearchedText("");

        getPmxDetailsInventory(
          "",
          _userInfo.team,
          _userInfo.zone_info || "",
          tableHeaders
        );
      }
    }

    if (Object.keys(selected).length !== 0) {
      validSignedURL(
        selected.ItemCode,
        "label",
        setImageLoading,
        setSelectedImage
      );
    }

    setTableHeaders(
      isLargeDevice
        ? headersPmxReport
        : isMediumDevice
        ? mdHeadersPmxReport
        : smHeadersPmxReport
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [isFocused, selected, isRefresh]);

  useEffect(() => {
    setImageLoading(true);
  }, [modalVisible]);

  useEffect(() => {
    setPmxDetailPageLevelList(recordTransformation(pmxInventoryDetailReport));

    setTimeout(() => {
      fadeTableLoader();
    }, 2000);
  }, [pmxInventoryDetailReport]);

  useEffect(() => {
    displayTableLoader();

    const _pmxsReport = recordTransformation(
      pmxInventoryDetailReport
    ) as Array<pmxDetailModel>;
    const filteredListBasedOnSearchOrCondition =
      searchedText.trim().length > 0
        ? filterBasedOnAnyKey(_pmxsReport, searchedText)
        : _pmxsReport;

    setPmxDetailPageLevelList(
      filterPmxByCategory(filteredListBasedOnSearchOrCondition, _filterCategory)
    );

    fadeTableLoader();
  }, [_filterCategory]);

  const ModalContent = () => {
    const _excludedKeys = [
      "ItemCode",
      "StorLocCode",
      "QuantityStatus",
      "InvntryUom",
      "Uom2",
    ];

    const _orderedKeys = [
      "ItemName",
      "BatchNumber1",
      "SsccNumber",
      "Warehouse",
      "StorLocName",
      "QuantityUom",
      "QuantityUom2",
    ];

    const _specialLabels = {
      StorLocName: {
        oldLabel: "StorLocName",
        newLabel: "Aisle",
      },
      QuantityUom: {
        oldLabel: "QuantityUom",
        newLabel: "Piece",
      },
      QuantityUom2: {
        oldLabel: "QuantityUom2",
        newLabel: "Box",
      },
    };

    return (
      <Modal.ModalContent
        title={selected.ItemCode || ""}
        icon="folder"
        uri={selectedImage}
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headersPmxReport}
            orderedKeys={_orderedKeys}
            specialLabels={_specialLabels}
            columnsToNotHaveThousandSeparator={[
              "BatchNumber1",
              "SsccNumber",
              "QuantityUom",
              "QuantityUom2",
            ]}
          />
        }
      />
    );
  };

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={[styles.container, { backgroundColor: "#f2f2f2" }]}>
        <View
          style={[
            styles.containerContent,
            {
              width: "100%",
            },
          ]}
        >
          {isPmxInventoryDetailReportListLoaded ? (
            <View>
              <LabelBannerWithSearchBar
                titleName={`Total Items: ${pmxDetailPageLevelList.length}`}
                handleSearch={handleSearch}
                setRefresh={setRefresh}
                dataToExport={pmxDetailPageLevelList}
                fileName={"inventory_details_report"}
              />
              {userInfo.team === "operation" && (
                <LabelBannerWithSearchTags
                  isMobileDevice={isMobileDevice}
                  isSmallDevice={isSmallDevice}
                  label="Quick Filter"
                  filterCategoryList={pmxInventoryDetailFilterCategoryList}
                  filterCategory={_filterCategory}
                  setFilterCategory={_setFilterCategory}
                />
              )}
              <DataTableWithPagination
                headers={tableHeaders}
                rows={pmxDetailPageLevelList}
                itemsPerPage={100}
                useModal={true}
                isRowsExpandable={false}
                setModalVisible={setModalVisible}
                setSelected={setSelected}
                isDetail={true}
                isDataLoading={isSearched}
                columnsToNotHaveThousandSeparator={
                  columnsToNotHaveThousandsSeparator
                }
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={ModalContent}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    // marginTop: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
