import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import * as React from "react";
import { ColorSchemeName } from "react-native";
import * as Linking from "expo-linking";
import RootStackNavigator from "./SharedNavigator/RootNavigator/RootStackNavigator";
import LinkingConfiguration from "./LinkingConfiguration";
import { setItemInSessionStorage } from "../services/utils/storageService";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  Linking.getInitialURL().then((data) => {
    if (data && data.includes("invites/")) {
      setItemInSessionStorage("invite-context", data);
    }
  });

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DefaultTheme : DefaultTheme}
    >
      <RootStackNavigator />
    </NavigationContainer>
  );
}
