import { decrypt, encrypt } from "../../utils/crypto";

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const setItemInSessionStorage = (key: string, value: any) => {
  const encryptedValue = encrypt(value);
  sessionStorage.setItem(key, encryptedValue);
};

export const getItemFromSessionStorage = (key: string) => {
  const encryptedValue = sessionStorage.getItem(key);
  return encryptedValue && encryptedValue !== null
    ? decrypt(encryptedValue)
    : null;
};

export const removeItemFromSessionStorage = (key: string) => {
  sessionStorage.removeItem("key");
};
