import React, { useEffect, useState } from "react";
import { Text, View } from "../shared/Themed";
import { StyleSheet } from "react-native";
import Icons from "@expo/vector-icons/MaterialCommunityIcons";
import { ResponseSelectBox } from "../../models/response";

import DropDownPicker, { ItemType } from "react-native-dropdown-picker";
import { ReadOnlyProps } from "../../utils/templates";

interface Props {
  Items:
    | Array<Record<string, string>>
    | Array<string | number>
    | ItemType<string>[];
  required: boolean;
  title: string;
  placeholder: string;
  width: number | string;
  defaultOption?: ResponseSelectBox | string | null;
  onSelectedValue: (event: any) => void;
}

export const SelectBoxControl: React.FC<ReadOnlyProps<Props>> = (
  props: ReadOnlyProps<Props>
) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.defaultOption);
  const [items, setItems] = useState(props.Items);

  useEffect(() => {
    if (props.defaultOption) {
      setValue(props.defaultOption);
    } else {
      setValue("");
    }
  }, [props.defaultOption]);

  return (
    <View
      style={{ flexDirection: "column", width: props.width, marginTop: 15 }}
    >
      <View style={{ flexDirection: "row" }}>
        <Text style={[styles.label, { color: "black", marginBottom: "5px" }]}>
          {props.title}
        </Text>
        {props.required && <Icons name="star" size={10} color="red" />}
      </View>
      <View>
        <DropDownPicker
          schema={{
            label: "value",
            value: "key",
          }}
          open={open}
          value={value as string}
          setValue={setValue}
          items={items as ItemType<string>[]}
          setItems={setItems}
          setOpen={setOpen}
          onSelectItem={(val: any) => {
            props.onSelectedValue(val["key"]);
          }}
          placeholder={props.placeholder}
          placeholderStyle={styles.placeholderStyles}
          listMode="SCROLLVIEW"
          style={styles.dropdown}
          dropDownContainerStyle={styles.dropdownContainerStyle}
          showTickIcon={true}
          listItemContainerStyle={styles.listItemContainerStyle}
          listItemLabelStyle={styles.listItemLabelStyle}
          tickIconStyle={styles.tickIconStyle}
          itemSeparator={true}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
  },
  dropdown: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "#B7B7B7",
    height: 35,
    padding: "8px",
    display: "flex",
    flexDirection: "row",
  },
  placeholderStyles: {
    color: "grey",
  },
  dropdownContainerStyle: {
    zIndex: 10000,
    position: "initial",
    display: "flex",
    flexDirection: "row",
    borderRadius: 3,
  },
  listItemContainerStyle: {
    display: "flex",
    flexDirection: "row",
  },
  listItemLabelStyle: {
    padding: "2%",
  },
  tickIconStyle: {
    marginTop: "5px",
    marginRight: "5px",
    color: "rgb(47, 149, 220)",
  },
});
