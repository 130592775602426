import { useContext, useEffect, useRef, useState } from "react";
import { View } from "../../../components/shared/Themed";

import { ChartTooltipItem } from "chart.js";
import { formatDateToMongoISOFormat } from "../../../utils/dateUtility";
import { scanPerLineMonthlyModal } from "../../../models/barcodeModel";
import { ReadOnlyProps } from "../../../utils/templates";
import _ from "lodash";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { NoDataBanner } from "../../../components/shared/NoDataBanner";
import { LineChartControl } from "../../../components/custom/Charts/LineChartControl";
import { Line } from "react-chartjs-2";
import { getScanPerLineMonthlyStats } from "../../../services/barcodeService";
import Spinner from "../../../components/shared/Spinner";

interface Props {
  selectedScanDate: Date;
  startDate: string;
  endDate: string;
  operatorName: string;
  isRefresh: boolean;
}

export const ScanPerLineMonthly = ({
  selectedScanDate,
  startDate,
  endDate,
  operatorName,
  isRefresh,
}: ReadOnlyProps<Props>) => {
  const chartRef = useRef<Line>(null);
  const isMountedRef = useRef<boolean>();
  const { isSmallDevice, isMobileDevice } = useContext(
    DeviceOrientationContext
  );

  const [processedScanStats, setProcessedScanStats] =
    useState<scanPerLineMonthlyModal>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isSectionLoadedBefore, setIsSectionLoadedBefore] =
    useState<boolean>(false);

  const data = {
    labels: processedScanStats?.labels,
    datasets: processedScanStats?.currentMonthScanDataset,
  };

  const titleToolTip = (tooltipItem: Array<ChartTooltipItem>) => {
    return `Day ${tooltipItem[0].label as string}`;
  };

  const fetchScanPerLineMonthly = async () => {
    try {
      const scanResults = await getScanPerLineMonthlyStats(
        startDate,
        endDate,
        formatDateToMongoISOFormat(selectedScanDate),
        operatorName
      );

      setProcessedScanStats(scanResults.data);
      setIsDataLoading(scanResults.isLoading);
    } catch (error) {
      setProcessedScanStats({} as unknown as scanPerLineMonthlyModal);
      setIsDataLoading(false);

      console.log(
        "onDateChange in ScanPerLineMonthly section the stats loading method failed of the following reason: ",
        error
      );
    }
  };

  useEffect(() => {
    if (isSectionLoadedBefore) {
      //Destory the chart
      if (chartRef.current && chartRef.current !== null) {
        chartRef.current.chartInstance.clear();
      }

      setIsDataLoading(true);
      fetchScanPerLineMonthly();
    }
  }, [selectedScanDate, operatorName, isRefresh]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchScanPerLineMonthly();
    setIsSectionLoadedBefore(true);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <View style={{ marginTop: "3%", paddingRight: "3%", paddingLeft: "3%" }}>
      {!isDataLoading ? (
        processedScanStats &&
        processedScanStats?.currentMonthScanDataset?.length > 0 ? (
          <LineChartControl
            chartId="totalScanLineChart"
            chartHeight={isSmallDevice || isMobileDevice ? 300 : 170}
            xAxisLabel="Per month"
            hasXAxisGridlines={false}
            yAxisLabel="Total Scans"
            yAxisStepSize={50}
            dataRecords={data}
            titleToolTip={titleToolTip}
            chartReference={chartRef}
            isAnimate={isMobileDevice || isSmallDevice ? false : true}
            isLineSmoothCornered={
              isMobileDevice || isSmallDevice ? false : true
            }
          />
        ) : (
          <NoDataBanner />
        )
      ) : (
        <Spinner
          size="small"
          color={"#00ff00"}
          styles={{ flexDirection: "row", margin: "20%" }}
        />
      )}
    </View>
  );
};
