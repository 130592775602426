import { DeviceOrientation as DOrientation } from "../../../models/deviceOrientationModel";
import { useState } from "react";
import { Dimensions } from "react-native";

export const DeviceOrientation = () => {
  const defaultDeviceOrientation = {
    isLargeDevice: Dimensions.get("window").width >= 1450,
    isMediumDevice:
      Dimensions.get("window").width < 1450 &&
      Dimensions.get("window").width >= 1145,
    isSmallDevice:
      Dimensions.get("window").width < 1145 &&
      Dimensions.get("window").width > 200,
    isMobileDevice:
      Dimensions.get("window").width < 600 &&
      Dimensions.get("window").width > 200,
  };

  const [deviceOrientation, setDeviceOrientation] = useState<DOrientation>(
    defaultDeviceOrientation
  );

  Dimensions.addEventListener("change", () => {
    try {
      setDeviceOrientation(defaultDeviceOrientation);
    } catch (e) {
      console.log(`Dimensions event error: ${e}`);
    }
  });

  return deviceOrientation;
};
