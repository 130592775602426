import React, { useState, useEffect, useRef, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { FAB } from "react-native-paper";
import { useIsFocused } from "@react-navigation/native";
import { View } from "../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../components/custom/Modal/index";
import Spinner from "../../components/shared/Spinner";
import useColorScheme from "../../hooks/useColorScheme";
import oktaConfig from "../../configs/okta";
import _ from "lodash";

import {
  deleteContainerById,
  getAllContainers,
} from "../../services/containersService";
import { headers, mdHeaders, smHeaders } from "./utils/containerHeaders";
import {
  nestedHeaders as itemsHeader,
  mdNestedHeaders as itemsMdHeaders,
  smNestedHeaders as itemsSmHeaders,
} from "./utils/itemsHeader";
import { containersModal } from "../../models/containersModel";
import {
  compareArraysToHaveAnIdenticalArray,
  filterBasedOnAnyKey,
} from "../../utils/searchInArrays";
import { getAllItems } from "../../services/itemsService";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { useToast } from "react-native-toast-notifications";
import { useSelector } from "react-redux";
import { handleTableLoader } from "../../utils/common";
import { itemsModal } from "../../models/itemsModel";
import { sortColumns } from "../../utils/sortColumns";
import { ContainersDispatcher } from "../../redux/dispatcher/containersDispatcher";
import { actionTypesContainers } from "../../redux/constants/actionTypesContainers";
import { LabelBannerWithSearchTags } from "../../components/custom/Banners/LabelBannerWithSearchTags";
import { ContainerSearchDisplaySwitch } from "./utils/ContainerSearchDisplaySwitch";

export default function ContainerManagementScreen({ navigation }: any) {
  const toast = useToast();
  const scrollRef = useRef<ScrollView>(null);
  const isFocused = useIsFocused();
  const colorScheme = useColorScheme();

  const columnsToNotHaveThousandsSeparator: Array<string> = ["Packing #"];
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [containersPageLevelList, setContainersPageLevelList] = useState<
    Array<containersModal>
  >([]);

  const { isLargeDevice, isMediumDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );

  const { userRoles } = useSelector((state: any) => state.configurations);
  const { allowedRolesForContainers, containersList, isContainersListLoaded } =
    useSelector((state: any) => state.containers);

  const { itemsList } = useSelector((state: any) => state.items);

  const isUserAdmin = userRoles.some((role: string) =>
    allowedRolesForContainers.includes(role)
  );

  const [selected, setSelected] = useState<containersModal>(
    {} as unknown as containersModal
  );

  //Table & Nested Table Headers
  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headers
    );

  const [nestedTableHeaders, setNestedTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      itemsHeader
    );

  const [_isDisplayViewOn, _setIsDisplayViewOn] = useState(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const [, setContentOffSet] = useState<number>(0);
  const [isFabOpen, setFabOpen] = useState<Record<string, boolean>>({
    open: false,
  });

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedContainerList: Array<containersModal> = sortColumns(
      containersPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setContainersPageLevelList(_sortedContainerList);
  };

  /**
   * TODO: NESTED TABLE SORTED Is Disabled,
   * enable when sorting on nested table feature is requested
   */
  // const onHandleNestedSortColumn = (
  //   columnKey: string,
  //   columnParentIdentifier: string
  // ) => {
  //   const _sortedItemsList: Array<itemsModal> = sortColumns(
  //     nestedItemsPageLevelList,
  //     columnKey,
  //     nestedTableHeaders,
  //     setNestedTableHeaders
  //   );

  //   setNestedItemsPageLevelList(_sortedItemsList);
  // };

  const onHandleScroll = (event: any) => {
    setContentOffSet(Math.floor(event.nativeEvent?.contentOffset?.y) ?? 0);
  };

  const onFabOpenChange = ({ open }: { open: boolean }) => setFabOpen({ open });

  const onHandleRowClick = (row: containersModal) => {
    navigation.navigate("ManageContainers", {
      screen: "ManageContainers",
      mode: "update",
      container: JSON.stringify(row),
    });
  };

  const displayTableLoader = (setIsSearched: (e: boolean) => void) => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = (setIsSearched: (e: boolean) => void) => {
    handleTableLoader(false, true, setIsSearched);
  };

  const onHandleRemoveRow = (containerId: string) => {
    const _containersList = containersPageLevelList;
    const alteredContainersList = _containersList.filter(
      (container) => container.container_number !== containerId
    );

    ContainersDispatcher(
      actionTypesContainers.ListOfContainers,
      alteredContainersList
    );
  };

  const onHandleDeleteRow = (containerId: string | number) => {
    if (confirm("Do you want to delete this container?")) {
      displayTableLoader(setIsSearched);

      deleteContainerById(containerId as string).then((response) => {
        if (response?.isSuccessfull) {
          onHandleRemoveRow(containerId as string);
          toast.show("Container deleted successfully", {
            type: "success",
          });
        } else {
          toast.show("Error: container not deleted. Try again", {
            type: "danger",
          });
        }
      });

      fadeTableLoader(setIsSearched);
    }
  };

  const onHandleSearch = (searchText: string) => {
    displayTableLoader(setIsSearched);

    let searchedContainers: Array<containersModal> = [];
    if (searchText.trim() !== "") {
      /**
       * When SearchBy: Containers, enter into if block
       * otherwise go for else block
       */
      if (!_isDisplayViewOn) {
        const filteredContainers = filterBasedOnAnyKey(
          extractIdenticalContainers(),
          searchText
        );

        searchedContainers = feedItemsInContainers(filteredContainers);
      } else {
        /**
         * Steps to search for items within containers
         * 1: Extract all items from containers & union them
         * 2: Search by keyword in the list of items extracted from containers
         * 3: Group by container_number on the searchedItems
         * 4: Remove the duplicates from array of container_numbers
         * 5: filter the unique containers from the identicalFeededContainersWithItems()
         *    & return the list of containers with items matching the search text
         */

        //Step 1
        const itemsFromContainers = _.map(
          identicalFeededContainersWithItems(),
          "items"
        );
        const flattenItems = _.flatten(itemsFromContainers);

        //Step 2
        const searchedItems = filterBasedOnAnyKey(flattenItems, searchText);

        //Step 3
        const groupedContainers = Object.keys(
          _.groupBy(searchedItems, "container_number")
        );

        //Step 4
        const uniqueContainers = _.uniq(groupedContainers);

        //Step 5
        const filteredContainers = identicalFeededContainersWithItems().filter(
          (container) => uniqueContainers.includes(container.container_number)
        );

        searchedContainers = filteredContainers;
      }

      setContainersPageLevelList(searchedContainers);
    }

    setTimeout(() => {
      fadeTableLoader(setIsSearched);
    }, 2000);
  };

  const onHandleScrollToBase = () => {
    scrollRef.current?.scrollTo({
      x: 0,
      y: 0,
      animated: true,
    });
  };

  const ModalContent = () => {
    const _excludedKeys = [
      "_id",
      "__v",
      "created_Date",
      "created_by",
      "container_number",
      "items",
    ];

    return (
      <Modal.ModalContent
        title={selected.container_number}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headers}
          />
        }
      />
    );
  };

  const extractIdenticalContainers = () => {
    //process both containers and items list to return filtered containers list
    const _itemsList: Array<itemsModal> = itemsList;
    const groupContainers = _.groupBy(
      _itemsList,
      (item) => item.container_number
    );

    return compareArraysToHaveAnIdenticalArray(
      "container_number",
      containersList,
      Object.keys(groupContainers)
    ) as Array<containersModal>;
  };

  const feedItemsInContainers = (containers: containersModal[]) => {
    const _itemsList: Array<itemsModal> = [...itemsList];

    return containers.map((container) => {
      return {
        ...container,
        items: _itemsList.filter(
          (item: itemsModal) =>
            item.container_number === container.container_number
        ),
      };
    });
  };

  const identicalFeededContainersWithItems = (): Array<containersModal> => {
    return feedItemsInContainers(extractIdenticalContainers());
  };

  useEffect(() => {
    setRefresh(true);
  }, [_isDisplayViewOn]);

  useEffect(() => {
    let isMounted: boolean = true;

    if (isMounted) {
      //To restrict fetching data from endpoint, if data is available in store
      if (containersPageLevelList.length === 0 || isRefresh) {
        displayTableLoader(setIsSearched);

        //Loading containers and items so to apply inner join on them so to filter out containers list based on items
        getAllContainers(tableHeaders);
        getAllItems(nestedTableHeaders);
      }
    }

    setTableHeaders(
      isLargeDevice ? headers : isMediumDevice ? mdHeaders : smHeaders
    );
    setNestedTableHeaders(
      isLargeDevice
        ? itemsHeader
        : isMediumDevice
        ? itemsMdHeaders
        : itemsSmHeaders
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [isFocused, isRefresh]);

  useEffect(() => {
    setContainersPageLevelList(identicalFeededContainersWithItems());

    setTimeout(() => {
      fadeTableLoader(setIsSearched);
    }, 2000);
  }, [containersList, itemsList]);

  return (
    <ScrollView
      style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      onScroll={onHandleScroll}
      ref={scrollRef}
    >
      <View
        style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
      >
        <View
          style={[
            styles.container,
            {
              backgroundColor: colorScheme === "light" ? "#f2f2f2" : "#f2f2f2",
            },
          ]}
        >
          <View
            style={[
              styles.containerContent,
              {
                width: isLargeDevice
                  ? "100%"
                  : isMediumDevice
                  ? "100%"
                  : "100%",
              },
            ]}
          >
            {isContainersListLoaded ? (
              <View>
                <FAB.Group
                  open={isFabOpen.open}
                  style={[
                    styles.fab,
                    {
                      paddingRight: isSmallDevice
                        ? "80%"
                        : isLargeDevice
                        ? "93%"
                        : "90%",
                    },
                  ]}
                  icon={isFabOpen.open ? "plus" : "arrow-up"}
                  actions={[
                    {
                      icon: "plus",
                      onPress: () => {
                        window.location.href = isUserAdmin
                          ? `${oktaConfig.api.redirectUri}/containers/manage`
                          : "";
                      },
                    },
                    {
                      icon: "arrow-up",
                      onPress: onHandleScrollToBase,
                    },
                  ]}
                  visible={false}
                  onStateChange={onFabOpenChange}
                  onPress={() => {
                    if (isFabOpen.open) {
                      // do something if the speed dial is open
                    }
                  }}
                />
                <LabelBannerWithSearchBar
                  titleName={`Total Containers: ${containersPageLevelList.length}`}
                  handleSearch={onHandleSearch}
                  setRefresh={setRefresh}
                  isResetFields={isRefresh}
                />
                <LabelBannerWithSearchTags
                  childern={
                    <ContainerSearchDisplaySwitch
                      title="Search by"
                      isDisplayViewOn={_isDisplayViewOn}
                      setIsDisplayViewOn={_setIsDisplayViewOn}
                    />
                  }
                />
                <DataTableWithPagination
                  headers={tableHeaders}
                  rows={containersPageLevelList}
                  itemsPerPage={20}
                  isDataLoading={isSearched}
                  useModal={true}
                  nestedHeader={nestedTableHeaders}
                  isRowsExpandable={true}
                  setModalVisible={setModalVisible}
                  handleRowClick={onHandleRowClick}
                  setSelected={setSelected}
                  isToExpandAllRows={_isDisplayViewOn}
                  rowKeyValue="container_number"
                  nestedItemKeyValue="items"
                  isDetail={true}
                  isEdit={isUserAdmin}
                  isDelete={isUserAdmin}
                  columnsToNotHaveThousandSeparator={
                    columnsToNotHaveThousandsSeparator
                  }
                  handleDeleteRow={onHandleDeleteRow}
                  handleSortColumn={onHandleSortColumn}
                />
              </View>
            ) : (
              <View>
                <Spinner
                  size="small"
                  color="red"
                  styles={{ flexDirection: "row", margin: "20%" }}
                />
              </View>
            )}
          </View>
        </View>
        <Modal.ModalContainer
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          childern={ModalContent}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexGrow: 1,
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  fab: {
    bottom: -10,
    position: "absolute",
    zIndex: 1,
  },
});
