import React, { useState, useEffect } from "react";
import { Platform, TouchableOpacity, StyleSheet, Image } from "react-native";
import * as ImagePicker from "expo-image-picker";
import { Text, View } from "../../../components/shared/Themed";
import useColorScheme from "../../../hooks/useColorScheme";
import roleConfig from "../../../utils/constants/roles";
import { getRole } from "../../../services/usersService";
import { getS3SignUrl, pushPhotoToS3 } from "../../../services/s3BucketService";
import Spinner from "../../../components/shared/Spinner";
import { getItemFromSessionStorage } from "../../../services/utils/storageService";

export default function BrandingForm() {
  const colorScheme = useColorScheme();
  const companyContext = JSON.parse(
    getItemFromSessionStorage("company-context") as string
  );

  const logoContext = getItemFromSessionStorage("logo-context") as string;
  const [text, setText] = useState<String>("");

  const [image, setImage] = useState<String>(logoContext);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const [companyId, setCompanyId] = useState<String>(
    companyContext ? companyContext.company_id : null
  );
  const [currentRole, setCurrentRole] = useState<String>("");
  const [imageBase64, setImageBase64] = useState<String>("");
  let disabled = false;
  if (companyContext) {
    disabled = currentRole === roleConfig.roles.owner ? false : true;
  }
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.3,
      base64: true,
      exif: false,
    });

    if (!result.cancelled) {
      setImageBase64(result.base64 as string);
      setImage(result.uri);
    }
  };

  const getImage = async () => {
    setLoading(true);
    companyId &&
      getS3SignUrl(
        "multisteps-go-public-image",
        `logo/${companyId}.png`,
        "getObject",
        "image/*"
      ).then((data) => {
        if (data?.signedUrl) {
          setImage(data.signedUrl);
        }
        setLoading(false);
      });
  };

  const uploadImage = async () => {
    companyId &&
      getS3SignUrl(
        "multisteps-go-public-image",
        `logo/${companyId}.png`,
        "putObject",
        "image/*"
      ).then((data) => {
        setLoading(true);
        pushPhotoToS3(data?.signedUrl, "PUT", imageBase64, "image/*").then(
          (data) => {
            setLoading(false);
            getImage();
          }
        );
      });
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (Platform.OS !== "web" && isMounted) {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert(
            "Sorry, Camera roll permissions are required to make this work!"
          );
        }
      }
    })();
    getRole(companyContext.company_id).then((mapiData) => {
      setCurrentRole(mapiData?.role);
    });
    companyContext && setCompanyId(companyContext.company_id);
    logoContext && setImage(logoContext);

    return () => {
      isMounted = false;
    };
  }, [companyContext, logoContext]);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: colorScheme === "light" ? "white" : "white" },
      ]}
    >
      <View style={styles.box}>
        <Text style={styles.title}>Company Logo</Text>
        <View style={styles.logoBox}>
          <Image
            source={{ uri: image }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        {!disabled && (
          <View style={styles.subBox}>
            <TouchableOpacity style={styles.logoButton} onPress={pickImage}>
              <Text style={styles.logoButtontext}>Upload Logo</Text>
            </TouchableOpacity>
            <Text style={styles.greyLabel}>
              JPG, GIF or PNG. Max size of 5MB
            </Text>
          </View>
        )}
        <Text
          style={{
            borderTopWidth: 1,
            borderTopColor: "#808080",
            borderTopStyle: "solid",
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
          }}
        ></Text>
        <View style={{ flexDirection: "row" }}>
          {!disabled && (
            <TouchableOpacity style={styles.button} onPress={uploadImage}>
              <Text style={styles.buttonText}>Save changes</Text>
            </TouchableOpacity>
          )}
          {isLoading && (
            <Spinner styles={styles.spinner} size="small" color="#00ff00" />
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
    margin: 20,
    width: 400,
  },
  subBox: {
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 40,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#6495ED",
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  logoBox: {
    width: "95%",
    borderWidth: 1,
    elevation: 2,
    backgroundColor: "white",
    borderColor: "#D3D3D3",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
  },
  logo: {
    width: 250,
    height: 250,
    resizeMode: "center",
  },
  title: {
    fontSize: 18,
    marginTop: 12,
    color: "#808080",
    fontWeight: "bold",
    marginLeft: 14,
  },
  logoButton: {
    alignItems: "center",
    justifyContent: "center",
    width: 150,
    height: 40,
    borderRadius: 20,
    borderColor: "#0000FF",
    borderWidth: 1,
    elevation: 2,
    backgroundColor: "white",
  },
  logoButtontext: {
    fontSize: 14,
    lineHeight: 10,
    letterSpacing: 0.25,
    color: "#0000FF",
  },
  greyLabel: {
    fontSize: 12,
    textAlign: "left",
    lineHeight: 10,
    letterSpacing: 0.25,
    color: "#808080",
    fontWeight: "bold",
    marginRight: 12,
    marginTop: 12,
  },
  spinner: {
    marginLeft: 12,
  },
});
