import sapiConfig from "../configs/sapi";
import { pmxDetailModel, pmxOverviewModel } from "../models/reportsModel";
import { actionTypesReports } from "../redux/constants/actionTypesReports";
import { ReportsDispatcher } from "../redux/dispatcher/reportsDispatcher";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = sapiConfig.api.domain;

const getLocation = (location: string) => {
  return location.replace("Australia/", "");
};

export const getPmxInventory = async (
  searchText: string,
  team: string,
  location: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  let pmxResult: Array<pmxOverviewModel> = [];
  const methodPath = `/report/pmx_inventory_report?${getLocation(
    location
  )}&${team.toLowerCase()}&${searchText}`;

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      pmxResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      ) as Array<pmxOverviewModel>;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ReportsDispatcher(actionTypesReports.ListOfPmxInventory, pmxResult);
};

export const getPmxDetailsInventory = async (
  searchText: string,
  team: string,
  location: string,
  tableHeaders: Record<string, Record<string, string | number | boolean>>
) => {
  let pmxDetailResult: Array<pmxDetailModel> = [];
  const methodPath = `/report/pmx_details_inventory_report?${getLocation(
    location
  )}&${team.toLowerCase()}&${searchText}`;

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      pmxDetailResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data.recordset
      ) as Array<pmxDetailModel>;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ReportsDispatcher(
    actionTypesReports.ListOfPmxInventoryDetail,
    pmxDetailResult
  );
};
