import {
  stockModel,
  pmxOverviewModel,
  pmxDetailModel,
  productionStockModel,
  rawMaterialStockOverviewModel,
  rawMaterialStockModel,
  customerStatusModel,
} from "../../models/reportsModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  //Stock Report
  stockReportList: Array<stockModel>;
  isStockReportListLoaded: boolean;
  stockReportFilterCategoryList: Array<string>;
  lastRefreshStockReport: string;

  //Search Product Report
  productReportList: Array<stockModel>;
  isProductReportListLoaded: boolean;
  productReportFilterCategoryList: Array<string>;
  lastRefreshProductReport: string;

  //Customer Status Report
  customerStatusReport: Array<customerStatusModel>;
  isCustomerStatusReportListLoaded: boolean;

  //PMX Inventory Report
  pmxInventoryReport: Array<pmxOverviewModel>;
  isPmxInventoryReportListLoaded: boolean;

  //PMX Inventory Detail Report
  pmxInventoryDetailReport: Array<pmxDetailModel>;
  pmxInventoryDetailFilterCategoryList: Array<string>;
  isPmxInventoryDetailReportListLoaded: boolean;

  //Production Stock Report
  productionStockReport: Array<productionStockModel>;
  isProductionStockReportListLoaded: boolean;

  //Raw Material OverView
  rawMaterialOverviewReport: Array<rawMaterialStockOverviewModel>;
  isRawMaterialOverviewReportListLoaded: boolean;

  //Raw Material Stock
  rawMaterialStockReport: Array<rawMaterialStockModel>;
  isRawMaterialStockReportListLoaded: boolean;
}

export const reportsState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  stockReportList: [],
  isStockReportListLoaded: false,
  lastRefreshStockReport: "",
  stockReportFilterCategoryList: [
    "all",
    "produce",
    "berry",
    "meat",
    "meals",
    "salad",
    "bakery",
    "NSW",
    "VIC",
    "QLD",
    "WA",
    "SA",
    "TAS",
  ],
  productReportList: [],
  isProductReportListLoaded: false,
  lastRefreshProductReport: "",
  productReportFilterCategoryList: [
    "all",
    "produce",
    "berry",
    "meat",
    "meals",
    "salad",
    "bakery",
  ],
  //Customer Status Report
  customerStatusReport: [],
  isCustomerStatusReportListLoaded: false,
  //PMX Inventory Report
  pmxInventoryReport: [],
  isPmxInventoryReportListLoaded: false,

  //PMX Inventory Detail Report
  pmxInventoryDetailReport: [],
  pmxInventoryDetailFilterCategoryList: [
    "all",
    "NSW",
    "VIC",
    "QLD",
    "WA",
    "TAS",
  ],
  isPmxInventoryDetailReportListLoaded: false,

  //Production Stock Report
  productionStockReport: [],
  isProductionStockReportListLoaded: false,

  //Raw Material OverView
  rawMaterialOverviewReport: [],
  isRawMaterialOverviewReportListLoaded: false,

  //Raw Material Stock
  rawMaterialStockReport: [],
  isRawMaterialStockReportListLoaded: false,
};
