import { itemsModal } from "../../models/itemsModel";
import { ReadOnlyProps } from "../../utils/templates";

interface props {
  itemsList: Array<itemsModal>;
  isItemsListLoaded: boolean;
  nestedItemsList: Array<itemsModal>;
  isNestedItemsListLoaded: boolean;
}

export const itemsState: ReadOnlyProps<props> = {
  //Filled via dynamic stream
  itemsList: [],
  isItemsListLoaded: false,
  nestedItemsList: [],
  isNestedItemsListLoaded: false,
};
