import React from "react";
import { View, Text } from "../shared/Themed";

export const CustomHorizontalLine: React.FC = () => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: "12px",
        marginBottom: "12px",
      }}
    >
      <View style={{ flex: 1, height: 1, backgroundColor: "#808080" }} />
      <View>
        <Text style={{ width: 50, textAlign: "center", color: "#808080" }}>
          OR
        </Text>
      </View>
      <View style={{ flex: 1, height: 1, backgroundColor: "#808080" }} />
    </View>
  );
};
