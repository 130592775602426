import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "react-native-gesture-handler";
import useCachedResources from "./hooks/useCachedResources";
import { ToastProvider } from "react-native-toast-notifications";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";

import DeviceOrientationContext from "./components/context/DeviceOrientationContext";
import { DeviceOrientation } from "./components/context/utils/DeviceOrientation";
import { MaterialIcons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { Provider } from "react-redux";

import store from "./redux/configureStore";

//SENTRY IMPORTS
import * as Sentry from "sentry-expo";

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const { isLargeDevice, isMediumDevice, isSmallDevice, isMobileDevice } =
    DeviceOrientation();

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    enableInExpoDevelopment: true,
    // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
    debug: process.env.NODE_ENV !== "production",
    //Enable auto performance tracking by default.
    enableAutoPerformanceTracking: true,
    //Should sessions be tracked to Sentry Health or not.
    enableAutoSessionTracking: true,
    // Specifies whether this SDK should send events to Sentry.
    enabled: true,
    // The current environment of your application (e.g. "production").
    environment:
      process.env.NODE_ENV !== "production" ? "development" : "production",
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Set sampling rate for profiling - this is relative to tracesSampleRate
    sampleRate: 1.0,
    // Enable session tracking
    autoSessionTracking: true,
    //Enable Tracing
    enableNative: true,
  });

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <SafeAreaProvider>
          <DeviceOrientationContext.Provider
            value={{
              isLargeDevice,
              isMediumDevice,
              isSmallDevice,
              isMobileDevice,
            }}
          >
            <ToastProvider
              placement="top"
              duration={4000}
              animationType="slide-in"
              animationDuration={250}
              offsetBottom={40}
              dangerIcon={<MaterialIcons name="dangerous" color={"white"} />}
              successIcon={<AntDesign name="checkcircleo" color={"white"} />}
            >
              <Navigation colorScheme={colorScheme} />
              <StatusBar />
            </ToastProvider>
          </DeviceOrientationContext.Provider>
        </SafeAreaProvider>
      </Provider>
    );
  }
}
