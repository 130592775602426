import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { StyleSheet } from "react-native";
import * as React from "react";
import { View } from "../../../components/shared/Themed";

import Colors from "../../../utils/constants/colors";
import useColorScheme from "../../../hooks/useColorScheme";
import HomeTitleHeader from "../../../components/core/HomeTitleHeader";
import ReportsDrawerNavigator from "../DrawerNavigation/ReportsDrawerNavigator";
import { HomeTabParamList, RootTabScreenProps } from "../../../types";
import ContainersDrawerNavigator from "../../StandardUserNavigator/Containers/ContainersDrawerNavigator";
import { useEffect, useState } from "react";
import { userClaimInformation } from "../../../models/usersModel";
import { useSelector } from "react-redux";
import ScansDrawerNavigator from "../Scans/ScansDrawerNavigator";
import Spinner from "../../../components/shared/Spinner";

const Tab = createMaterialTopTabNavigator<HomeTabParamList>();

export default function HomeTabNavigator() {
  const colorScheme = useColorScheme();

  const [userProfile, setUserProfile] = useState<userClaimInformation>();
  const [usersTeam, setUsersTeam] = useState<string>("Reports");
  const { userInfo } = useSelector((state: any) => state.configurations);

  useEffect(() => {
    setUserProfile(userInfo);
  }, [userInfo]);

  useEffect(() => {
    setUsersTeam(userProfile?.team.toUpperCase());
  }, [userProfile]);

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: colorScheme === "light" ? "#fafafa" : "#fafafa",
      }}
    >
      <HomeTitleHeader />
      <Tab.Navigator
        initialRouteName={"Reports"}
        screenOptions={{
          tabBarActiveTintColor: Colors[colorScheme].tint,
          tabBarStyle: [
            styles._tabBarStyle,
            {
              backgroundColor: colorScheme === "light" ? "#fafafa" : "#fafafa",
            },
          ],
          tabBarLabelStyle: styles._tabBarLabelStyle,
          tabBarItemStyle: {
            width: 100,
          },
          swipeEnabled: false,
          lazy: true,
          lazyPlaceholder: () => {
            return (
              <View
                style={{
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner
                  size="small"
                  color="red"
                  styles={{
                    flexDirection: "row",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                />
              </View>
            );
          },
        }}
      >
        {usersTeam === "SALES" ? (
          <>
            <Tab.Screen
              name="Reports"
              component={ReportsDrawerNavigator}
              options={({ navigation }: RootTabScreenProps<"Reports">) => ({
                title: "Report",
              })}
            />
            <Tab.Screen
              name="Containers"
              component={ContainersDrawerNavigator}
              options={({}: RootTabScreenProps<"Containers">) => ({
                title: "Container",
              })}
            />
          </>
        ) : (
          <Tab.Screen
            name="Scans"
            component={ScansDrawerNavigator}
            options={({}: RootTabScreenProps<"ScanByOperator">) => ({
              title: "Scan",
            })}
          />
        )}
      </Tab.Navigator>
    </View>
  );
}

const styles = StyleSheet.create({
  _tabBarLabelStyle: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "none",
  },
  _tabBarStyle: {
    height: 50,
    width: "98%",
    alignSelf: "center",
    justifySelf: "center",
    borderTopWidth: 0,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
});
