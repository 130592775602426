import React, { useContext } from "react";
import { StyleSheet, Modal } from "react-native";
import { Avatar, IconButton } from "react-native-paper";
import { BlurView } from "expo-blur";
import { View } from "../../shared/Themed";

import DeviceOrientationContext from "../../context/DeviceOrientationContext";
import { ReadOnlyProps } from "../../../utils/templates";

interface Props {
  childern: React.ElementType;
  modalVisible?: boolean;
  isMobileDevice?: boolean;
  setModalVisible: (event: any) => void;
}

export const ModalContainer: React.FC<ReadOnlyProps<Props>> = (
  props: ReadOnlyProps<Props>
) => {
  const Children = props.childern;
  const { isMediumDevice, isSmallDevice } = useContext(
    DeviceOrientationContext
  );

  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={props.modalVisible}
        onRequestClose={() => {
          props.setModalVisible(!props.modalVisible);
        }}
      >
        <BlurView intensity={5} style={styles.centeredView}>
          <View
            style={[
              styles.modalView,
              {
                minWidth: props.isMobileDevice
                  ? "92%"
                  : isSmallDevice
                  ? "45%"
                  : isMediumDevice
                  ? "30%"
                  : "24%",
                minHeight: props.isMobileDevice
                  ? "28%"
                  : isSmallDevice
                  ? "70%"
                  : "80%",
                maxHeight: props.isMobileDevice
                  ? "28%"
                  : isSmallDevice
                  ? "80%"
                  : "95%",
              },
            ]}
          >
            {props.childern && <Children {...props} />}
            <IconButton
              style={styles.button}
              icon="close"
              color={"#2196F3"}
              size={20}
              onPress={() => props.setModalVisible(!props.modalVisible)}
            />
          </View>
        </BlurView>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "#FBFCFC",
    borderRadius: 2,
    padding: "1%",
    alignItems: "center",
    justifyContent: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    marginTop: 10,
    minWidth: "1%",
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    border: "1px solid #eee",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
