import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";

import { Text } from "../../../../components/shared/Themed";
import { Switch as RNSwitch } from "react-native-switch";
import { ReadOnlyProps } from "../../../../utils/templates";

interface Props {
  title?: string;
  lastRefresh?: string;
  isDisplayViewOn: boolean;
  setIsDisplayViewOn: (hasDisplay: boolean) => void;
}

export const StockReportDisplaySwitch: React.FC<Props> = (
  props: ReadOnlyProps<Props>
) => {
  return (
    <>
      {props.lastRefresh && (
        <>
          <Text style={styles.lastRefresh}>
            Last Refresh:{" "}
            <Text style={{ fontWeight: "normal" }}> {props.lastRefresh}</Text>
          </Text>{" "}
          &nbsp;|&nbsp;
        </>
      )}

      <Text style={styles.title}>{props.title}</Text>
      <TouchableOpacity>
        <RNSwitch
          value={props.isDisplayViewOn}
          onValueChange={props.setIsDisplayViewOn}
          disabled={false}
          activeText={"CARTON"}
          inActiveText={"PIECE"}
          circleSize={25}
          barHeight={30}
          circleBorderWidth={2}
          //Active Props
          backgroundActive={"rgb(47, 149, 220)"}
          circleActiveColor={"#8BCAE5"}
          //InActive Props
          backgroundInactive={"rgba(0, 0, 0, 0.12)"}
          circleInActiveColor={"#A3A4A2"}
          inactiveTextStyle={{ color: "black" }}
          circleBorderInactiveColor="#858683"
          renderInActiveText={true}
          //renderInsideCircle={() => <CustomComponent />} // custom component to render inside the Switch circle (Text, Image, etc.)
          changeValueImmediately={true} // if rendering inside circle, change state immediately or wait for animation to complete
          innerCircleStyle={{
            alignItems: "center",
            justifyContent: "center",
          }} // style for inner animated circle for what you (may) be rendering inside the circle
          renderActiveText={true}
          switchLeftPx={2} // denominator for logic when sliding to TRUE position. Higher number = more space from RIGHT of the circle to END of the slider
          switchRightPx={2} // denominator for logic when sliding to FALSE position. Higher number = more space from LEFT of the circle to BEGINNING of the slider
          switchWidthMultiplier={props.isDisplayViewOn ? 4.65 : 3.85} // multiplied by the `circleSize` prop to calculate total width of the Switch
          switchBorderRadius={30} // Sets the border Radius of the switch slider. If unset, it remains the circleSize.
          containerStyle={{ marginRight: "10px" }}
        />
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  title: {
    marginLeft: "10px",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: "5px",
  },
  lastRefresh: {
    marginLeft: "10px",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: "10px",
    fontStyle: "italic",
  },
});
