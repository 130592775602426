import mapiConfig from "../configs/mapi";
import { barcodeModal } from "../models/barcodeModel";
import { getUserInfo } from "./usersService";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import * as Sentry from "@sentry/browser";

const BASE_URL = mapiConfig.api.domain;
const Controller = "/barcodes";

interface resultProps {
  data: any;
  isSuccessfull?: boolean;
  isLoading: boolean;
}

export const getBarcodeById = async (boxId: string) => {
  const methodPath = `${Controller}/search/${boxId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data as barcodeModal,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const addBarcodeForBox = async (
  boxId: string,
  lineNo: string,
  userId: string
) => {
  const userInfo = await getUserInfo();
  const methodPath = `${Controller}/add`;

  const httpBody = {
    box_id: boxId,
    employee_id: userId,
    employee_name: `${userInfo.given_name} ${userInfo.family_name}`,
    line_number: lineNo,
    date: new Date(),
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const addBulkBarcodesForBoxes = async (barcodes: Array<any>) => {
  const methodPath = `${Controller}/insert/bulk`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: barcodes,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getTotalScansStats = async (
  startDate: string,
  endDate: string,
  currentDate: string,
  operatorName: string
) => {
  const methodPath = `${Controller}/getTotalScans/${startDate}/${endDate}/${currentDate}/${operatorName}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getScanPerLineMonthlyStats = async (
  startDate: string,
  endDate: string,
  currentDate: string,
  operatorName: string
) => {
  const methodPath = `${Controller}/getScanPerLineMonthly/${startDate}/${endDate}/${currentDate}/${operatorName}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getScanPerOperatorMonthlyStats = async (
  startDate: string,
  endDate: string,
  currentDate: string,
  operatorName: string
) => {
  const methodPath = `${Controller}/getScanPerOperatorMonthly/${startDate}/${endDate}/${currentDate}/${operatorName}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getAvgScanStats = async (
  startDate: string,
  endDate: string,
  currentDate: string,
  operatorName: string
) => {
  const methodPath = `${Controller}/getAvgScan/${startDate}/${endDate}/${currentDate}/${operatorName}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getGroupScanPerOperator = async (
  startDate: string,
  endDate: string,
  currentDate: string,
  operatorName: string
) => {
  const methodPath = `${Controller}/getGroupScanPerOperator/${startDate}/${endDate}/${currentDate}/${operatorName}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getScanPerLines = async (
  startDate: string,
  endDate: string,
  currentDate: string,
  operatorName: string
) => {
  const methodPath = `${Controller}/getScanPerLines/${startDate}/${endDate}/${currentDate}/${operatorName}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getAllOperatorsList = async (
  startDate: string,
  endDate: string
) => {
  const methodPath = `${Controller}/getAllOperatorsList/${startDate}/${endDate}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const getScanStatsByBoxIds = async (barcodes: string[]) => {
  const methodPath = `${Controller}/getScanStatsByBoxIds`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: barcodes,
  })
    .then((result) => {
      return {
        data: result.data as Array<barcodeModal>,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};
