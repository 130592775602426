import oktaConfig from "../../configs/okta";
import * as Okta from "../oktaAuthService";
import { clearSessionStorage } from "./storageService";

export const logError = (error: any) => {
  // Token is expired, relogin is required
  if (error.includes("403")) {
    Okta.deleteSession().then(() => {
      clearSessionStorage();
      window.location.href = `${oktaConfig.api.redirectUri}`;
    });
  }
  console.error("ERROR:", error);
};

export const logWarn = (error: any) => {
  console.warn("WARN", error);
};

export const defaultLog = (error: any) => {
  console.log("LOG:", error);
};
