import React, { useState, useEffect, useContext } from "react";
import {
  Platform,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  Image,
} from "react-native";
import { Text, View } from "../../../components/shared/Themed";
import CustomTextInput from "../../../components/custom/CustomTextInput";
import * as ImagePicker from "expo-image-picker";
import useColorScheme from "../../../hooks/useColorScheme";
import { updateUser } from "../../../services/usersService";
import { User } from "../../../models/usersModel";
import { googlePlaceAutoComplete } from "../../../services/generalService";
import { getS3SignUrl, pushPhotoToS3 } from "../../../services/s3BucketService";
import { useSelector } from "react-redux";
import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { ConfigurationDispatcher } from "../../../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../../../redux/constants/actionTypesConfigurations";
import Spinner from "../../../components/shared/Spinner";

export default function ProfileForm() {
  const { userInfo, profilePhoto } = useSelector(
    (state: any) => state.configurations
  );
  const {
    first_name,
    last_name,
    email: userEmail,
    mobile: userMobile,
    gender: userGender,
    languages,
    location,
    user_id,
  } = userInfo;

  const colorScheme = useColorScheme();
  const orientationContext = useContext(DeviceOrientationContext);

  const [givenName, setGivenName] = useState<string>(first_name);
  const [familyName, setFamilyName] = useState<string>(last_name);
  const [image, setImage] = useState<string>(profilePhoto);
  const [email, setEmail] = useState<string>(userEmail);
  const [mobile, setMobile] = useState<string>(userMobile);
  const [gender] = useState<string>(userGender);
  const [imageBase64, setImageBase64] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedLanguages, setSelectedLanguages] =
    useState<Array<string>>(languages);
  const [selectedLocation, setSelectedLocation] =
    useState<Array<string>>(location);
  const [locationSearchTerm, setLocationSearchTerm] = useState<string>("");
  const [, setLocationList] = useState<Array<unknown>>([]);
  const [, setLocation] = useState<string>("");
  const disabled = false;

  const setHasAvatar = (data: boolean) => {
    ConfigurationDispatcher(actionTypesConfigurations.HasAvatar, data);
  };

  const setProfilePhoto = (data: string) => {
    ConfigurationDispatcher(actionTypesConfigurations.ProfilePhoto, data);
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.3,
      base64: true,
      exif: false,
    });
    if (!result.cancelled) {
      setImageBase64(result.base64 as string);
      setImage(result.uri);
    }
  };

  const getImage = async () => {
    setLoading(true);
    getS3SignUrl(
      "multisteps-go-public-image",
      `avatar/${user_id}.png`,
      "getObject",
      "image/*"
    ).then((data) => {
      if (data?.signedUrl) {
        setImage(data.signedUrl);
        setProfilePhoto(data.signedUrl);
      }
      setLoading(false);
    });
  };

  const uploadImage = async () => {
    setLoading(true);
    image &&
      getS3SignUrl(
        "multisteps-go-public-image",
        `avatar/${user_id}.png`,
        "putObject",
        "image/*"
      ).then((data) => {
        pushPhotoToS3(data?.signedUrl, "PUT", imageBase64, "image/*").then(
          () => {
            updateUser(
              {
                has_avatar: true,
              },
              ""
            ).then(() => {
              setLoading(false);
              setHasAvatar(true);
              getImage();
            });
          }
        );
      });
  };

  const handleTextChange = (text: string, field: string) => {
    field === "givenName"
      ? setGivenName(text)
      : field === "familyName"
      ? setFamilyName(text)
      : field === "mobile"
      ? setMobile(text)
      : field === "email"
      ? setEmail(text)
      : setLocation(text);
  };

  const handleLocationTextChange = (text: string) => {
    const isNewSearch = locationSearchTerm.length !== text.length;
    isNewSearch && setLocationSearchTerm(text);
    text &&
      isNewSearch &&
      googlePlaceAutoComplete(text as string).then((data) =>
        setLocationList([{ name: "Location", place_id: 0, children: data }])
      );
  };

  const handleSubmit = () => {
    setLoading(true);

    const userBody: User = {
      email: email as string,
      first_name: givenName as string,
      last_name: familyName as string,
      gender: gender as string,
      location: selectedLocation as string[],
      mobile: mobile as string,
      languages: selectedLanguages as string[],
      display_name: `${givenName} ${familyName}`,
    };

    updateUser(userBody, user_id).then((data) => {
      if (data?.status === "ACTIVE") {
        imageBase64 && uploadImage();
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (Platform.OS !== "web" && isMounted) {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert(
            "Sorry, Camera roll permissions are required to make this work!"
          );
        }
      }
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <ScrollView
      style={{ backgroundColor: colorScheme === "light" ? "white" : "white" }}
    >
      <View
        style={[
          styles.container,
          { backgroundColor: colorScheme === "light" ? "white" : "white" },
        ]}
      >
        <View style={styles.box}>
          <View style={styles.subBox}>
            <Image
              source={{
                uri: image ? image : require("../../../assets/noimage.png"),
              }}
              style={styles.photo}
              resizeMode="contain"
            />
            <View style={styles.subBox2}>
              <TouchableOpacity
                style={styles.photoButton}
                onPress={() => pickImage()}
              >
                <Text style={styles.photoButtonText}>Upload Photo</Text>
              </TouchableOpacity>
              <Text style={styles.greyLabel}>
                JPG, GIF or PNG. Max size of 8MB
              </Text>
              <Text style={styles.greyLabel}></Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: orientationContext.isSmallDevice
                ? "column"
                : "row",
              width: "100%",
              marginTop: 20,
              alignSelf: "flex-start",
              justifySelf: "flex-start",
            }}
          >
            <CustomTextInput
              title="First Name"
              width={orientationContext.isSmallDevice ? "80%" : "45%"}
              borderColor="#ccc"
              marginLeft={0}
              placeHolder="First name"
              value={givenName}
              disabled={true}
              setChangeText={(text: string) =>
                handleTextChange(text, "givenName")
              }
            />
            <CustomTextInput
              title="Last Name"
              width={orientationContext.isSmallDevice ? "80%" : "45%"}
              borderColor="#ccc"
              marginLeft={orientationContext.isSmallDevice ? 0 : 30}
              placeHolder="Last name"
              value={familyName}
              disabled={true}
              setChangeText={(text: string) =>
                handleTextChange(text, "familyName")
              }
            />
          </View>
          <View
            style={{
              flexDirection: orientationContext.isSmallDevice
                ? "column"
                : "row",
              width: "100%",
              marginTop: orientationContext.isSmallDevice ? 10 : 20,
              alignSelf: "flex-start",
              justifySelf: "flex-start",
            }}
          >
            <CustomTextInput
              title="Mobile"
              width={orientationContext.isSmallDevice ? "80%" : "45%"}
              borderColor="#ccc"
              marginLeft={0}
              placeHolder="Mobile phone"
              value={mobile}
              disabled={true}
              setChangeText={(text: string) => handleTextChange(text, "mobile")}
            />
            <CustomTextInput
              title="Email"
              width={orientationContext.isSmallDevice ? "80%" : "45%"}
              borderColor="#ccc"
              marginLeft={orientationContext.isSmallDevice ? 0 : 30}
              placeHolder="Email address"
              value={email}
              disabled={true}
              setChangeText={(text: string) => handleTextChange(text, "email")}
            />
          </View>
          <View style={{ flexDirection: "row", marginTop: 20 }}>
            {!disabled && (
              <TouchableOpacity
                style={styles.button}
                onPress={() => handleSubmit()}
              >
                {isLoading ? (
                  <Spinner
                    styles={styles.spinner}
                    size="small"
                    color="#00ff00"
                  />
                ) : (
                  <Text style={styles.buttonText}>Submit</Text>
                )}
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  box: {
    flex: 1,
    flexDirection: "column",
  },
  subBox: {
    flexDirection: "row",
    margin: 30,
  },
  subBox2: {
    flexDirection: "column",
    marginLeft: "2%",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: 40,
    borderRadius: 10,
    elevation: 3,
    backgroundColor: "#6495ED",
    marginLeft: 14,
  },
  buttonText: {
    fontSize: 12,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "white",
  },
  label: {
    minWidth: "10%",
    maxWidth: 500,
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    marginLeft: 14,
  },
  greyLabel: {
    fontSize: 14,
    textAlign: "left",
    lineHeight: 10,
    letterSpacing: 0.25,
    color: "#808080",
    marginLeft: 10,
  },
  input: {
    minWidth: "10%",
    maxWidth: 500,
    height: 60,
    backgroundColor: "#fff",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: "#ccc",
    borderWidth: 2,
    borderRadius: 15,
    fontSize: 15,
    margin: 20,
  },
  photo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  photoButton: {
    alignItems: "center",
    justifyContent: "center",
    width: 130,
    height: 40,
    elevation: 2,
    margin: 15,
  },
  photoButtonText: {
    fontSize: 14,
    lineHeight: 10,
    letterSpacing: 0.25,
    color: "#87CEFA",
  },
  spinner: {
    marginLeft: 12,
  },
});
