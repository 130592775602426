import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { View, Text } from "../../../../components/shared/Themed";
import { DateInputControl } from "../../../../components/custom/DateInputControl";
import { subtractDaysFromDate } from "../../../../utils/dateUtility";
import Icon from "react-native-vector-icons/FontAwesome5";

interface Props {
  isSmallDevice?: boolean;
  isMobileDevice?: boolean;
  scanDate: Date;
  setScanDate: (e: Date) => void;
  setRefresh?: (e: boolean) => void;
}

export const ScanDateFilter = ({
  isMobileDevice,
  isSmallDevice,
  scanDate,
  setScanDate,
  setRefresh,
}: Props) => {
  return (
    <>
      {(!isSmallDevice || !isMobileDevice) && <br />}
      <View
        style={{
          marginBottom: !isSmallDevice || !isMobileDevice ? "0.955%" : "3.8%",
          width: !isSmallDevice || !isMobileDevice ? "23.5%" : "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
          paddingLeft: "2%",
          paddingRight: !isSmallDevice || !isMobileDevice ? "1%" : "2%",
        }}
      >
        <View
          style={{
            marginRight: !isSmallDevice || !isMobileDevice ? "5%" : "5%",
            marginTop: !isSmallDevice || !isMobileDevice ? "10%" : "7.5%",
          }}
        >
          <Text style={[styles.label]}>Scan Date</Text>
        </View>
        <View style={{ flexGrow: !isSmallDevice || !isMobileDevice ? 0 : 2 }}>
          <DateInputControl
            title=""
            setDate={setScanDate}
            value={scanDate}
            width={"100%"}
            defaultText="Select Scan Date"
            disabled={true}
            validRange={{
              startDate: subtractDaysFromDate(new Date(), 730), //Last 2 years from current Date/Today
              endDate: new Date(),
            }}
          />
        </View>
        <View
          style={{
            marginRight: !isSmallDevice || !isMobileDevice ? "1%" : "5%",
            marginLeft: !isSmallDevice || !isMobileDevice ? "4%" : "5%",
            marginTop: !isSmallDevice || !isMobileDevice ? "10%" : "7.5%",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setRefresh!(true);
            }}
          >
            <Icon name={"sync"} size={15} color="#000080" />
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  label: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
  },
});
