import mapiConfig from "../configs/mapi";
import {
  containerMongoDbModal,
  containersModal,
} from "../models/containersModel";
import { getUserInfo } from "./usersService";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import { ContainersDispatcher } from "../redux/dispatcher/containersDispatcher";
import { actionTypesContainers } from "../redux/constants/actionTypesContainers";
import { sortCollection } from "../utils/sortCollection";

import * as Sentry from "@sentry/browser";

const BASE_URL = mapiConfig.api.domain;
const Controller = "/containers";

interface resultProps {
  data: any;
  isSuccessfull?: boolean;
  isLoading: boolean;
}

export const getAllContainers = async (
  tableHeaders: Record<string, Record<string, string | number | boolean>>
): Promise<void> => {
  const methodPath = `${Controller}/list`;
  let containerResult: Array<containersModal> = [];

  await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      containerResult = sortCollection(
        tableHeaders,
        "sortDirection",
        result.data
      );
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });

  ContainersDispatcher(actionTypesContainers.ListOfContainers, containerResult);
};

export const getContainerById = async (containerId: string) => {
  const methodPath = `${Controller}/search/${containerId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        data: result.data as containerMongoDbModal,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const manageContainer = async (container: any, type: string) => {
  const userInfo = await getUserInfo();
  const methodPath =
    type === "add"
      ? `${Controller}/add`
      : `${Controller}/update/${container.container_number}`;

  const httpBody = {
    ...container,
    ...(type === "add" && { created_Date: new Date() }),
    ...(type === "add" && {
      created_by: `${userInfo.given_name} ${userInfo.family_name}`,
    }),
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: type === "add" ? "POST" : "PUT",
    path: methodPath,
    body: httpBody,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const manageBulkContainers = async (containers: Array<any>) => {
  const methodPath = `${Controller}/insert/bulk`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: containers,
  })
    .then((result) => {
      return {
        data: result.data,
        isLoading: false,
        isSuccessfull: true,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        data: [],
        isLoading: false,
        isSuccessfull: false,
      } as resultProps;
    });
};

export const deleteContainerById = async (containerId: string) => {
  const methodPath = `${Controller}/delete/${containerId}`;
  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "DELETE",
    path: methodPath,
  })
    .then(() => {
      return {
        isSuccessfull: true,
        isLoading: false,
      } as resultProps;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        isSuccessfull: false,
        isLoading: false,
      } as resultProps;
    });
};
