import containerActions from "./containersActions";
import itemsActions from "./itemsActions";
import configurationsActions from "./configurationsActions";
import artworkActions from "./artworkActions";
import reportsActions from "./reportsActions";

const allActions = {
  configurationsActions,
  containerActions,
  artworkActions,
  itemsActions,
  reportsActions,
};

export default allActions;
