export const headers = {
  container_number: {
    name: "Container",
    width: 0.8,
    textAlign: "left",
  },
  // importer: {
  //   name: "Importer",
  //   width: 0.7,
  //   textAlign: "left",
  // },
  // type: {
  //   name: "Type",
  //   width: 0.3,
  //   textAlign: "left",
  // },
  // size: {
  //   name: "Size",
  //   width: 0.3,
  //   textAlign: "left",
  // },
  deliver_to: {
    name: "Deliver To",
    width: 0.8,
    textAlign: "left",
  },
  etd: {
    name: "ETD",
    width: 0.6,
    textAlign: "left",
  },
  eta_store: {
    name: "ETA",
    width: 0.6,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  // dd_number: {
  //   name: "DD #",
  //   width: 0.5,
  //   textAlign: "left",
  // },
  // coo_number: {
  //   name: "COO #",
  //   width: 0.5,
  //   textAlign: "left",
  // },
  status: {
    name: "Status",
    width: 0.6,
    textAlign: "left",
  },
  priority: {
    name: "Priority",
    width: 0.3,
    textAlign: "left",
  },
  // created_by: {
  //   name: "Created By",
  //   width: 0.8,
  //   textAlign: "left",
  // },
  "": {
    name: "",
    width: 0.3,
    textAlign: "",
  },
};

export const mdHeaders = {
  container_number: {
    name: "Container",
    width: 0.5,
    textAlign: "left",
  },
  deliver_to: {
    name: "Deliver To",
    width: 1,
    textAlign: "left",
  },
  etd: {
    name: "ETD",
    width: 0.5,
    textAlign: "left",
  },
  eta_store: {
    name: "ETA",
    width: 0.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  status: {
    name: "Status",
    width: 0.4,
    textAlign: "left",
  },
  priority: {
    name: "Priority",
    width: 0.3,
    textAlign: "left",
  },
  "": {
    name: "",
    width: 0.3,
    textAlign: "",
  },
};

export const smHeaders = {
  container_number: {
    name: "Container",
    width: 0.5,
    textAlign: "left",
  },
  // etd: {
  //   name: "ETD",
  //   width: 0.3,
  //   textAlign: "left",
  // },
  eta_store: {
    name: "ETA",
    width: 0.3,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  "": {
    name: "",
    width: 0.3,
    textAlign: "",
  },
};
