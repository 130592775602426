import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { View } from "../../../components/shared/Themed";
import { LabelBannerWithSearchBar } from "../../../components/custom/Banners/LabelBannerWithSearchBar";
import { DataTableWithPagination } from "../../../components/custom/DataTable/DataTableWithPagination";
import { default as Modal } from "../../../components/custom/Modal";
import Spinner from "../../../components/shared/Spinner";
import { getPmxInventory } from "../../../services/inventoryService";

import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import {
  headersPMXOverview,
  mdHeadersPMXOverview,
  smHeadersPMXOverview,
} from "../utils/pmxInventoryHeaders";
import { pmxOverviewModel } from "../../../models/reportsModel";
import { validSignedURL } from "../../../utils/validateSignedUrl";
import { handleTableLoader } from "../../../utils/common";
import { useSelector } from "react-redux";
import { userClaimInformation } from "../../../models/usersModel";
import { filterBasedOnAnyKey } from "../../../utils/searchInArrays";
import { sortColumns } from "../../../utils/sortColumns";

export default function PmxInventoryReportScreen({ route, navigation }: any) {
  const { isLargeDevice, isMediumDevice } = useContext(
    DeviceOrientationContext
  );

  const { pmxInventoryReport, isPmxInventoryReportListLoaded } = useSelector(
    (state: any) => state.reports
  );
  const { userInfo } = useSelector((state: any) => state.configurations);

  const [selected, setSelected] = useState<pmxOverviewModel>(
    {} as unknown as pmxOverviewModel
  );

  const [pmxPageLevelList, setPmxPageLevelList] = useState<
    Array<pmxOverviewModel>
  >([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [tableHeaders, setTableHeaders] =
    useState<Record<string, Record<string, string | number | boolean>>>(
      headersPMXOverview
    );
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [isImageLoading, setImageLoading] = useState<boolean>(true);

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const handleSearch = (searchText: string) => {
    displayTableLoader();

    if (searchText.trim() !== "") {
      const searchedList: Array<pmxOverviewModel> = filterBasedOnAnyKey(
        combineDuplicatedData(pmxInventoryReport),
        searchText
      );
      setPmxPageLevelList(searchedList);
    }

    setTimeout(() => {
      fadeTableLoader();
    }, 1000);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedPmxList: Array<pmxOverviewModel> = sortColumns(
      pmxPageLevelList,
      columnKey,
      tableHeaders,
      setTableHeaders
    );

    setPmxPageLevelList(_sortedPmxList);
  };

  const combineDuplicatedData = (data: Array<pmxOverviewModel>) => {
    let holder: Record<
      string,
      Record<string, string | number | pmxOverviewModel>
    > = {};

    data.forEach((pmx: pmxOverviewModel) => {
      let key = `${pmx.ItemCode} - ${pmx.Location}`;
      if (holder.hasOwnProperty(key)) {
        if (
          holder[key].ItemName === pmx.ItemName &&
          holder[key].Location === pmx.Location
        ) {
          holder[key] = {
            InvntryUom: pmx.InvntryUom,
            ItemCode: pmx.ItemCode,
            ItemName: pmx.ItemName,
            Location: pmx.Location,
            QuantityUom:
              (holder[key].QuantityUom as number) + (pmx.QuantityUom as number),
            QuantityUom2:
              (holder[key].QuantityUom2 as number) +
              (pmx.QuantityUom2 as number),
            Uom2: pmx.Uom2,
            Warehouse: pmx.Warehouse,
          };
        } else {
          holder[key] = { ...pmx };
        }
      } else {
        holder[key] = { ...pmx };
      }
    });

    const filteredPmxList: Array<pmxOverviewModel> = [];
    for (let prop in holder) {
      filteredPmxList.push({
        InvntryUom: holder[prop].InvntryUom,
        ItemCode: holder[prop].ItemCode,
        ItemName: holder[prop].ItemName,
        Location: holder[prop].Location,
        QuantityUom: holder[prop].QuantityUom,
        QuantityUom2: holder[prop].QuantityUom2,
        Uom2: holder[prop].Uom2,
        Warehouse: holder[prop].Warehouse,
      } as pmxOverviewModel);
    }

    return filteredPmxList;
  };

  useEffect(() => {
    let isMounted: boolean = true;
    const _userInfo = userInfo as userClaimInformation;

    // Avoid keep refreshing table when item is selected
    if (isMounted) {
      if (pmxPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();
        getPmxInventory(
          "",
          _userInfo.team,
          _userInfo.zone_info || "",
          tableHeaders
        );
      }
    }

    if (Object.keys(selected).length !== 0) {
      validSignedURL(
        selected.ItemCode,
        "label",
        setImageLoading,
        setSelectedImage
      );
    }

    setTableHeaders(
      isLargeDevice
        ? headersPMXOverview
        : isMediumDevice
        ? mdHeadersPMXOverview
        : smHeadersPMXOverview
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [selected, isRefresh]);

  useEffect(() => {
    setImageLoading(true);
  }, [modalVisible]);

  useEffect(() => {
    setPmxPageLevelList(combineDuplicatedData(pmxInventoryReport));

    setTimeout(() => {
      fadeTableLoader();
    }, 2000);
  }, [pmxInventoryReport]);

  const ModalContent = () => {
    const _excludedKeys = ["ItemCode", "InvntryUom", "Uom2"];

    const _orderedKeys = [
      "ItemName",
      "Warehouse",
      "Location",
      "QuantityUom",
      "QuantityUom2",
    ];

    const _specialLabels = {
      QuantityUom: {
        oldLabel: "QuantityUom",
        newLabel: "Piece",
      },
      QuantityUom2: {
        oldLabel: "QuantityUom2",
        newLabel: "Box",
      },
    };

    return (
      <Modal.ModalContent
        title={selected.ItemCode || ""}
        icon="folder"
        uri={selectedImage}
        loading={isImageLoading}
        childern={
          <Modal.ModalBody
            listData={selected}
            excludedKeys={_excludedKeys}
            renameList={headersPMXOverview}
            orderedKeys={_orderedKeys}
            specialLabels={_specialLabels}
          />
        }
      />
    );
  };

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={[styles.container, { backgroundColor: "#f2f2f2" }]}>
        <View
          style={[
            styles.containerContent,
            {
              width: "100%",
            },
          ]}
        >
          {isPmxInventoryReportListLoaded ? (
            <View>
              <LabelBannerWithSearchBar
                titleName={`Total Items: ${pmxPageLevelList.length}`}
                handleSearch={handleSearch}
                setRefresh={setRefresh}
                dataToExport={pmxPageLevelList}
                fileName={"inventory_overview_report"}
              />
              <DataTableWithPagination
                headers={tableHeaders}
                rows={pmxPageLevelList}
                itemsPerPage={100}
                useModal={true}
                isDataLoading={isSearched}
                isRowsExpandable={false}
                setModalVisible={setModalVisible}
                setSelected={setSelected}
                isDetail={true}
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
      <Modal.ModalContainer
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        childern={ModalContent}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
    // marginTop: 20
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
