import s3Config from "../configs/s3";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = s3Config.api.domain;

export const getS3SignUrl = async (
  bucketName: string,
  fileName: string,
  s3Method: string,
  contentType: string
) => {
  const methodPath = "/s3/presignedurl";
  const httpBody = {
    bucketName: bucketName,
    fileName: fileName,
    s3Method: s3Method,
    contentType: contentType,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then((result) => {
      if (result.data.status === "ACTIVE") {
        return {
          signedUrl: result.data.url,
          isLoading: false,
        };
      } else {
        return {
          signedUrl: null,
          isLoading: false,
        };
      }
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const pushPhotoToS3 = async (
  presignedUrl: string,
  httpMethod: string,
  fileBase64: any,
  contentType: string
) => {
  const buffer = Buffer.from(fileBase64, "base64");

  return await httpMStepsClient({
    type: httpMethod,
    path: presignedUrl,
    body: buffer,
    contentType: contentType,
    isTokenRequired: false,
    isAcceptHeaderRequired: false,
  })
    .then((result) => {
      if (result.data) {
        return {
          signedUrl: result.data.url,
          isLoading: false,
        };
      } else {
        return {
          signedUrl: null,
          isLoading: false,
        };
      }
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const checkS3Object = async (bucketName: string, fileName: string) => {
  const methodPath = "/s3/checkS3Object";
  const _httpBody = {
    bucketName: bucketName,
    fileName: fileName,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: _httpBody,
  })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};
