import mapiConfig from "../configs/mapi";

import { UserClaims } from "@okta/okta-auth-js";
import { authClient } from "./oktaAuthService";
import { User, userClaimInformation } from "../models/usersModel";
import { httpMStepsClient } from "./utils/httpService";
import { logError } from "./utils/logService";
import { accessToken, idToken } from "./utils/tokenService";
import { getCompanyDetailsByUserId } from "./companyService";

import * as Sentry from "@sentry/browser";

//BASE_URL
const BASE_URL = mapiConfig.api.domain;
const Controller = "/users";

export const getUserInfo = async (): Promise<UserClaims> => {
  const _accessToken = await accessToken();
  const _idToken = await idToken();

  return (await authClient.token.getUserInfo(
    _accessToken,
    _idToken
  )) as UserClaims;
};

export const getUsers = async (listAll: boolean) => {
  const userInfo = await getUserInfo();
  const userId: string = !listAll ? userInfo.sub : "all";
  const methodPath = `${Controller}/search/${userId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        users: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        users: [],
        isLoading: false,
      };
    });
};

export const getUsersByCompanyId = async (companyId: string) => {
  const methodPath = `${Controller}/search/company/${companyId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      return {
        users: result.data,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);

      return {
        users: [],
        isLoading: false,
      };
    });
};

export const addUser = async () => {
  const methodPath = `${Controller}/add`;
  const userInfo = await getUserInfo();
  const getCompany = await getCompanyDetailsByUserId();
  const companyIds = getCompany?.company.map(
    (company: any) => company.company_id
  );

  const httpBody: userClaimInformation = {
    login: userInfo.login,
    email: userInfo.primaryEmail as string,
    auto_sync: (userInfo.primaryEmail as string).includes("multisteps")
      ? true
      : false,
    team: userInfo.team,
    company_ids: companyIds,
    first_name: userInfo.given_name,
    last_name: userInfo.family_name,
    display_name: `${userInfo.given_name} ${userInfo.family_name}`,
    user_id: userInfo.sub,
    zone_info: userInfo.zoneinfo,
  };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "POST",
    path: methodPath,
    body: httpBody,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        users: httpBody,
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const updateUser = async (httpBody: User, selectedUserId: string) => {
  const userInfo = await getUserInfo();
  const userId: String = selectedUserId !== "" ? selectedUserId : userInfo.sub;
  const methodPath = `${Controller}/update/${userId}`;

  const httpBodyParam = Object.keys(httpBody).length
    ? httpBody
    : {
        login: userInfo.login,
        email: userInfo.primaryEmail,
        team: userInfo.team,
        zone_info: userInfo.zoneinfo,
        first_name: userInfo.given_name,
        last_name: userInfo.family_name,
        display_name: `${userInfo.given_name} ${userInfo.family_name}`,
      };

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "PUT",
    path: methodPath,
    body: httpBodyParam,
  })
    .then(() => {
      return {
        status: "ACTIVE",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};

export const getRole = async (companyId: string) => {
  const userInfo = await getUserInfo();
  const userId: string = userInfo.sub;
  const methodPath = `${Controller}/search/${userId}`;

  return await httpMStepsClient({
    baseURL: BASE_URL,
    type: "GET",
    path: methodPath,
  })
    .then((result) => {
      if (result.data.length) {
        const currentRole = result.data[0].roles.filter((role: any) => {
          return role.company_id === companyId;
        });

        return {
          role: currentRole[0].role,
          isLoading: false,
        };
      }

      return {
        role: "MEMBER",
        isLoading: false,
      };
    })
    .catch((error) => {
      logError(error);
      Sentry.captureException(error);
    });
};
