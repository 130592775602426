const headersRawMaterial = {
  FrgnName: {
    name: "Raw Material Group",
    width: 5.0,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  TotalInstock: {
    name: "Total",
    width: 1.2,
    textAlign: "left",
  },
  aPET: {
    name: "APET",
    width: 1,
    textAlign: "left",
  },
  rPET50: {
    name: "RPET50",
    width: 1,
    textAlign: "left",
  },
  rPET80: {
    name: "RPET80",
    width: 1,
    textAlign: "left",
  },
  rPET100: {
    name: "RPET100",
    width: 1,
    textAlign: "left",
  },
};

const mediumDeviceHeadersRawMaterial = {
  FrgnName: {
    name: "Raw Material Group",
    width: 5.0,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  TotalInstock: {
    name: "Total",
    width: 1.2,
    textAlign: "left",
  },
  aPET: {
    name: "APET",
    width: 1,
    textAlign: "left",
  },
  rPET50: {
    name: "RPET50",
    width: 1,
    textAlign: "left",
  },
  rPET80: {
    name: "RPET80",
    width: 1,
    textAlign: "left",
  },
  rPET100: {
    name: "RPET100",
    width: 1,
    textAlign: "left",
  },
};

const smallDeviceHeadersRawMaterial = {
  FrgnName: {
    name: "Raw Material",
    width: 2,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  TotalInstock: {
    name: "Total",
    width: 1.2,
    textAlign: "left",
  },
  aPET: {
    name: "APET",
    width: 1.2,
    textAlign: "left",
  },
  rPET50: {
    name: "RPET50",
    width: 1.2,
    textAlign: "left",
  },
  rPET80: {
    name: "RPET80",
    width: 1.2,
    textAlign: "left",
  },
  rPET100: {
    name: "RPET100",
    width: 1.2,
    textAlign: "left",
  },
};

const headersRawMaterialOverview = {
  RawMaterialOverview: {
    name: "Raw Material",
    width: 2.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  TotalInStock: {
    name: "Total",
    width: 1.2,
    textAlign: "left",
  },
  PlannedQty: {
    name: "Planned",
    width: 1.5,
    textAlign: "left",
  },
  OnWater: {
    name: "On Water",
    width: 1,
    textAlign: "left",
  },
  OnOrder: {
    name: "On Order",
    width: 1,
    textAlign: "left",
  },
};

const mediumDeviceHeadersRawMaterialOverview = {
  RawMaterialOverview: {
    name: "Raw Material",
    width: 2.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  TotalInStock: {
    name: "Total",
    width: 1.2,
    textAlign: "left",
  },
  PlannedQty: {
    name: "Planned",
    width: 1,
    textAlign: "left",
  },
  OnWater: {
    name: "On Water",
    width: 1,
    textAlign: "left",
  },
  OnOrder: {
    name: "On Order",
    width: 1,
    textAlign: "left",
  },
};

const smallDeviceHeadersRawMaterialOverview = {
  RawMaterialOverview: {
    name: "Raw Material",
    width: 2.5,
    textAlign: "left",
    isSort: true,
    sortDirection: "asc",
  },
  TotalInStock: {
    name: "Total",
    width: 1.2,
    textAlign: "left",
  },
  PlannedQty: {
    name: "Planned",
    width: 1,
    textAlign: "left",
  },
  OnWater: {
    name: "On Water",
    width: 1,
    textAlign: "left",
  },
  OnOrder: {
    name: "On Order",
    width: 1,
    textAlign: "left",
  },
};

export default {
  headersRawMaterial,
  mediumDeviceHeadersRawMaterial,
  smallDeviceHeadersRawMaterial,
  headersRawMaterialOverview,
  mediumDeviceHeadersRawMaterialOverview,
  smallDeviceHeadersRawMaterialOverview,
};
