import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, ScrollView } from "react-native";
import { View } from "../../../components/shared/Themed";
import LabelBanner from "../../../components/custom/Banners/LabelBanner";
import Spinner from "../../../components/shared/Spinner";
import { getRawMaterialProductionStocks } from "../../../services/stockService";

import DeviceOrientationContext from "../../../components/context/DeviceOrientationContext";
import { default as rawMaterial } from "../utils/rawMaterialStockHeaders";
import { rawMaterialStockModel } from "../../../models/reportsModel";
import { DataTableWithPagination } from "../../../components/custom/DataTable/DataTableWithPagination";
import { useSelector } from "react-redux";
import { sortColumns } from "../../../utils/sortColumns";
import { handleTableLoader } from "../../../utils/common";

export default function RawMaterialStocksReportScreen({
  route,
  navigation,
}: any) {
  const { isLargeDevice, isMediumDevice } = useContext(
    DeviceOrientationContext
  );

  const [isRefresh, setRefresh] = useState<boolean>(false);
  const { rawMaterialStockReport, isRawMaterialStockReportListLoaded } =
    useSelector((state: any) => state.reports);

  const [selected, setSelected] = useState<rawMaterialStockModel>(
    {} as unknown as rawMaterialStockModel
  );

  const [rawMaterialStockPageLevelList, setRawMaterialStockPageLevelList] =
    useState<Array<rawMaterialStockModel>>([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const [tableHeaders, setTableHeaders] = useState<
    Record<string, Record<string, string | number | boolean>>
  >(rawMaterial.headersRawMaterial);

  const displayTableLoader = () => {
    handleTableLoader(true, false, setIsSearched);
  };

  const fadeTableLoader = () => {
    handleTableLoader(false, true, setIsSearched);
  };

  const onHandleSortColumn = (columnKey: string) => {
    const _sortedRawMaterialStockList: Array<rawMaterialStockModel> =
      sortColumns(
        rawMaterialStockPageLevelList,
        columnKey,
        tableHeaders,
        setTableHeaders
      );

    setRawMaterialStockPageLevelList(_sortedRawMaterialStockList);
  };

  useEffect(() => {
    let isMounted = true;

    // Avoid keep refreshing table when item is selected
    if (isMounted) {
      if (rawMaterialStockPageLevelList.length === 0 || isRefresh) {
        displayTableLoader();
        getRawMaterialProductionStocks(tableHeaders);
      }
    }

    setTableHeaders(
      isLargeDevice
        ? rawMaterial.headersRawMaterial
        : isMediumDevice
        ? rawMaterial.mediumDeviceHeadersRawMaterial
        : rawMaterial.smallDeviceHeadersRawMaterial
    );

    setRefresh(false);

    return () => {
      isMounted = false;
    };
  }, [selected, isRefresh]);

  useEffect(() => {
    setRawMaterialStockPageLevelList(rawMaterialStockReport);

    setTimeout(() => {
      fadeTableLoader();
    }, 2000);
  }, [rawMaterialStockReport]);

  return (
    <ScrollView style={{ backgroundColor: "white" }}>
      <View style={[styles.container, { backgroundColor: "#f2f2f2" }]}>
        <View
          style={[
            styles.containerContent,
            {
              width: "100%",
            },
          ]}
        >
          {isRawMaterialStockReportListLoaded ? (
            <View>
              <LabelBanner
                titleName={`Total Items: ${rawMaterialStockPageLevelList.length}`}
                childern={"a"}
              />
              <DataTableWithPagination
                headers={tableHeaders}
                rows={rawMaterialStockPageLevelList}
                itemsPerPage={100}
                useModal={false}
                isDetail={false}
                isDataLoading={isSearched}
                setModalVisible={() => {}}
                setSelected={setSelected}
                handleRowClick={() => {}}
                handleDeleteRow={() => {}}
                handleSortColumn={onHandleSortColumn}
              />
            </View>
          ) : (
            <View>
              <Spinner
                size="small"
                color="red"
                styles={{ flexDirection: "row", margin: "20%" }}
              />
            </View>
          )}
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  containerContent: {
    flexDirection: "column",
    alignSelf: "flex-start",
    justifySelf: "flex-start",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
