import { sortDirection } from "./constants/genericTypes";
import { getItemsHavingSortDirection } from "./searchInArrays";
import { sortByDirection } from "./sortDataTable";

export const sortCollection = (
  tableHeaders: Record<string, Record<string, string | number | boolean>>,
  keyToSort: string,
  collection: unknown
) => {
  const { sortDirections, columns } = getItemsHavingSortDirection(
    tableHeaders,
    keyToSort
  );

  return sortByDirection(
    sortDirections as Array<sortDirection>,
    columns as Array<string>,
    collection
  );
};
