import React from "react";
import { ReadOnlyProps } from "../../../utils/templates";
import { TouchableOpacity, StyleSheet } from "react-native";

import { Badge } from "react-native-paper";
import { renameLabel } from "../../../utils/common";

interface Props {
  itemsList: Array<string>;
  filterCategory: string;
  setFilterCategory: (category: string) => void;
}

export const BadgeHorizontalList: React.FC<Props> = (
  props: ReadOnlyProps<Props>
) => {
  return (
    <>
      {props.itemsList.map((item) => {
        return (
          <TouchableOpacity key={item}>
            <Badge
              key={item}
              style={[
                styles.badgeStyle,
                props.filterCategory === item
                  ? styles.activeBadge
                  : styles.inActiveBadge,
              ]}
              onPress={() => props.setFilterCategory(item)}
            >
              {renameLabel(item)}
            </Badge>
          </TouchableOpacity>
        );
      })}
    </>
  );
};

const styles = StyleSheet.create({
  badgeStyle: {
    marginLeft: "2px",
    marginRight: "2px",
    fontSize: 12,
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  inActiveBadge: {
    color: "black",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  activeBadge: {
    color: "white",
    backgroundColor: "rgb(47, 149, 220)",
  },
});
