import { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  Image,
  useWindowDimensions,
  ImageBackground,
} from "react-native";

import { useToast } from "react-native-toast-notifications";
import { View } from "../../components/shared/Themed";
import { ForgotPasswordScreen } from "./ForgotPasswordScreen";
import { LoginScreen } from "./LoginScreen";
import DeviceOrientationContext from "../../components/context/DeviceOrientationContext";
import { Card } from "react-native-paper";
import { LoginWithTandaScreen } from "./LoginWithTandaScreen";

function MainScreen({}) {
  const toast = useToast();
  const dimensions = useWindowDimensions();

  const { isSmallDevice } = useContext(DeviceOrientationContext);
  const [displayForgetPasswordScreen, setDisplayForgetPasswordScreen] =
    useState<boolean>(false);
  const [displayLoginWithTendaScreen, setDisplayLoginWithTendaScreen] =
    useState<boolean>(false);
  const [forgetPasswordStatus, setForgetPasswordStatus] =
    useState<boolean>(false);

  useEffect(() => {
    if (forgetPasswordStatus) {
      toast.show("A password reset link has sent to your email", {
        type: "success",
      });
    }
  }, [forgetPasswordStatus]);

  return (
    <View style={styles.container}>
      <View testID="loginPanel" style={{ flexDirection: "row" }}>
        <View
          testID="loginLeftPanel"
          style={{
            width: dimensions.width / 2,
            backgroundColor: "#ededed",
            display: isSmallDevice ? "none" : "flex",
          }}
        >
          <Image
            source={{ uri: require("../../assets/AnalyticsLogo.png") }}
            style={{
              width: "100%",
              height: dimensions.height / 2,
              marginTop: dimensions.height / 3.5,
            }}
          />
        </View>
        <View
          testID="loginRightPanel"
          style={[
            { width: isSmallDevice ? dimensions.width : dimensions.width / 2 },
          ]}
        >
          <ImageBackground
            source={{
              uri: isSmallDevice
                ? require("../../assets/AnalyticsLogo.png")
                : "",
            }}
            style={[styles.bgImage]}
            resizeMode="cover"
          >
            <View
              style={{
                width: isSmallDevice ? "80%" : "50%",
                marginTop: dimensions.height / 8,
                marginLeft: isSmallDevice ? "10%" : "27%",
                backgroundColor: "transparent",
              }}
            >
              <Image
                style={styles.tinyLogo}
                source={require("../../assets/logo2.svg")}
              />
              <Card testID="loginMultiSteps" mode="elevated">
                <Card.Content style={[styles.content]}>
                  {displayForgetPasswordScreen ? (
                    <ForgotPasswordScreen
                      setForgetPasswordStatus={setForgetPasswordStatus}
                      setDisplayForgetPasswordScreen={
                        setDisplayForgetPasswordScreen
                      }
                    />
                  ) : displayLoginWithTendaScreen ? (
                    <LoginWithTandaScreen
                      setDisplayForgetPasswordScreen={
                        setDisplayForgetPasswordScreen
                      }
                      setDisplayLoginWithTendaScreen={
                        setDisplayLoginWithTendaScreen
                      }
                    />
                  ) : (
                    <LoginScreen
                      setDisplayForgetPasswordScreen={
                        setDisplayForgetPasswordScreen
                      }
                      setDisplayLoginWithTendaScreen={
                        setDisplayLoginWithTendaScreen
                      }
                    />
                  )}
                </Card.Content>
              </Card>
            </View>
          </ImageBackground>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  tinyLogo: {
    width: 250,
    height: 60,
    alignSelf: "center",
    marginBottom: "5%",
  },
  content: {
    padding: 0,
    borderColor: "#eee",
    borderWidth: 1,
    borderStyle: "solid",
  },
  contentLabel: {
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "row",
    paddingTop: "5%",
    paddingBottom: "5%",
  },
  bgImage: {
    flex: 1,
    justifyContent: "center",
  },
  errorColor: {
    color: "red",
  },
  successColor: {
    color: "#037d50",
  },
  px_5: { paddingLeft: "5%", paddingRight: "5%" },
  forgetPasswordLink: {
    marginTop: "2%",
    fontStyle: "italic",
    color: "#adacac",
    textDecorationLine: "underline",
  },
  boldFont: {
    fontWeight: "bold",
  },
});

export default MainScreen;
