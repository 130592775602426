import { sortDirection } from "./constants/genericTypes";

/**
 * This methods allows to perform wild-card search on the collection
 * @param {Array<any>} collection - the JSON collection on which wild-card operation needs to apply
 * @param {string} searchKey - the value which is used as a key to perform wild-card
 * @returns the collection with a possible combinations based on the searchKey
 */
export const filterBasedOnAnyKey = (
  collection: Array<any>,
  searchKey: string
): Array<any> => {
  return collection.filter((element) => {
    return Object.values(element).some(
      (value) =>
        value !== null &&
        (value as string)
          .toString()
          .toLowerCase()
          .includes(searchKey.toLowerCase())
    );
  });
};

/**
 * This methods returns a collection, after
 * ensuring & filtering values in Array A (i.e. getCompared)
 * based on a key (i.e. getComparedKey) from the Array B (i.e. toCompareWith)
 * @param {string} getComparedKey - the key with which the list has to be filtered
 * @param {Array<unknown>} getCompared - the JSON collection whose records needs to be made identical
 * @param {Array<string>} toCompareWith - the list with which an array is validated
 * @returns the list with identical records after a kind of INNER JOIN operation
 */
export const compareArraysToHaveAnIdenticalArray = (
  getComparedKey: string,
  getCompared: Array<unknown>,
  toCompareWith: Array<string>
) => {
  return getCompared.filter((item: any) => {
    return toCompareWith.includes(item[getComparedKey]);
  });
};

/**
 * This methods extract sort information from a collection
 * @param {Record<string, Record<string, string | boolean | number>>} collection - collection of records containing sort information
 * @param {string} key - key within a collection containing sort information
 * @returns a collection of columns having default sort & their directions
 */
export const getItemsHavingSortDirection = (
  collection: Record<string, Record<string, string | boolean | number>>,
  key: string
) => {
  const keysFromCollections = Object.keys(collection);
  const filterArrayByKey: Record<
    string,
    Array<string | number | boolean | sortDirection>
  > = {
    columns: [],
    sortDirections: [],
  };

  keysFromCollections.map((value: any) => {
    if (collection[value][key] !== undefined) {
      filterArrayByKey.columns.push(value);
      filterArrayByKey.sortDirections.push(
        collection[value][key] === "asc"
          ? sortDirection.Ascending
          : sortDirection.Descending
      );
    }
  });

  return filterArrayByKey;
};
