import store from "../configureStore";
import allActions from "../actions";

import { actionTypesArtwork } from "./../constants/actionTypesArtwork";
import { artworkModel } from "./../../models/artworkModel";

const { dispatch } = store;

export const ArtWorkDispatcher = (
  actionType: actionTypesArtwork,
  data: unknown
) => {
  const containersAction = allActions.artworkActions;

  switch (actionType) {
    case actionTypesArtwork.ListOfArtwork:
      dispatch(containersAction.setArtworkList(data as Array<artworkModel>));
      break;
  }
};
