import { useWindowDimensions } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { drawerItemsMain } from "./ScansDrawerItemsMain ";
import CustomDrawerContent from "../../../components/custom/CustomDrawerContent";
import ScanByManagerScreen from "../../../screens/Scans/Management/ScanByManagerScreen";
import ScanDashboardScreen from "../../../screens/Scans/Management/ScanDashboard";
import { userClaimInformation } from "../../../models/usersModel";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import teamsConfig from "../../../utils/constants/teams";
import rolesConfig from "../../../utils/constants/roles";

const Drawer = createDrawerNavigator();

export default function ScansDrawerNavigator() {
  const dimensions = useWindowDimensions();

  const [userProfile, setUserProfile] = useState<userClaimInformation>();
  const [assignedRoles, setAssignedRoles] = useState<string[]>();

  const { userInfo, userRoles } = useSelector(
    (state: any) => state.configurations
  );

  const { operation } = teamsConfig.teams;
  const { owner } = rolesConfig.roles;

  useEffect(() => {
    setUserProfile(userInfo);
    setAssignedRoles(userRoles);
  }, [userInfo]);

  return (
    <Drawer.Navigator
      initialRouteName="ScanDashboard"
      screenOptions={{
        drawerStyle: {
          width: 200,
        },
        drawerLabelStyle: {
          fontSize: 12,
          textTransform: "none",
        },
        drawerType: dimensions.width >= 768 ? "front" : "front", //'permanent' : 'front',
      }}
      drawerContent={(props) => (
        <CustomDrawerContent
          drawerItems={
            userProfile?.team.toUpperCase() === operation &&
            assignedRoles?.includes(owner)
              ? drawerItemsMain
              : []
          }
          {...props}
        />
      )}
    >
      <Drawer.Screen
        options={{
          drawerLabel: "Production Dashboard",
          title: "Production Dashboard",
        }}
        name="ScanDashboard"
        component={ScanDashboardScreen}
      />
      <Drawer.Screen
        options={{
          drawerLabel: "Scan Results",
          title: "Scan Results",
        }}
        name="ScanByManager"
        component={ScanByManagerScreen}
      />
    </Drawer.Navigator>
  );
}
