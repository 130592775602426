import { artworkModel, pendingArtWork } from "../../../models/artworkModel";
import { checkIsDate, formatDate } from "../../../utils/dateUtility";

export const convertObjectToReadableForm_Artwork = (
  data: Array<artworkModel>
) => {
  data.forEach((item: artworkModel) => {
    const pendingArtworkNumbers =
      item.pending_artwork_numbers as Array<pendingArtWork>;
    const attribute =
      pendingArtworkNumbers.length > 0
        ? pendingArtworkNumbers.map(
            (artwork: pendingArtWork) => artwork.pendingNumber
          )
        : [];

    item.pending_artwork_numbers = attribute?.join(", ").toString();
  });
  return data;
};
