import { companyModel } from "../../models/companyModel";
import { rolesModel, userClaimInformation } from "../../models/usersModel";
import { actionTypesConfigurations as actionTypes } from "../constants/actionTypesConfigurations";

const setIsUserAuthorized = (data: boolean) => {
  return {
    type: actionTypes.IsUserAuthorized,
    payload: data,
  };
};

const setCompanies = (data: Array<companyModel>) => {
  return {
    type: actionTypes.Companies,
    payload: data,
  };
};

const setCurrentCompany = (data: companyModel) => {
  return {
    type: actionTypes.CurrentCompany,
    payload: data,
  };
};

const setNavigation = (data: any) => {
  return {
    type: actionTypes.Navigation,
    payload: data,
  };
};

const setLogo = (data: string) => {
  return {
    type: actionTypes.Logo,
    payload: data,
  };
};

const setCurrentRole = (data: string) => {
  return {
    type: actionTypes.CurrentRole,
    payload: data,
  };
};

const setInitials = (data: string) => {
  return {
    type: actionTypes.Initial,
    payload: data,
  };
};

const setUserRoles = (data: Array<string>) => {
  return {
    type: actionTypes.UserRoles,
    payload: data,
  };
};

const setUserInfo = (data: userClaimInformation) => {
  return {
    type: actionTypes.UserInfo,
    payload: data,
  };
};

const setHasAvatar = (data: boolean) => {
  return {
    type: actionTypes.HasAvatar,
    payload: data,
  };
};

const setHasAvatarMenu = (data: boolean) => {
  return {
    type: actionTypes.HasAvatarMenu,
    payload: data,
  };
};

const setIsDrawer = (data: boolean) => {
  return {
    type: actionTypes.IsDrawer,
    payload: data,
  };
};

const setProfilePhoto = (data: string) => {
  return {
    type: actionTypes.ProfilePhoto,
    payload: data,
  };
};

export default {
  setIsUserAuthorized,
  setNavigation,
  setCompanies,
  setCurrentCompany,
  setHasAvatarMenu,
  setHasAvatar,
  setProfilePhoto,
  setIsDrawer,
  setLogo,
  setUserInfo,
  setInitials,
  setCurrentRole,
  setUserRoles,
};
