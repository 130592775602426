import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import React, { useEffect } from "react";
import { StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { View } from "../../../components/shared/Themed";
import useColorScheme from "../../../hooks/useColorScheme";
import TeamActiveMemberScreen from "../../../screens/Settings/TeamMemberScreens/TeamActiveMemberScreen";
import TabOneScreen from "../../../screens/ModalScreen";
import { RootTabParamList } from "../../../types";

const Tab = createMaterialTopTabNavigator<RootTabParamList>();

export default function TeamTabNavigator(props) {
  const colorScheme = useColorScheme();
  const [searchQuery, setSearchQuery] = React.useState("");
  const onChangeSearch = (query: any) => setSearchQuery(query);

  useEffect(() => {
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: colorScheme === "light" ? "white" : "white " },
      ]}
    >
      <View style={styles.row}>
        <View style={{ width: "50%" }}>
          <Button
            icon="account-plus"
            mode="contained"
            onPress={() => props.setModalVisible(true)}
          >
            Invite User
          </Button>
        </View>
        <View style={styles.box}>
          <Tab.Navigator
            initialRouteName="Active"
            screenOptions={{
              tabBarActiveTintColor: "black",
              tabBarInactiveTintColor: "grey",
              tabBarLabelStyle: {
                fontSize: 16,
                fontWeight: "bold",
                textTransform: "none",
              },
              tabBarItemStyle: { width: 100 },
              tabBarStyle: {
                height: 70,
                flexDirection: "row",
                borderTopWidth: 0.4,
                borderBottomWidth: 0.4,
                borderColor: "lightgrey",
                backgroundColor: "#EAEDED",
              },
            }}
          >
            <Tab.Screen
              style={styles.container}
              name="Active"
              component={TeamActiveMemberScreen}
              options={{ tabBarLabel: "Active" }}
            />
            <Tab.Screen
              style={styles.container}
              name="Invited"
              component={TabOneScreen}
              options={{ tabBarLabel: "Invited" }}
              listeners={{
                tabPress: (e) => {
                  e.preventDefault();
                },
              }}
            />
          </Tab.Navigator>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
  },
  box: {
    flexDirection: "row",
  },
  row: {
    flex: 1,
  },
});
