import { actionTypesConfigurations as actionTypes } from "../constants/actionTypesConfigurations";
import { configurationsState } from "../state/configurations";

const configurationReducer = (state = configurationsState, action: any) => {
  switch (action.type) {
    case actionTypes.IsUserAuthorized:
      return {
        ...state,
        isUserAuthorized: action.payload,
      };
    case actionTypes.Companies:
      return {
        ...state,
        companies: action.payload,
      };
    case actionTypes.CurrentCompany:
      return {
        ...state,
        currentCompany: action.payload,
      };
    case actionTypes.Navigation:
      return {
        ...state,
        navigation: action.payload,
      };
    case actionTypes.Logo:
      return {
        ...state,
        logo: action.payload,
      };
    case actionTypes.CurrentRole:
      return {
        ...state,
        currentRole: action.payload,
      };
    case actionTypes.Initial:
      return {
        ...state,
        initial: action.payload,
      };
    case actionTypes.UserInfo:
      return {
        ...state,
        userInfo: action.payload,
      };
    case actionTypes.HasAvatar:
      return {
        ...state,
        hasAvatar: action.payload,
      };
    case actionTypes.HasAvatarMenu:
      return {
        ...state,
        hasAvatarMenu: action.payload,
      };
    case actionTypes.IsDrawer:
      return {
        ...state,
        isDrawer: action.payload,
      };
    case actionTypes.ProfilePhoto:
      return {
        ...state,
        profilePhoto: action.payload,
      };
    case actionTypes.UserRoles:
      return {
        ...state,
        userRoles: action.payload,
      };
    default:
      return state;
  }
};

export default configurationReducer;
