import React, { useState, useContext } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { Button, Avatar } from "react-native-paper";
import { View } from "../shared/Themed";
import AvatarMenu from "./AvatarMenu";
import { useSelector } from "react-redux";
import DeviceOrientationContext from "../context/DeviceOrientationContext";
import store from "../../redux/configureStore";
import { ConfigurationDispatcher } from "../../redux/dispatcher/configurationDispatcher";
import { actionTypesConfigurations } from "../../redux/constants/actionTypesConfigurations";

export default function UserAvatar() {
  const [isVisible] = useState<boolean>(false);

  const { dispatch } = store;
  const orientationContext = useContext(DeviceOrientationContext);
  const { userInfo, hasAvatarMenu, hasAvatar, isDrawer, profilePhoto } =
    useSelector((state: any) => state.configurations);

  const setHasAvatarMenu = (data: boolean) => {
    ConfigurationDispatcher(actionTypesConfigurations.HasAvatarMenu, data);
  };
  const fullName = userInfo.display_name.split(" ");

  return (
    <View
      style={[
        styles.container,
        { marginLeft: orientationContext.isSmallDevice ? "85%" : "72%" },
      ]}
    >
      <View style={[styles.avatar]}>
        <TouchableOpacity
          style={{ flexDirection: "row" }}
          onPress={() => setHasAvatarMenu(!hasAvatarMenu)}
        >
          {hasAvatar ? (
            <Avatar.Image
              size={isDrawer ? 55 : 35}
              source={profilePhoto}
              style={{ backgroundColor: "white" }}
            />
          ) : (
            <Avatar.Text
              size={isDrawer ? 55 : 35}
              rounded
              label={`${fullName.shift().charAt(0)}`}
              style={{ backgroundColor: "#3d4db7" }}
            />
          )}
          {!orientationContext.isSmallDevice && (
            <Button
              style={styles.icon}
              contentStyle={{ width: 50 }}
              color="grey"
              icon={isVisible ? "menu-up" : "menu-down"}
              onPress={() => setHasAvatarMenu(!hasAvatarMenu)}
            />
          )}
        </TouchableOpacity>
        {hasAvatarMenu && !isDrawer && !orientationContext.isSmallDevice ? (
          <View
            style={[
              styles.avatarMenu,
              styles.shadowProp,
              {
                width: orientationContext.isSmallDevice ? 200 : 300,
                right: orientationContext.isSmallDevice ? 40 : 10,
              },
            ]}
          >
            <AvatarMenu />
          </View>
        ) : (
          <View />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  avatar: {
    justifyContent: "space-around",
  },
  container: {
    position: "absolute",
    width: "10%",
    height: 30,
    flexDirection: "row",
    marginTop: 15,
  },
  avatarMenu: {
    textAlign: "left",
    position: "absolute",
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 8,
    backgroundColor: "white",
    marginTop: 380,
    zIndex: 1,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  tinyLogo: {
    width: 50,
    height: 30,
  },
  icon: {
    marginTop: 14,
  },
});
