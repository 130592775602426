import { actionTypesContainers as actionTypes } from "../constants/actionTypesContainers";
import { containersModal } from "../../models/containersModel";

const setContainersList = (data: Array<containersModal>) => {
  return {
    type: actionTypes.ListOfContainers,
    payload: data,
  };
};

const setIsContinersTableRefreshed = (data: boolean) => {
  return {
    type: actionTypes.IsContainersTableRefreshed,
    payload: data,
  };
};

const setSelectedContainer = (data: containersModal) => {
  return {
    type: actionTypes.SelectedContainer,
    payload: data,
  };
};

export default {
  setContainersList,
  setIsContinersTableRefreshed,
  setSelectedContainer,
};
