import { useState, useEffect, useContext } from "react";
import { Image } from "react-native";

import { Text, View } from "../shared/Themed";
import Spinner from "../../components/shared/Spinner";
import { useSelector } from "react-redux";
import DeviceOrientationContext from "../context/DeviceOrientationContext";
import { getItemFromSessionStorage } from "../../services/utils/storageService";

export default function HomeTabTitle() {
  const orientationContext = useContext(DeviceOrientationContext);
  const logoContext =
    (getItemFromSessionStorage("logo-context") as string) || "";
  const [logo, setLogo] = useState<string>(logoContext);

  const { companies, currentCompany, userInfo } = useSelector(
    (state: any) => state.configurations
  );

  useEffect(() => {
    let isMounted = true;
    setLogo(logoContext);

    return () => {
      isMounted = false;
    };
  }, [logoContext]);

  return (
    <View
      style={{
        height: "8%",
        borderTopWidth: 0.1,
        borderBottomWidth: 3,
        borderColor: "darkgrey",
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        marginBottom: 10,
      }}
    >
      {currentCompany ? (
        <View style={{ flexDirection: "row", width: "100%", height: "100%" }}>
          {logo ? (
            <View
              style={{
                flexDirection: "column",
                width: "20%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={{ uri: logo }}
                style={{
                  width: orientationContext.isSmallDevice ? 40 : 60,
                  height: orientationContext.isSmallDevice ? 40 : 60,
                }}
                resizeMode="contain"
              />
            </View>
          ) : (
            <></>
          )}
          <View
            style={{
              flexDirection: "column",
              width: orientationContext.isSmallDevice ? "30%" : "10%",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></View>
        </View>
      ) : !companies.length ? (
        <View
          style={{
            flexDirection: "column",
            width: "20%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              //fontSize: 20,
              fontWeight: "bold",
              color: "grey", //colorScheme === "light" ? "grey" : "white",
              marginLeft: "1%",
            }}
          >
            {`Hi ${userInfo.first_name}`}
          </Text>
        </View>
      ) : (
        <View
          style={{
            flexDirection: "column",
            width: "20%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            size="small"
            color="red"
            styles={{
              flexDirection: "row",
              justifySelf: "center",
              alignSelf: "center",
            }}
          />
        </View>
      )}
    </View>
  );
}
